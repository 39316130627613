const baseComponents = {
  icon: {
    dir: "default"
  },
  title: {
    fontSize: "2.5rem",
    fontFamily: "poppinsR",
    color: "#161616",
  },
  button: {
    bgColor: "#CCCCCC",
    fontFamily: "poppinsM",
    fontSize: "1rem",
    fontColor: "#161616"
  },
  borderedText: {
    fontFamily: "opensansR",
    fontColor: "#161616",
    fontSizeChat: "0.875rem",
    fontSizeCard: "1rem",
    fontFamilyB: "opensansB",
    fontSizeB: "1rem",
    borderColor: "#CCCCCC"
  },
  colorWrapper: {
    bgColor: "#EEEEEE"
  },
  input: {
    placeholderColor: "#B1B1B1",
    borderColor: "#CCCCCC",
    focusBorderColor: "#CCCCCC",
    fontFamilyLabel: "poppinsSB",
    fontSizeLabel: "1rem",
    fontFamilyInput: "opensansR",
    fontSizeInput: "0.875rem"
  },
  textArea: {
    countFontFamily: "opensansR",
    countFontSize: "0.875rem",
    countFontColor: "#161616"
  },
  radio: {
    fontSizeLabel: "0.875rem",
    fontColorLabel: "#161616",
    fontFamilyLabel: "opensansR",
    fontFamilyBoldLabel: "opensansB",
    radioSize: "1.25rem",
    radioColor: "#707070",
    labelFontFamily: "poppinsSB",
    labelFontSize: "1rem",
    labelFontColor: "#161616"
  },
  notes: {
    bgColor: "#707070",
    fontColor: "#fff",
    fontFamily: "poppinsMI",
    fontSize: "1rem"
  },
  countDown: {
    bgColor: "#707070",
    labelFontColor: "#fff",
    labelFontFamily: "openSansR",
    labelFontSize: "1.125rem",
    numberFontColor: "#fff",
    numberFontFamily: "antsvalley",
    numberFontSize: "5rem"
  }
}

const sections = {
  cover: {
    brGrFontFamily: "antsvalley",
    brGrFontSize: "4.375rem",
    brGrFontColor: "#161616",
    andFontFamily: "antsvalley",
    andFontSize: "5.625rem",
    andFontColor: "#161616",
    andMarginTop: "-3rem",
    andMarginBottom: "-2rem",
    dearFontFamily: "poppinsR",
    dearFontSize: "1.25rem",
    dearColor: "#707070",
  },
  opening: {
    qContentFontFamily: "opensansR",
    qContentFontSize: "1rem",
    qContentColor: "#161616",
    qDescFontFamily: "opensansB",
    qDescFontSize: "1rem",
    qDescColor: "#161616",

    avatarFrameWidth: "275",
    avatarNameFontSize: "1.25rem",
    avatarNameFontFamily: "poppinsSB",
    avatarDescFontSize: "0.875rem",
    avatarDescFontFamily: "poppinsR",
    avatarNameColor: "#161616",
    avatarDescColor: "#707070"
  },
  time: {
    scheduleTitleFF: "opensansB",
    scheduleTitleFS: "1.25rem",
    scheduleTitleC: "#161616",
    scheduleDescFF: "opensansR",
    scheduleDescFS: "1rem",
    scheduleDescC: "#161616",
    
    dateCardType: 1,
    timeCardType: 1,
  },
  location: {
    titleFontFamily: "opensansB",
    titleFontSize: "1.25rem",
    titleColor: "#161616",
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#161616"
  },
  rsvp: {
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#161616",
  },
  story: {
    lineColor: "#161616",
    iconBgColor: "#161616",
    iconBorderColor: "#161616",
    cardBorderColor: "#161616",
  },
  streaming: {
    streamingTextFontFamily: "opensansR",
    streamingTextFontSize: "1rem",
    streamingTextFontColor: "#161616",
  },
  gift: {
    codeFontFamily: "poppinsSB",
    codeFontSize: "1.25rem",
    codeFontColor: "#161616",
    detailFontFamily: "poppinsR",
    detailFontSize: "0.875rem",
    detailFontColor: "#161616",

    giftDetailFontFamily: "poppinsR",
    giftDetailFontSize: "1rem",
    giftDetailFontColor: "#161616",
  },
  warning: {
    warnIconFontFamily: "opensansSB",
    warnIconFontSize: "1rem",
    warnIconFontColor: "#161616",
  }
}

export { baseComponents, sections }