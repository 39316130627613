const deviceAgent = {
  data() {
    return {
      device: typeof window != "undefined" ? (window.innerWidth <= 900 ? "mobile" : "desktop") : null,
    }
  },
  computed: {
    isDesktop() {
      return this.device == "desktop" ? true : false
    },
    isMobile() {
      return this.device == "mobile" ? true : false
    }
  },
  methods: {
    onResize() {
        this.device = window.innerWidth <= 992 ? "mobile" : "desktop"

        // window.location.reload()
    }
  },
  mounted() {
    if(typeof window != "undefined") window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() { 
    if(typeof window != "undefined") window.removeEventListener('resize', this.onResize)
  }
}

const deviceWindow = {
  data() {
    return {
      windowWidth: window.innerWidth
    }
  }
}

const alert = {
  methods: {
    $alertSuccess(message) {
      this.$swal({
        icon: "success",
        html: `<h5 style="color: #fff; font-family: poppinsM">${this.$weddingData.brideNickName} & ${this.$weddingData.groomNickName} Wedding</h5><h6 style="color: #fff">${message}</h6>`,
        showConfirmButton: true,
        confirmButtonColor: "#fff",
        iconColor: "#fff",
        confirmButtonText: '<span style="color: rgb(16, 33, 53); font-family: poppinsM">Okey  </span>',
        background: this.$baseComponents.background.bgColor || "#fff",
      })
    },
    $alertFail(message = '') {
      this.$swal({
        icon: "error",
        html: `<h6 style="color: #8A807D">${message || 'Terjadi kesalahan. Mohon ulangi beberapa menit lagi :('}</h6>`,
        showConfirmButton: true,
        background: this.$baseComponents.background.bgColor || "#fff"
      })
    },
    $buttonClick(func) {
      if(this.isMobile) return setTimeout(() => func(), 250)

      return func()
    },
    $background(backgroundData) {
      const { background } = this.$baseComponents

      return background.withImage ? { backgroundImage: backgroundData } : { backgroundColor: backgroundData }
    },
    $checkMobileDecoration(decors) {
      return Object.keys(decors).
          filter((key) => key.includes('m_')).
          reduce((cur, key) => {
            return Object.assign(cur, { [key.replace("m_", '')]: decors[key] })
          }, {})
    }
  }
}

export { deviceAgent, deviceWindow, alert }