<template>
  <div 
    class="warning-icon text-center" 
    :style="styles"
  >
    <img :width="isDesktop ? css.warnIconWidth : css.m_warnIconWidth" :src="`/icons/${iconDir}/${icon}`">
    <p class="warn text-center" v-html="text"></p>
  </div>
</template>

<script>
export default {
  props: ["icon", "text"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        warnIconFontFamily: "opensansB",
        warnIconFontSize: "1rem",
        warnIconFontColor: "#161616",

        ...this.$sections.warning
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--warn-icon-font-family': css.warnIconFontFamily,
        '--warn-icon-font-size': css.warnIconFontSize,
        '--warn-icon-font-color': css.warnIconFontColor,
      }

      const mobile = {
        '--m-warn-icon-font-size': css.m_warnIconFontSize,
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  p.warn {
    font-family: var(--warn-icon-font-family);
    font-size: var(--warn-icon-font-size);
    color: var(--warn-icon-font-color);
  }
</style>

<style mobile scoped>
  .mobile p.warn {
    font-size: var(--m-warn-icon-font-size);
  }
</style>