export default [
  {
    name: 'montserratR',
    src: 'fonts/montserrat/Montserrat-Regular.ttf'
  },
  {
    name: 'montserratB',
    src: 'fonts/montserrat/Montserrat-Bold.ttf'
  },
  {
    name: 'montserratEB',
    src: 'fonts/montserrat/Montserrat-ExtraBold.ttf'
  },
  {
    name: 'montserratBk',
    src: 'fonts/montserrat/Montserrat-Black.ttf'
  }
]