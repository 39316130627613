<template>
  <div
    class="countdown-container pt-5 px-5 pb-8"
    :style="{
      background: backgroundColor,
      ...styles
    }"
    :class="{
      'just-married': justMarried
    }"
  >
    <template v-if="time.day != null">
      <BorderedText
        wide 
        :borderColor="borderColor"
        :borderWidth="borderWidth"
        :m_boteCardMinHeight="css.m_boteCardMinHeight"
        :boteCardMinHeight="css.boteCardMinHeight"
        :boteCardClass="{ 'd-flex align-items-center': isMobile }"
      >
        <div class="d-flex justify-content-center w-100">
          <template v-if="!justMarried">
            <div class="time-countdown-wrapper">
              <img 
                src="/icons/customVirandaAkbar/love-balloons.svg" 
                class="love-balloon-icon" 
                alt="love-balloon-icon" 
                :width="countdown_top_icon_width"
              >
  
              <Title 
                :class="{
                  'mt-5': isDesktop,
                  'mt-1': isMobile
                }"
                class="text-navy" 
                text="Hari yang dinanti" 
              />

              <div class="countdowns">
                <div class="countdown-item">
                  <p class="label">Hari</p>

                  <p class="time">{{ time.day }}</p>
                </div>

                <div class="countdown-item">
                  <p class="label">Jam</p>

                  <p class="time">{{ time.hour }}</p>
                </div>

                <div class="countdown-item">
                  <p class="label">Menit</p>

                  <p class="time">{{ time.minute }}</p>
                </div>

                <div class="countdown-item">
                  <p class="label">Detik</p>

                  <p class="time">{{ time.second }}</p>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <img class="justmarried-icon" :src="`/icons/${iconDir}/${justMarriedIcon}`">
          </template>
        </div>
      </BorderedText>
    </template>
  </div>
</template>

<script>
export default {
  props: ["date", "bgColor", "labelFontColor", "labelFontFamily", "labelFontSize", "numberFontColor", "numberFontFamily", "numberFontSize", "borderColor", "borderWidth", "m_labelFontSize", "m_numberFontSize"],

  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      backgroundColor: this.bgColor || "#707070",
      lFontColor: this.labelFontColor || "#fff",
      lFontFamily: this.labelFontFamily || "openSansR",
      lFontSize: this.labelFontSize || "1.125rem",
      nFontColor: this.numberFontColor || "#fff",
      nFontFamily: this.numberFontFamily || "antsvalley",
      nFontSize: this.numberFontSize || "5rem",
      time: {
        day: null,
        hour: null,
        minute: null,
        second: null,
        distance: 0
      },
      css: {
        boteCardStyles: "",
        ...this.$baseComponents.countDown
      }
    }
  },

  computed: {
    styles() {
      const desktop = {
        '--label-font-color': this.lFontColor,
        '--label-font-family': this.lFontFamily,
        '--label-font-size': this.lFontSize,
        '--number-font-color': this.nFontColor,
        '--number-font-family': this.nFontFamily,
        '--number-font-size': this.nFontSize,
      }

      const mobile = {
        '--m-label-font-size': this.m_labelFontSize,
        '--m-number-font-size': this.m_numberFontSize,
      }

      return { ...desktop, ...mobile }
    },

    justMarried() {
      if(this.time.day == 0 && this.time.hour == 0 && this.time.minute == 0 && this.time.second == 0) return true
      
      return false
    },

    justMarriedIcon() {
      return this.isDesktop ? "justmarried-desktop.png" : "justmarried-mobile.png"
    },

    countdown_top_icon_width() {
      return this.isDesktop ? '50' : '40'
    }
  },

  methods: {
    getCountDown() {
      let countDownDate = new Date(this.date).getTime()
      let now = new Date().getTime()

      this.distance = countDownDate - now

      if (this.distance > 0) {
        setTimeout(() => {
          this.time.day = Math.floor(this.distance / (1000 * 60 * 60 * 24))
          this.time.hour = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          this.time.minute = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
          this.time.second = Math.floor((this.distance % (1000 * 60)) / 1000)

          this.getCountDown()
        }, 1000)
      } else {
        this.time.day = this.time.hour = this.time.minute = this.time.second = 0
      }
    }
  },
  mounted() {
    this.getCountDown()
  }
}
</script>

<style>
  .text-navy {
    color: #102135 !important;
  }
</style>

<style scoped>
  .label p {
    color: var(--label-font-color);
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
  }

  .number p {
    color: var(--number-font-color);
    font-family: var(--number-font-family);
    font-size: var(--number-font-size);
  }

  .countdowns {
    display: flex;
    justify-content: center;
    gap: 2.5rem;
    margin-top: 2rem;
  }

  .countdowns .countdown-item {
    padding: 1.31rem 1.25rem 0 1.25rem;
    border-radius: 1rem;
    background: #102135;
  }

  .countdowns .countdown-item p.label {
    color: #FFF;
    text-align: center;
    font-family: opensansR;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .countdowns .countdown-item p.time {
    color: #FFF;
    text-align: center;
    font-family: antsvalley;
    font-size: 5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 6.325rem;
  }

  .just-married.countdown-container {
    background: #102135 !important;
  }

  .just-married.countdown-container .justmarried-icon {
    max-width:  33.875rem;
  }
</style>

<style mobile scoped>
  .mobile .countdown-container {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding: 0 !important;
  }

  .mobile .label p {
    font-size: var(--m-label-font-size);
    margin-bottom: 0.938rem;
  }

  .mobile .number p {
    font-size: var(--m-number-font-size);
  }

  .mobile .number {
    width: 3.125rem;
  }

  .mobile .countdowns {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }

  .mobile .countdowns .countdown-item {
    padding: 0.5rem 0 0.37rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 5rem;
    max-width: 5rem;
    border-radius: 0.5rem;
  }

  .mobile .countdowns .countdown-item p.time {
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    min-width: 2.5rem;
    max-width: 2.5rem;
    margin-top: 0.81rem;
  }

  .mobile .just-married.countdown-container .justmarried-icon {
    max-width:  13.56rem;
  }
</style>