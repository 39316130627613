const baseComponents = {
  background: {
    withImage: true
  },
  icon: {
    dir: "customRegitaAditya"
  },
  title: {
    fontSize: "1.75rem",
    fontFamily: "andinademo",
    color: "#57663A",

    m_fontSize: "1.25rem"
  },
  button: {
    bgColor: "#956464",
    fontFamily: "poppinsM",
    fontSize: "1rem",
    fontColor: "#FFFFFF"
  },
  borderedText: {
    fontFamily: "opensansR",
    fontColor: "#57663A",
    fontSizeChat: "0.875rem",
    fontSizeCard: "1rem",
    fontFamilyB: "opensansB",
    fontSizeB: "1rem",
    borderColor: "#F5BCBC",
    iconWidth: "50",

    m_fontSizeCard: "0.75rem",
    m_fontSizeB: "0.75rem",
    m_iconWidth: "30"
  },
  colorWrapper: {
    bgColor: "#f5e9e6"
  },
  input: {
    // start::desktop
    placeholderColor: "#B1B1B1",
    borderColor: "#CCCCCC",
    focusBorderColor: "#57663A",
    fontFamilyLabel: "poppinsSB",
    labelColor: "#4D2525",
    fontSizeLabel: "1rem",
    fontFamilyInput: "opensansR",
    fontSizeInput: "0.875rem",
    fontColorInput: "#956464",
    // end::desktop

    // start::mobile
    m_fontSizeLabel: "0.75rem",
    m_fontSizeInput: "0.75rem"
    // end::mobile
  },
  textArea: {
    // start::desktop
    countFontFamily: "opensansR",
    countFontSize: "0.875rem",
    countFontColor: "#4D2525",
    labelColor: "#4D2525",
    focusBorderColor: "#57663A",
    fontColorInput: "#956464",
    // end::desktop

    // start::mobile
    m_countFontSize: "0.75rem",
    // end::mobile
  },
  radio: {
    // start::desktop
    fontSizeLabel: "0.875rem",
    fontFamilyLabel: "opensansR",
    fontFamilyBoldLabel: "opensansB",
    radioSize: "1.25rem",
    radioColor: "#57663A",
    labelFontFamily: "poppinsSB",
    labelFontSize: "1rem",
    labelFontColor: "#4D2525",
    optionLabelColor: "#57663A",
    // end::desktop

    // start::mobile
    m_radioSize: "1rem",
    m_fontSizeLabel: "0.75rem",
    m_labelFontSize: "0.75rem",
    // end::mobile
  },
  notes: {
    bgColor: "#707070",
    fontColor: "#fff",
    fontFamily: "poppinsMI",
    fontSize: "1rem"
  },
  countDown: {
    // start::desktop
    bgColor: "#956464",
    labelFontColor: "#FFFFFF",
    labelFontFamily: "openSansR",
    labelFontSize: "1.125rem",
    numberFontColor: "#FFFFFF",
    numberFontFamily: "antsvalley",
    numberFontSize: "5rem",
    borderColor: "#FFFFFF",
    borderWidth: "0.125rem",
    // end::desktop

    // start::mobile
    m_labelFontSize: "0.75rem",
    m_numberFontSize: "2.5rem",
    // end::mobile
  },
  musicButton: {
    // start::desktop
    backgroundColor: "#956464",
    buttonWidth: "4rem",
    iconWidth: "38",
    // end::desktop
    
    // start::mobile
    m_buttonWidth: "2.5rem",
    m_iconWidth: "24",
    // end::mobile
  }
}

const sections = {
  cover: {
    //start::desktop
    brGrFontFamily: "andinademo",
    brGrFontSize: "3.125rem",
    brGrFontColor: "#57663A",
    
    andFontFamily: "andinademo",
    andFontSize: "2.875rem",
    andFontColor: "#F5BCBC",
    andMarginTop: "-2.5rem",
    andMarginBottom: "-6rem",
    andMarginLeft: "0",
    andMarginRight: "0",

    dearFontFamily: "poppinsR",
    dearFontSize: "1.25rem",
    dearColor: "#707070",

    bridePaddingLeft: "3.7rem",
    groomPaddingLeft: "13rem",
    // end::desktop
    
    // start::mobile
    m_brGrFontSize: "1.75rem",

    m_andFontSize: "1.5rem",
    m_andMarginTop: "-1.3rem",
    m_andMarginBottom: "-3rem",
    m_andMarginLeft: "0",
    m_andMarginRight: "0",

    m_dearFontSize: "0.875rem",
    m_dearFontColor: "#4D2525",

    m_bridePaddingLeft: "2rem",
    m_bridePaddingRight: "0",
    
    m_groomPaddingRight: "0",
    m_groomPaddingLeft: "7rem"
    // end::mobile
  },
  opening: {
    // start::desktop
    qContentFontFamily: "opensansR",
    qContentFontSize: "1rem",
    qContentColor: "#57663A",
    qDescFontFamily: "opensansB",
    qDescFontSize: "1rem",
    qDescColor: "#57663A",
    
    avatarFrameWidth: "200",
    avatarNameFontSize: "1.25rem",
    avatarNameFontFamily: "poppinsSB",
    avatarDescFontSize: "0.875rem",
    avatarDescFontFamily: "poppinsR",
    avatarParentsFontFamily: "opensansR",
    avatarParentsFontSize: "1rem",
    avatarNameColor: "#57663A",
    avatarDescColor: "#57663A",
    avatarMarginBottom: "2.375rem",
    parentWithAnd: false,
    // end::desktop

    // start::mobile
    m_qContentFontSize: "0.75rem",
    m_qDescFontSize: "0.75rem",
    
    m_avatarFrameWidth: "110",
    m_avatarNameFontSize: "0.875rem",
    m_avatarDescFontSize: "0.688rem",
    m_avatarParentsFontSize: "0.688rem",
    m_avatarParentsFontFamily: "opensansR",
    m_avatarMarginBottom: "0.688rem",
    m_avatarDescFontFamily: "opensansI",
    m_parentWithAnd: false,
    m_addBrOnSpace: 2,
    // end::mobile
  },
  time: {
    // start::desktop
    scheduleTitleFF: "opensansB",
    scheduleTitleFS: "1.25rem",
    scheduleTitleC: "#4D2525",
    scheduleDescFF: "opensansR",
    scheduleDescFS: "1rem",
    scheduleDescC: "#4D2525",

    dateTimeIconSize: "50",
    dateCardType: 1,
    timeCardType: 1,
    // end::desktop

    // start::mobile
    m_scheduleTitleFS: "0.75rem",
    m_scheduleDescFS: "0.75rem",
    
    m_dateTimeIconSize: "30"
    // end::mobile
  },
  location: {
    // start::desktop
    titleFontFamily: "opensansB",
    titleFontSize: "1.25rem",
    titleColor: "#4D2525",
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#4D2525",
    iconWidth: "50",
    // end::desktop

    // start::mobile
    m_titleFontSize: "0.75rem",
    m_descFontSize: "0.75rem",
    m_iconWidth: "30",
    // end::mobile
  },
  rsvp: {
    // start::desktop
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#4D2525",
    // end:desktop

    // start::mobile
    m_descFontSize: "0.75rem",
    // end::mobile
  },
  story: {
    lineColor: "#161616",
    iconBgColor: "#161616",
    iconBorderColor: "#161616",
    cardBorderColor: "#161616",
  },
  gallery: {
    galleryInitialWidth: "133px",
    m_galleryInitialWidth: "104px"
  },
  wish: {
    m_chatBorderBottom: "1px solid #956464"
  },
  streaming: {
    streamingTextFontFamily: "opensansR",
    streamingTextFontSize: "1rem",
    streamingTextFontColor: "#161616",
  },
  gift: {
    codeFontFamily: "poppinsSB",
    codeFontSize: "1.25rem",
    codeFontColor: "#161616",
    detailFontFamily: "poppinsR",
    detailFontSize: "0.875rem",
    detailFontColor: "#161616",

    giftDetailFontFamily: "poppinsR",
    giftDetailFontSize: "1rem",
    giftDetailFontColor: "#161616",
  },
  warning: {
    // start::desktop
    warnIconFontFamily: "opensansSB",
    warnIconFontSize: "1rem",
    warnIconFontColor: "#93A173",
    warnIconWidth: "150",
    // end::desktop

    // start::mobile
    m_warnIconWidth: "90",
    m_warnIconFontSize: "0.75rem"
    // end::mobile
  },
  footer: {
    // start::desktop
    footerIconWidth: "200",
    // end::desktop
    
    // start::mobile
    m_footerIconWidth: "125"
    // end::mobile
  }
}
export { baseComponents, sections }