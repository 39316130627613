<template>
  <div id="location-decoration" :style="styles">
    <div name="top-center" v-if="decor.topCenter">
      <img :src="`${iconDir}/top-center.png`" class="decoration" alt="top-center-decoration" data-aos-offset="100" data-aos="zoom-in-down">
    </div>

    <div name="top-left" v-if="decor.topLeft">
      <img :src="`${iconDir}/top-left.png`" class="decoration" alt="top-left-decoration" data-aos-offset="100" data-aos="zoom-in-down">
    </div>
    <div name="top-right" v-if="decor.topRight">
      <img :src="`${iconDir}/top-right.png`" class="decoration" alt="top-right-decoration" data-aos-offset="100" data-aos="zoom-in-down">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      let decors = this.$decorations.location

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/location/${this.device}`
    },
    styles() {
      const css = this.$decorations.location

      const desktop = {
        '--top-left-top': css.topLeft_top,
        '--top-left-left': css.topLeft_left,
        '--top-left-width': css.topLeft_width,

        '--top-right-top': css.topRight_top,
        '--top-right-right': css.topRight_right,
        '--top-right-width': css.topRight_width,
      }

      const mobile = {
        '--m-top-left-top': css.m_topLeft_top,
        '--m-top-left-left': css.m_topLeft_left,
        '--m-top-left-width': css.m_topLeft_width,
        '--m-top-left-z-index': css.m_topLeft_zIndex,

        '--m-top-right-top': css.m_topRight_top,
        '--m-top-right-right': css.m_topRight_right,
        '--m-top-right-width': css.m_topRight_width,
        '--m-top-right-z-index': css.m_topRight_zIndex
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  [name=top-left] img {
    top: var(--top-left-top);
    left: var(--top-left-left);
    width: var(--top-left-width);
  }

  [name=top-right] img {
    top: var(--top-right-top);
    right: var(--top-right-right);
    width: var(--top-right-width);
  }
</style>

<style mobile scoped>
  .mobile [name=top-left] img {
    top: var(--m-top-left-top);
    left: var(--m-top-left-left);
    width: var(--m-top-left-width);
    z-index: var(--m-top-left-z-index);
  }

  .mobile [name=top-right] img {
    top: var(--m-top-right-top);
    right: var(--m-top-right-right);
    width: var(--m-top-right-width);
    z-index: var(--m-top-right-z-index);
  }
</style>