var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button-container d-flex justify-content-center align-items-center w-100",style:({
      background: _vm.bgColor, 
      minWidth: _vm.isDesktop ? _vm.minWidth : null, 
      maxWidth: _vm.minWidth,
      '--button-container-bg-color': _vm.bgColor,
      paddingLeft: _vm.paddingX,
      paddingRight: _vm.paddingX,
    })},[(_vm.icon)?[_c('img',{staticClass:"button-icon",attrs:{"src":("/icons/" + _vm.iconDir + "/" + _vm.icon)}})]:_vm._e(),_c('p',{staticClass:"button-text",style:({fontFamily: _vm.fontFamily, fontSize: _vm.fontSize, color: _vm.fontColor})},[_vm._v(_vm._s(_vm.text))]),(_vm.loading)?_c('div',{staticClass:"loading-spinner"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }