<template>
  <div name="mobile-content">
    <div class="mobile-content-padding">
      <section name="logo">
        <img 
          name="wedding-logo" 
          :src="$weddingData.galleryInitialSrc" 
          alt="wedding-logo"
        >
      </section>

      <section name="quotes">
        <p class="quote" name="q-content">
          {{ wedding_quotes }}
        </p>

        <p class="quote" name="q-desc">
          {{ $weddingData.quotesDesc }}
        </p>
      </section>
    </div>

    <section name="avatars">
      <div name="bride-avatar">
        <div class="image">
          <img 
            src="/images/bride-mobile.png"
            alt="bride-image" 
            class="bride-avatar-image"
          >
        </div>

        <div class="avatar-name">
          <p class="name">{{ $weddingData.brideFullName }}</p>

          <p class="from-text">Putri Terakhir dari :</p>

          <p class="parents">
            {{ $weddingData.brideFather }}
            <br>
            {{ $weddingData.brideMother }}
          </p>
        </div>
      </div>

      <div name="and-avatar">
        <p class="and-avatar">&</p>
      </div>

      <div name="groom-avatar">
        <div class="image">
          <img 
            src="/images/groom-mobile.png"
            alt="groom-image" 
            class="groom-avatar-image"
          >
        </div>

        <div class="avatar-name">
          <p class="name">{{ $weddingData.groomFullName }}</p>

          <p class="from-text">Putra Terakhir dari :</p>

          <p class="parents">
            {{ $weddingData.groomFather }}
            <br>
            {{ $weddingData.groomMother }}
          </p>
        </div>
      </div>
    </section>

    <section 
      name="decorations" 
    >
      <img 
        src="/decorations/customVirandaAkbar/opening/mobile/top-center.png" 
        alt="top-center" 
        class="top-center pointer-events-none"
        data-aos="fade-down"
        data-aos-offset="500"
      >
    </section>
  </div>
</template>

<script>
  export default {
    computed: {
      wedding_quotes() {
        return '"Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang."'
      }
    }
  }
</script>

<style mobile scoped>
  .mobile [name=mobile-content] .mobile-content-padding {
    padding: 0.81rem 1.5rem 0 1.5rem;
    position: relative;
    z-index: 2;
  }

  .mobile section[name=logo] img[name=wedding-logo] {
    width: 4.375rem;
  }

  .mobile p.quote {
    color: #FFF;
    text-align: justify;
    font-family: montserratR;
    font-size: 0.6875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.125rem;
    margin-top: 0.875rem;
    max-width: 84%;
  }

  .mobile p[name=q-desc].quote {
    font-family: montserratB;
  }

  .mobile img.bride-avatar-image {
    width: 45%;
    max-width: 13.8125rem;
  }

  .mobile img.groom-avatar-image {
    width: 45%;
    max-width: 13.8125rem;
  }

  .mobile section[name=avatars] {
    margin-top: 2.19rem;
  }

  .mobile [name=bride-avatar], .mobile [name=groom-avatar] {
    position: relative;
    z-index: 2;
  }

  .mobile [name=groom-avatar] {
    margin-top: 3.5rem;
  }

  .mobile [name=bride-avatar] .avatar-name {
    position: absolute;
    right: 0;
    top: 0.94rem;
    width: 55%;
    max-width: 15.875rem;
    padding: 0.75rem 1.5rem 0.75rem 1rem;
    background: #102135;
    z-index: 2;
  }

  .mobile [name=groom-avatar] .avatar-name {
    position: absolute;
    left: 0;
    bottom: 1.04rem;
    width: 55%;
    max-width: 15.875rem;
    padding: 0.75rem 1rem 0.75rem 1.5rem;
    background: #102135;
    z-index: 2;
  }

  .mobile [name=groom-avatar] .image {
    display: flex;
    justify-content: flex-end;
  }

  .mobile .avatar-name p.name {
    font-family: cinzeldecorativeB;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: conic-gradient(from 180deg at 48.5% 50%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }  

  .mobile .avatar-name p.from-text {
    color: #FFF;
    font-family: montserratR;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.31rem;
  }

  .mobile .avatar-name p.parents {
    color: #FFF;
    font-family: montserratR;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    margin-top: 0.19rem;
  }

  .mobile [name=and-avatar] p.and-avatar {
    font-family: cinzelR;
    font-size: 24.75rem;
    color: var(--q-desc-color);
    background: conic-gradient(from 180deg at 40.15% 53.73%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.5;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 1.75rem;
    bottom: 0;
  }

  .mobile [name=avatars] {
    position: relative;
  }

  .mobile [name=decorations] img.top-center {
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1;
  }
</style>