const baseComponents = {
  background: {
    withImage: true,
    bgColor: "#E2D3BD"
  },
  icon: {
    dir: "customSyasyaAzzam"
  },
  title: {
    fontSize: "2.625rem",
    fontFamily: "handycheera",
    color: "#E1C2C2",

    m_fontSize: "1.75rem"
  },
  button: {
    // start::desktop
    bgColor: "#E1C2C2",
    fontFamily: "poppinsM",
    fontSize: "1rem",
    fontColor: "#594F4A",
    minWidth: "15.625rem",
    // start::desktop

    // start::mobile
    m_fontSize: "0.75rem"
    // end::mobile
  },
  borderedText: {
    fontFamily: "opensansR",
    fontColor: "#FFFFFF",
    fontColorChatName: "#FFFFFF",
    fontSizeChat: "0.875rem",
    fontSizeCard: "1rem",
    fontFamilyB: "opensansB",
    fontSizeB: "1rem",
    borderColor: "#E2D3BD",
    borderWidth: "0.125rem",
    borderRadius: "0.625rem",
    iconWidth: "50",

    m_fontSizeCard: "0.75rem",
    m_fontSizeB: "0.75rem",
    m_iconWidth: "30"
  },
  colorWrapper: {
    bgColor: "#8A807D",
    border: "1px solid #8A807D"
  },
  input: {
    // start::desktop
    placeholderColor: "#B1B1B1",
    borderColor: "#CCCCCC",
    focusBorderColor: "#D4AF37",
    fontFamilyLabel: "poppinsSB",
    labelColor: "#FFFFFF",
    fontSizeLabel: "1rem",
    fontFamilyInput: "opensansR",
    fontSizeInput: "0.875rem",
    fontColorInput: "#1C192C",
    disabledFontColorInput: "#d2d2d2",
    // end::desktop

    // start::mobile
    m_fontSizeLabel: "0.75rem",
    m_fontSizeInput: "0.75rem"
    // end::mobile
  },
  textArea: {
    // start::desktop
    countFontFamily: "opensansR",
    countFontSize: "0.875rem",
    countFontColor: "#FFFFFF",
    labelColor: "#FFFFFF",
    focusBorderColor: "#E1C2C2",
    fontColorInput: "#1C192C",
    // end::desktop

    // start::mobile
    m_countFontSize: "0.75rem",
    // end::mobile
  },
  radio: {
    // start::desktop
    fontSizeLabel: "0.875rem",
    fontFamilyLabel: "opensansR",
    fontFamilyBoldLabel: "opensansB",
    radioSize: "1.25rem",
    radioColor: "#FFFFFF",
    radioColorActive: "#E1C2C2",
    radioBorderColorActive: "#E1C2C2",
    labelFontFamily: "poppinsSB",
    labelFontSize: "1rem",
    labelFontColor: "#FFFFFF",
    optionLabelColor: "#FFFFFF",
    optionLabelColorActive: "#E1C2C2",
    // end::desktop

    // start::mobile
    m_radioSize: "1rem",
    m_fontSizeLabel: "0.75rem",
    m_labelFontSize: "0.75rem",
    // end::mobile
  },
  notes: {
    bgColor: "#E2D3BD",
    fontColor: "#594F4A",
    fontFamily: "poppinsMI",
    fontSize: "1rem",
    borderColor: "#594F4A"
  },
  countDown: {
    // start::desktop
    bgColor: "#E2D3BD",
    labelFontColor: "#594F4A",
    labelFontFamily: "openSansR",
    labelFontSize: "1.125rem",
    numberFontColor: "#594F4A",
    numberFontFamily: "antsvalley",
    numberFontSize: "5rem",
    borderColor: "#594F4A",
    borderWidth: "0.125rem",
    // end::desktop

    // start::mobile
    m_labelFontSize: "0.75rem",
    m_numberFontSize: "2.5rem",
    m_boteCardMinHeight: "6.625rem"
    // end::mobile
  },
  musicButton: {
    // start::desktop
    opacity: 1,
    backgroundColor: "#998d8a",
    activeBackgroundColor: "#756c6a",
    buttonWidth: "4rem",
    iconWidth: "38",
    // end::desktop
    
    // start::mobile
    m_buttonWidth: "2.5rem",
    m_iconWidth: "24",
    // end::mobile
  },
  floatingMenu: {
    backgroundColor: "#594F4A",
    borderColor: "#594F4A"
  },
  loading: {
    background: "#1C192C"
  }
}

const sections = {
  cover: {
    //start::desktop
    brGrFontFamily: "handycheera",
    brGrFontSize: "5.125rem",
    brGrFontColor: "#FFE1E0",
    
    andFontFamily: "handycheera",
    andFontSize: "3.5rem",
    andFontColor: "#FFE1E0",
    andMarginTop: "-2.5rem",
    andMarginBottom: "-2.3rem",
    andMarginLeft: "11.5rem",
    andMarginRight: "0",

    dearFontFamily: "poppinsR",
    dearFontSize: "1.25rem",
    dearColor: "#FFFFFF",

    groomMarginRight: "0",
    groomPaddingLeft: "6rem",

    nameMarginBottomOnPublic: "10rem",
    // end::desktop
    
    // start::mobile
    m_brGrFontSize: "3rem",

    m_andFontSize: "2rem",
    m_andMarginTop: "-1.8rem",
    m_andMarginBottom: "-1.3rem",
    m_andMarginLeft: "0",
    m_andMarginRight: "-8rem",

    m_dearFontSize: "0.875rem",
    m_dearFontColor: "#FFFFFF",

    m_bridePaddingLeft: "0",
    m_bridePaddingRight: "0",
    
    m_groomPaddingRight: "0",
    m_groomPaddingLeft: "4rem",
    
    m_nameMarginBottomOnPublic: "5.875rem"
    // end::mobile
  },
  opening: {
    // start::desktop
    qContentFontFamily: "opensansR",
    qContentFontSize: "1rem",
    qContentColor: "#FFFFFF",
    qDescFontFamily: "opensansB",
    qDescFontSize: "1rem",
    qDescColor: "#FFF",
    
    avatarFrameWidth: "275",
    avatarNameFontSize: "1.25rem",
    avatarNameFontFamily: "poppinsSB",
    avatarDescFontSize: "0.875rem",
    avatarDescFontFamily: "poppinsR",
    avatarParentsFontFamily: "opensansR",
    avatarParentsFontSize: "1rem",
    avatarNameColor: "#FFF",
    avatarDescColor: "#E2D3BD",
    avatarMarginBottom: "0.625rem",
    parentWithAnd: true,
    // end::desktop

    // start::mobile
    m_qContentFontSize: "0.75rem",
    m_qDescFontSize: "0.75rem",
    
    m_avatarFrameWidth: "150",
    m_avatarNameFontSize: "0.875rem",
    m_avatarDescFontSize: "0.625rem",
    m_avatarParentsFontSize: "0.75rem",
    m_avatarParentsFontFamily: "opensansR",
    m_avatarMarginBottom: "0",
    m_avatarDescFontFamily: "opensansI",
    m_parentWithAnd: true
    // end::mobile
  },
  time: {
    // start::desktop
    scheduleTitleFF: "opensansB",
    scheduleTitleFS: "1.25rem",
    scheduleTitleC: "#FFFFFF",
    scheduleDescFF: "opensansR",
    scheduleDescFS: "1rem",
    scheduleDescC: "#FFFFFF",

    dateTimeIconSize: "50",
    dateCardType: 3,
    timeCardType: 3,
    // end::desktop

    // start::mobile
    m_scheduleTitleFS: "0.75rem",
    m_scheduleDescFS: "0.75rem",
    
    m_dateTimeIconSize: "30"
    // end::mobile
  },
  location: {
    // start::desktop
    titleFontFamily: "opensansB",
    titleFontSize: "1.25rem",
    titleColor: "#FFFFFF",
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#FFFFFF",
    iconWidth: "50",
    noBorder: true,
    headerBorderWidth: "1px 1px 0 1px",
    headerBorderStyle: "solid",
    headerBorderColor: "#8A807D",
    headerBorderRadius: "1.25rem 1.25rem 0 0",
    wrapperPaddingTop: "0",
    addressMaxWidth: "60%",
    // end::desktop

    // start::mobile
    m_titleFontSize: "0.75rem",
    m_descFontSize: "0.75rem",
    m_iconWidth: "30",
    // end::mobile
  },
  rsvp: {
    // start::desktop
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#FFFFFF",
    // end:desktop

    // start::mobile
    m_descFontSize: "0.75rem",
    // end::mobile
  },
  story: {
    lineColor: "#E1C2C2",
    iconBgColor: "#E1C2C2",
    iconBorderColor: "#E1C2C2",
    cardBorderColor: "#E2D3BD",
    textColor: "#FFFFFF"
  },
  gallery: {
    galleryInitialWidth: "133px",
    m_galleryInitialWidth: "104px"
  },
  wish: {
    noDataFontColor: "#FFF",

    // start::mobile
    m_chatBorderBottom: "1px solid transparent"
    // end::mobile
  },
  streaming: {
    streamingTextFontFamily: "opensansR",
    streamingTextFontSize: "1rem",
    streamingTextFontColor: "#161616",
  },
  gift: {
    // start::desktop
    codeFontFamily: "poppinsSB",
    codeFontSize: "1.25rem",
    codeFontColor: "#594F4A",
    detailFontFamily: "poppinsR",
    detailFontSize: "0.875rem",
    detailFontColor: "#594F4A",
    bgColor: "#E2D3BD",

    giftDetailFontFamily: "poppinsR",
    giftDetailFontSize: "1rem",
    giftDetailFontColor: "#594F4A",
    // end::desktop

    // start::mobile
    m_codeFontSize: "0.875rem",
    m_detailFontSize: "0.75rem",
    // start::mobile
  },
  warning: {
    // start::desktop
    warnIconFontFamily: "opensansSB",
    warnIconFontSize: "1rem",
    warnIconFontColor: "#FFFFFF",
    warnIconWidth: "150",
    // end::desktop

    // start::mobile
    m_warnIconWidth: "90",
    m_warnIconFontSize: "0.75rem"
    // end::mobile
  },
  footer: {
    logoType: "all_white",
    // start::desktop
    footerIconWidth: "200",
    // end::desktop
    
    // start::mobile
    m_footerIconWidth: "125"
    // end::mobile
  }
}

const decorations = {
  cover: {
    // start::desktop
    topLeft: true,
    topLeft_top: "0",
    topLeft_left: "0",
    topLeft_width: "34.2%",
    
    bottomRight: true,
    bottomRight_bottom: "0",
    bottomRight_right: "0",
    bottomRight_width: "19.9%",
    // start::desktop

    // start::mobile
    m_topLeft: true,
    m_topLeft_top: "0",
    m_topLeft_left: "0",
    m_topLeft_width: "71.25%",
    
    m_bottomRight: true,
    m_bottomRight_bottom: "0",
    m_bottomRight_right: "0",
    m_bottomRight_width: "57.8%",
    // start::mobile
  },
  opening: {
    // start::desktop
    bottomLeft: true,
    bottomLeft_bottom: "-2.8rem",
    bottomLeft_left: "0",
    bottomLeft_width: "14%",
    
    bottomRight: true,
    bottomRight_bottom: "-2.8rem",
    bottomRight_right: "0",
    bottomRight_width: "14%",
    // start::desktop
  },
  time: {
    // start::desktop
    bottomLeft: true,
    bottomLeft_bottom: "14.7rem",
    bottomLeft_left: "0",
    bottomLeft_width: "45.5%",
    bottomLeft_zIndex: "-1",
    
    bottomRight: true,
    bottomRight_bottom: "14.7rem",
    bottomRight_right: "0",
    bottomRight_width: "45.5%",
    bottomRight_zIndex: "-1",
    // start::desktop

    // start::mobile
    m_bottomCenter: true,
    m_bottomCenter_bottom: "10.063rem",
    m_bottomCenter_width: "100%",
    m_bottomCenter_zIndex: "-1"
    // start::mobile
  },
  location: {
    // start::mobile
    m_topLeft: true,
    m_topLeft_top: "-1.25rem",
    m_topLeft_left: "0",
    m_topLeft_width: "31%",
    m_topLeft_zIndex: "1",
    
    m_topRight: true,
    m_topRight_top: "-1.25rem",
    m_topRight_right: "0",
    m_topRight_width: "31%",
    m_topRight_zIndex: "1",
    // end::mobile
  },
  story: {
    // start::desktop
    topLeft: true,
    topLeft_top: "0",
    topLeft_left: "0",
    topLeft_width: "13.75%",
    topLeft_zIndex: "-1"
    // end::desktop
  },
  rsvp: {
    // start::desktop
    topLeft: true,
    topLeft_top: "-1.3rem",
    topLeft_left: "0",
    topLeft_width: "14%",
    
    topRight: true,
    topRight_top: "-1.3rem",
    topRight_right: "0",
    topRight_width: "14%",
    // start::desktop

    // start::mobile
    m_topRight: true,
    m_topRight_top: "-0.5rem",
    m_topRight_right: "0",
    m_topRight_width: "29.45%",
    // start::mobile
  },
  gallery: {
    // start::mobile
    m_bottomCenter: true,
    m_bottomCenter_bottom: "-6.188rem",
    m_bottomCenter_width: "100%",
    // end::mobile
  },
  wish: {
    // start::desktop
    topRight: true,
    topRight_top: "-1.3rem",
    topRight_right: "0",
    topRight_width: "13.4%",
    // start::desktop
  },
  footer : {
    // start::desktop
    bottomLeft: true,
    bottomLeft_bottom: "0",
    bottomLeft_left: "0",
    bottomLeft_width: "39.3%",
    
    bottomRight: true,
    bottomRight_bottom: "0",
    bottomRight_right: "0",
    bottomRight_width: "39.3%",
    // start::desktop

    // start::mobile
    m_bottomCenter: true,
    m_bottomCenter_bottom: "0",
    m_bottomCenter_width: "100%",
    // start::mobile
  }
}

export { baseComponents, sections, decorations }