<template>
  <BaseNotes v-bind="[$baseComponents.notes, $attrs]" />
</template>

<script>
import BaseNotes from "@/components/bases/BaseNotes"

export default {
  components: { BaseNotes }
}
</script>