<template>
  <div id="story-decoration" :style="styles">
    <!-- <div name="top-left" v-if="decor.topLeft">
      <img :src="`${iconDir}/top-left.png`" class="decoration" alt="top-left-decoration" data-aos="fade-right">
    </div> -->
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      return this.$decorations.story
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/story/${this.device}`
    },
    styles() {
      const css = this.$decorations.story

      const desktop = {
        '--top-left-top': css.topLeft_top,
        '--top-left-left': css.topLeft_left,
        '--top-left-width': css.topLeft_width,
        '--top-left-z-index': css.topLeft_zIndex,
      }

      const mobile = {
        '--m-top-left-top': css.m_topLeft_top,
        '--m-top-left-left': css.m_topLeft_left,
        '--m-top-left-width': css.m_topLeft_width,
        '--m-top-left-z-index': css.m_topLeft_zIndex,
      }

      return { ...mobile, ...desktop }
    }
  }
}
</script>

<style scoped>
  [name=top-left] img {
    top: var(--top-left-top);
    left: var(--top-left-left);
    width: var(--top-left-width);
    z-index: var(--top-left-z-index);
  }
</style>

<style mobile scoped>
  .mobile [name=top-left] img {
    top: var(--m-top-left-top);
    left: var(--m-top-left-left);
    width: var(--m-top-left-width);
    z-index: var(--m-top-left-z-index);
  }
</style>