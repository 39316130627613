<template>
  <div 
    class="music-btn-wrapper d-flex justify-content-center align-items-center"
    @click="changeMusicState"
  >
    <div class="disk">
      <div 
        name="disk-arm-combined"
        :class="{
          'on': !mute,
          running
        }"
      >
        <div name="disk-plate" id="disk-plate">
          <img 
            src="/icons/customVirandaAkbar/disk-plate.png" 
            alt="disk-plate" 
            class="disk-plate"
          >
        </div>

        <div name="disk-top">
          <svg class="disk-top-front" width="59" height="59" viewBox="0 0 59 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.9983 58.9733C47.8054 58.9733 58.9983 47.7804 58.9983 33.9733C58.9983 20.1662 47.8054 8.97333 33.9983 8.97333C20.1912 8.97333 8.99829 20.1662 8.99829 33.9733C8.99829 47.7804 20.1912 58.9733 33.9983 58.9733Z" fill="#C4C4C4"/>
            <path d="M17.8523 14.8933C14.4413 17.7823 11.8193 21.5673 10.3423 25.8873L27.7313 43.4343L27.7363 43.4293C29.6983 45.3333 32.5193 46.3563 35.5673 45.8403C39.2623 45.2143 42.3073 42.2473 42.8653 38.5413C43.1353 36.7493 42.9983 34.8263 42.9983 34.8263L17.8523 14.8933Z" fill="#949494"/>
            <path d="M40.4541 27.7103L40.4591 27.7063L14.7081 1.72033C12.7561 -0.231668 9.59012 -0.231668 7.63812 1.72033L1.98111 7.37733C0.0281074 9.33033 0.0281074 12.4953 1.98111 14.4483L27.7321 40.4343L27.7371 40.4293C29.6991 42.3333 32.5201 43.3563 35.5681 42.8403C39.2631 42.2143 42.2401 39.2363 42.8661 35.5413C43.3811 32.4933 42.3581 29.6733 40.4541 27.7103Z" fill="#F5F5F5"/>
          </svg>

            <svg class="disk-top-back" width="100" height="101" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M49.9983 100.973C77.6125 100.973 99.9983 78.5876 99.9983 50.9733C99.9983 23.3591 77.6125 0.973328 49.9983 0.973328C22.3841 0.973328 -0.00170898 23.3591 -0.00170898 50.9733C-0.00170898 78.5876 22.3841 100.973 49.9983 100.973Z" fill="#2C2C2E"/>
              <path d="M77.2102 82.8513C81.3523 82.8513 84.7102 79.4935 84.7102 75.3513C84.7102 71.2092 81.3523 67.8513 77.2102 67.8513C73.0681 67.8513 69.7102 71.2092 69.7102 75.3513C69.7102 79.4935 73.0681 82.8513 77.2102 82.8513Z" fill="#C4C4C4"/>
            </svg>
        </div>

        <div name="disk-arm">
          <img 
            src="/icons/customVirandaAkbar/disk-arm-arm.png" 
            alt="disk-arm-bottom" 
            class="disk-arm-bottom"
          >

          <svg class="disk-arm-top" width="48" height="83" viewBox="0 0 48 83" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7343 82.9863C26.7703 51.8343 37.6963 20.6323 41.8113 8.86333C42.7223 6.25533 41.3473 3.40333 38.7393 2.49233L32.4063 0.281335C29.8013 -0.628665 26.9523 0.741334 26.0403 3.34633C21.9833 14.9433 11.0523 46.1523 0.0292969 77.2693L15.7343 82.9863Z" fill="#949494"/>
            <path d="M44.2342 11.5623C44.2342 11.5623 29.7771 6.06134 23.0201 3.48734C20.3881 2.48534 17.4571 3.85434 16.5271 6.51234L5.65117 37.5433C4.72917 40.0993 6.01716 42.9233 8.55116 43.9023L29.7381 52.0913C32.3551 53.1033 35.2921 51.7633 36.2441 49.1233L47.1852 17.8693C48.0692 15.3153 46.7622 12.5213 44.2342 11.5623Z" fill="#535357"/>
            <path d="M14.5423 12.1773L9.71826 25.9413L40.2323 37.7363L45.1073 23.8113C36.2223 20.4303 24.3913 15.9273 14.5423 12.1773Z" fill="#C4C4C4"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      audio: null,
      mute: false,
      running: true
    }
  },

  computed: {
    volume_icon() {
      return this.mute ? "volume_off.svg" : "volume_up.svg"
    }
  },

  watch: {
    mute(val) {
      if(val) {
        const disk_plate = document.getElementById('disk-plate')

        var st = window.getComputedStyle(disk_plate, null)

        var tr = st.getPropertyValue("animation")
        console.log('test tr', tr);
      }
    }
  },

  methods: {
    __init() {
      this.getAudio()
      this.playMusic(false)
    },
    getAudio() {
      this.audio = new Audio(this.$weddingData.musicSrc)
    },
    changeMusicState() {
      this.mute = !this.mute

      setTimeout(() => {
        this.running = !this.running
        this.playMusic(this.mute)
      }, 1000)
    },
    playMusic(param) {
      if (!param) {
        if (typeof this.audio.loop == "boolean") {
          this.audio.loop = true
        } else {
          this.audio.addEventListener(
            "ended",
            () => {
              this.currentTime = 0
              this.play()
            },
            false
          );
        }
        this.audio.play()
      } else {
        this.audio.pause()
      }
    },
  },
  mounted() {
    this.__init()
  }
}
</script>

<style scoped>
  .music-btn-wrapper {
    right: 1.5rem;
    top: 1.5rem;
    opacity: var(--opacity) !important;
    position: fixed;
    width: var(--button-width);
    height: var(--button-width);
    border-radius: var(--button-width);
    transition: 0.5s;
    z-index: 10;
  }

  .music-btn-wrapper:hover {
    opacity: 1 !important;
    cursor: pointer;
    background-color: var(--active-background-color) !important;
    transition: 0.5s;
  }

  .disk .disk-plate {
    width: 4rem;
  }

  .disk .disk-arm {
    width: 1.25rem;
  }

  [name=disk-arm-combined] {
    position: relative;
  }

  [name=disk-arm-combined].on [name=disk-arm] .disk-arm-bottom {
    right: 0;
    transform: rotate(0deg);
    transition: 1s;
  }

  [name=disk-arm-combined].on [name=disk-arm] .disk-arm-top {
    right: -6.7px; 
    transform: rotate(-4deg);
    transition: 1s;
  }

  [name=disk-arm-combined] .disk-disk-top {
    width: 3rem;
    position: absolute;
    top: -21px;
    right: -14px;
    z-index: 61;
  }

  [name=disk-arm-combined] [name=disk-top] {
    position: relative;
  }

  [name=disk-arm-combined] [name=disk-top] .disk-top-front {
    position: absolute;
    right: -2px;
    top: 0;
    z-index: 63;
    width: 2rem;
  }

  [name=disk-arm-combined] [name=disk-top] .disk-top-back {
    position: absolute;
    right: -14px;
    top: -18px;
    z-index: 60;
    width: 3.2rem;
  }

  [name=disk-arm-combined].running [name=disk-plate] .disk-plate {
    animation-play-state: running;
  }

  [name=disk-arm-combined]:not(.running) [name=disk-plate] .disk-plate {
    animation-play-state: paused;
  }

  [name=disk-arm-combined] [name=disk-plate] .disk-plate {
    position: absolute;
    right: 27px;
    top: 11px;
    z-index: 60;
    width: 9rem;
    transform: rotateZ(0);
    transition: 1s;

    animation-name: rotation;
    animation-duration: 4s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  [name=disk-arm-combined] [name=disk-arm] .disk-arm-top {
    right: 5.5px;
    position: absolute;
    top: -1.9rem;
    z-index: 62;
    transform: rotateZ(-31deg);
    transition: 1s;
    width: 1.2rem;
  }

  [name=disk-arm-combined] [name=disk-arm] .disk-arm-bottom {
    position: absolute;
    z-index: 60;
    right: -10px;
    transform: rotateZ(-26deg);
    transition: 1s;
    width: 4rem;
  }
</style>

<style mobile scoped>
  .mobile .music-btn-wrapper {
    transform: translateX(15px) scale(0.5);
  }
</style>