import Vue from 'vue'

// default data
import { baseComponents, sections } from '@/assets/themes/default'
import defaultWeddingData from '@/assets/data/default/weddingData'

export default () => {
  Vue.prototype.$baseComponents = baseComponents
  Vue.prototype.$sections = sections
  Vue.prototype.$weddingData = defaultWeddingData
  Vue.prototype.$guestData = null
}