<template>
  <div
    id="story-section"
    class="d-flex align-items-center justify-content-center"
    :class="{
      'public': !$guestData
    }"
  >
    <div name="content">
      <div
        v-if="isDesktop" 
        class="top-decor"
      >
        <img :src="`${iconDir}/top-center.png`" class="time-top-decoration" alt="top-center-decoration" data-aos="zoom-in">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#102135" fill-opacity="1" d="M0,160L120,186.7C240,213,480,267,720,288C960,309,1200,299,1320,293.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
      </div>

      <div 
        v-else-if="isMobile" 
        class="top-decor-mobile"
      >
        <img 
          :src="`${iconDir}/top-center.png`" 
          class="time-top-decoration-mobile" 
          alt="top-center-decoration-mobile" 
          data-aos="fade-up"
        >

        <img 
          :src="`${iconDir}/top-front-center.svg`" 
          alt="time-top-front-decoration-mobile"
          class="time-top-front-decoration-mobile"
          data-aos="fade-up"
        >
      </div>

      <section name="title">
        <Title class="story-title" text="Cerita Kami" />
      </section>
      <section name="storyline">
        <StoryLine />
      </section>
    </div>

    <div name="decorations">
      <StoryDecoration v-if="isDesktop"/>
    </div>
  </div>
</template>

<script>
import StoryLine from "@/components/sections/story/StoryLine"
import StoryDecoration from "@/components/sections/story/StoryDecoration"

export default {
  components: { StoryLine, StoryDecoration },

  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/story/${this.device}`
    },
  }
}
</script>

<style scoped>
  section[name=title] p {
    margin-top: -2rem;
    height: 7rem;
  }
  
  #story-section {
    margin-top: -1rem;
  }

  #story-section.public {
    margin-top: 19rem;
  }
  
  section[name=storyline] {
    background-color: #102135;
    position: relative;
    z-index: 1;
  }

  section[name=title] {
    padding-top: 3rem;
    position: relative;
    z-index: 2;
    background: #102135;
  }

  .top-decor img {
    top: 9.8rem;
  }

  .top-decor img, .top-decor svg {
    width: 100%;
    position: absolute;
  }

  .top-decor img.time-top-decoration {
    transform: translateY(-100%);
  }

  .top-decor svg {
    transform: translateY(-93%) scaleX(-1);
  }

  .top-decor {
    margin-top: -1rem;
    width: 1490px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
  }
</style>

<style mobile>
   .mobile #story-section p.story-title {
    font-size: 2rem !important;
  }
</style>

<style mobile scoped>
  .mobile #story-section {
    position: relative;
    margin-bottom: 3rem;
  }

  .mobile .top-decor-mobile {
    position: absolute;
    left: 0;
    right: 0;
    top: -17rem;
  }

  .mobile .top-decor-mobile .time-top-decoration-mobile {
    z-index: -3;
    position: absolute;
    left: 0;
    right: 0;
    width: 100vw;
    top: 6.23rem;
  }

  .mobile .top-decor-mobile .time-top-front-decoration-mobile {
    position: absolute;
    z-index: -2;
    left: 0;
    right: 0;
    width: 100vw;
  }

  .mobile section[name=title] {
    padding-top: 0;
  }

  .mobile section[name=title] p {
     height: unset;
  }

  .mobile section[name=storyline] {
    margin-top: 0.63rem;
  }

  .mobile #storyline-container {
    min-height: unset;
  }
</style>