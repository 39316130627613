import Vue from 'vue'

// start::repositories
import settingRepository from '@/database/repositories/settingRepository'
import guestRepository from '@/database/repositories/guestRepository'
import rsvpRepository from '@/database/repositories/rsvpRepository'
import wishRepository from '@/database/repositories/wishRepository'
// end::repositories

// start::services
import wishService from '@/database/services/wishService'
import rsvpService from '@/database/services/rsvpService'
// end::services

export default () => {
  Vue.prototype.$settingRepository = new settingRepository()
  Vue.prototype.$guestRepository = new guestRepository()
  Vue.prototype.$rsvpRepository = new rsvpRepository()
  Vue.prototype.$wishRepository = new wishRepository()
  Vue.prototype.$wishService = new wishService()
  Vue.prototype.$rsvpService = new rsvpService()
}
  