export default [
  {
    name: 'poppinsR',
    src: 'fonts/poppins/Poppins-Regular.ttf'
  },
  {
    name: 'poppinsB',
    src: 'fonts/poppins/Poppins-Bold.ttf'
  },
  {
    name: 'poppinsBI',
    src: 'fonts/poppins/Poppins-BoldItalic.ttf'
  },
  {
    name: 'poppinsEB',
    src: 'fonts/poppins/Poppins-ExtraBold.ttf'
  },
  {
    name: 'poppinsEBI',
    src: 'fonts/poppins/Poppins-ExtraBoldItalic.ttf'
  },
  {
    name: 'poppinsBk',
    src: 'fonts/poppins/Poppins-Black.ttf'
  },
  {
    name: 'poppinsBkI',
    src: 'fonts/poppins/Poppins-BlackItalic.ttf'
  },
  {
    name: 'poppinsL',
    src: 'fonts/poppins/Poppins-Light.ttf'
  },
  {
    name: 'poppinsEL',
    src: 'fonts/poppins/Poppins-ExtraLight.ttf'
  },
  {
    name: 'poppinsLI',
    src: 'fonts/poppins/Poppins-LightItalic.ttf'
  },
  {
    name: 'poppinsELI',
    src: 'fonts/poppins/Poppins-ExtraLightItalic.ttf'
  },
  {
    name: 'poppinsI',
    src: 'fonts/poppins/Poppins-Italic.ttf'
  },
  {
    name: 'poppinsM',
    src: 'fonts/poppins/Poppins-Medium.ttf'
  },
  {
    name: 'poppinsMI',
    src: 'fonts/poppins/Poppins-MediumItalic.ttf'
  },
  {
    name: 'poppinsT',
    src: 'fonts/poppins/Poppins-Thin.ttf'
  },
  {
    name: 'poppinsTI',
    src: 'fonts/poppins/Poppins-ThinItalic.ttf'
  },
  {
    name: 'poppinsSB',
    src: 'fonts/poppins/Poppins-SemiBold.ttf'
  },
  {
    name: 'poppinsSBI',
    src: 'fonts/poppins/Poppins-SemiBoldItalic.ttf'
  }
]