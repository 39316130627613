import RepositoryBase from "../repository"

export default class wishService extends RepositoryBase {
  constructor() {
    super("wishes")
  }

  async submitWish(form) {

    console.log("Test form", form);
    form.createdAt = this.timestamp

    const id = await this.getWishId()
    
    return new Promise((resolve, reject) => {
      this.ref
      .doc(id)
      .set(form)
      .then(() => {
        resolve({ success: true })
      })
      .catch((error) => {
        reject({
          success: false,
          message: error.message
        })
      });
    })
  }

  getWishId() {
    return new Promise((resolve, reject) => {
      this.ref
        .orderBy("createdAt", "desc")
        // .limit(1)
        .get()
        .then(wish => {
          let id = '1'

          if(wish.size && wish.docs.length) {
            const data = wish.docs.sort((a, b) => a.id - b.id)
            const lastData = data[data.length - 1]

            id = '' + (parseInt(lastData.id) + 1)
          }

          resolve(id)
        })
        .catch((error) => {
          reject({
            success: false,
            message: error.message
          })
        })
    })
  }
}