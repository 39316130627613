<template>
  <ColorWrapper v-bind="$attrs">
    <slot></slot>
  </ColorWrapper>
</template>


<script>
export default {

}
</script>