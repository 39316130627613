var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-container"},[_c('p',{class:{
      'mb-4': _vm.isDesktop,
      'mb-1': _vm.isMobile
    },style:({
      fontFamily: _vm.fontFamilyLabel,
      fontSize: _vm.isDesktop ? _vm.fontSizeLabel : _vm.m_fontSizeLabel,
      color: _vm.labelColor
    })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('input',_vm._b({staticClass:"w-100",style:({
      '--placeholder-color': _vm.phColor,
      '--focus-border-color': _vm.fbColor,
      '--disabled-font-color': _vm.disabledFontColorInput,
      fontFamily: _vm.fontFamilyInput,
      fontSize: _vm.isDesktop ? _vm.fontSizeInput : _vm.m_fontSizeInput,
      color: _vm.fontColorInput,
      borderColor: _vm.borderColor
    }),on:{"input":function (e) { return _vm.$emit('input', e.target.value); }}},'input',_vm.$attrs,false))])}
var staticRenderFns = []

export { render, staticRenderFns }