import firebase from '@/config/firebase-config'
import { website } from '@/config/website-config'

export default class RepositoryBase {
  constructor(resource) {
    this.firebase = firebase
    this.db = firebase.firestore()
    this.ref = this.db.collection(`${website}/${resource}`)
    this.timestamp = new Date().getTime()
  } 
}