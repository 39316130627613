var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center position-relative",attrs:{"id":"time-section"}},[_c('div',{staticClass:"w-100",attrs:{"name":"content"}},[_c('div',{staticClass:"top-decor"},[(_vm.isDesktop)?[_c('img',{staticClass:"time-top-decoration",attrs:{"src":(_vm.iconDir + "/top-center.png"),"alt":"top-center-decoration","data-aos":"zoom-in"}}),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1440 320"}},[_c('path',{attrs:{"fill":"#102135","fill-opacity":"1","d":"M0,160L120,186.7C240,213,480,267,720,288C960,309,1200,299,1320,293.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"}})])]:(_vm.isMobile)?[_c('img',{staticClass:"time-top-center-2-decoration pointer-events-none",attrs:{"src":(_vm.iconDir + "/top-center-2.png"),"alt":"time-top-center-2-decoration","id":"time-section-scrollto"}}),_c('img',{staticClass:"time-top-center-1-decoration pointer-events-none",attrs:{"src":(_vm.iconDir + "/top-center-1.svg"),"alt":"time-top-center-1-decoration"}})]:_vm._e()],2),_c('div',{staticClass:"time-content"},[_c('section',{staticClass:"text-center",class:{
          'mt-19-5 mb-7-5': _vm.isDesktop,
        },attrs:{"name":"title"}},[_c('img',{attrs:{"width":_vm.isDesktop ? _vm.css.dateTimeIconSize : _vm.css.m_dateTimeIconSize,"src":("/icons/" + _vm.baseIconDir + "/date.svg")}}),_c('Title',{class:{
            'mt-5': _vm.isDesktop,
            'mt-4': _vm.isMobile
          },attrs:{"text":"Waktu Pelaksanaan"}})],1),_c('section',{staticClass:"d-flex justify-content-center",attrs:{"name":"date"}},[_c('DateCard',{attrs:{"type":_vm.css.dateCardType}})],1),_c('section',{staticClass:"d-flex justify-content-center",attrs:{"name":"time"}},[_c('TimeCard',{attrs:{"type":_vm.css.timeCardType}})],1),_c('section',{style:({
          marginTop: _vm.isDesktop ? '3.75rem' : ''
        }),attrs:{"name":"count-down"}},[_c('CountDown',{attrs:{"date":_vm.$weddingData.receptionCountDown}})],1)])]),_c('div',{attrs:{"name":"decorations"}},[_c('TimeDecoration')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }