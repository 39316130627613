<template>
  <div id="gift-modal">
    <div class="modal-wrapper">
      <div 
        class="modal-content text-center"
        :class="{ 
          'is-closing': is_closing
        }"
      >
        <section name="title">
          <img 
            src="/icons/customVirandaAkbar/wedding gift.svg" 
            alt="gift-icon"
            class="gift-icon"
          >

          <Title text="Kirim Hadiah" />
        </section>

        <section name="gift">
          <PaymentCard 
            icon="/icons/customVirandaAkbar/payment-1.png"
            icon_width="5.625rem"
            code="1180029229548"
            name="Akbar Riski"
          />

          <PaymentCard 
            icon="/icons/customVirandaAkbar/payment-2.png"
            icon_width="3.84613rem"
            code="100175017227"
            name="Viranda Adhiazni"
          />

          <PaymentCard 
            icon="/icons/customVirandaAkbar/payment-2.svg"
            icon_width="1.6875rem"
            title="E-wallet"
            code="089529229541"
            name="Akbar Riski"
          />
        </section>

        <div class="close-icon-wrapper">
          <img 
            class="close-icon"
            src="/icons/customVirandaAkbar/close-icon.svg" 
            alt="close-icon"
            @click="close"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/components/helpers/event-bus"
import PaymentCard from "@/components/sections/gift/PaymentCard"

export default {
  components: {
    PaymentCard
  },

  data() {
    return {
      is_closing: false
    }
  },

  methods: {
    close() {
      this.is_closing = true

      setTimeout(() => {
        this.is_closing = false

        EventBus.$emit('giftModalState', false)
      }, 500)
    }
  },
}
</script>

<style>
  html:has(#gift-modal) {
    overflow-y: hidden !important;
  }
</style>

<style scoped>
  .modal-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 998;
    left: 0;
    top: 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.791);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    animation-name: zoomAndBubbling;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    max-width: 75rem;
    min-width: 75rem;
    padding: 2.5rem 2.06rem 5.87rem 2.06rem;
    border-radius: 0.625rem;
    background: #102135;
    box-shadow: -10px 10px 2px 0px rgba(255, 255, 255, 0.15);
  }

  .modal-content.is-closing {
    animation-name: reverseZoomAndBubbling;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    margin: auto;
  }

  section[name=title] {
    text-align: center;
  } 

  section[name=gift] {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  .gift-button {
    margin-top: 2.75rem;
    display: flex;
    justify-content: center;
  }

  .close-icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    padding: 2.5rem 2.06rem;
  }

  .close-icon {
    width: 2rem;
    transition: 0.25s;
  }

  .close-icon:hover  {
    cursor: pointer;
    transform: scale(1.25);
    transition: 0.25s;
    filter: drop-shadow(1px 0px 2px rgba(255,255,255,0.66));
  }
</style>

<style mobile scoped>
  .mobile .modal-content {
    width: 85%;
    min-width: unset;
    max-width: unset;
    padding: 2rem 0.94rem 2.06rem 0.94rem;
  }

  .mobile .gift-icon {
    width: 2rem;
  }

  .mobile section[name=gift] {
    flex-direction: column;
  }

  .mobile .close-icon-wrapper {
    padding: 2rem 1.5rem;
  }
</style>