import Vue from 'vue'

export default () => {
  const requireComponent = require.context(
    '@/components/resources',
    false,
    /[A-Z]\w+\.(vue|js)$/
  )
  
  requireComponent.keys().forEach((fileName) => {
    const componentConfig = requireComponent(fileName)    

    Vue.component(fileName.replace(/^\.\/(.*)\.\w+$/, '$1'), componentConfig.default || componentConfig)
  })
}