<template>
  <ColorWrapper
    class="d-flex justify-content-center w-100 maxw-200"
    :style="styles"
  >
    <div class="text-center w-100">
      <section name="icon" v-if="type != 4">
        <img :width="isDesktop ? css.dateTimeIconSize : css.m_dateTimeIconSize" :src="`/icons/${iconDir}/time.svg`">
      </section>

      <template v-if="type == 1">
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad Nikah</p>
            <p class="desc">{{ $weddingData.akadTime }}</p>
          </div>
          <div class="detail-2">
            <p class="title">Resepsi</p>
            <p class="desc">{{ $weddingData.receptionTime }}</p>
          </div>
        </section>
      </template>

      <template v-else-if="type == 2">
        <section name="detail" class="mt-7-5">
          <p class="title">Resepsi</p>
          <p class="desc">{{ $weddingData.receptionTime }}</p>
        </section>
      </template>

      <template v-else-if="type == 3">
        <section name="detail" class="mt-7-5">
          <p class="title">Waktu Akad</p>
          <p class="desc">{{ $weddingData.akadTime }}</p>
        </section>
      </template>

      <template v-else-if="type = 4">
        <p class="text-center time-text">{{ time_label }} : 11.00 - 14.00</p>
      </template>

      <template v-else>
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad Nikah</p>
            <p class="desc">{{ $weddingData.akadTime }}</p>
          </div>
          <div class="detail-2">
            <p class="title">Resepsi Sesi 1</p>
            <p class="desc">{{ $weddingData.receptionTime }}</p>
          </div>
          <div class="detail-3">
            <p class="title">Resepsi Sesi 2</p>
            <p class="desc">{{ $weddingData.reception2Time }}</p>
          </div>
        </section>
      </template>
    </div>
  </ColorWrapper>
</template>

<script>
export default {
  props: {
    type: {
      type: Number,
      default: 1,
    }
  },

  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        scheduleTitleFF: "opensansB",
        scheduleTitleFS: "1.25rem",
        scheduleTitleC: "#161616",
        scheduleDescFF: "opensansR",
        scheduleDescFS: "1rem",
        scheduleDescC: "#161616",

        ...this.$sections.time
      }
    }
  },

  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--schedule-title-ff': css.scheduleTitleFF,
        '--schedule-title-fs': css.scheduleTitleFS,
        '--schedule-title-c': css.scheduleTitleC,
        '--schedule-desc-ff': css.scheduleDescFF,
        '--schedule-desc-fs': css.scheduleDescFS,
        '--schedule-desc-c': css.scheduleDescC,
      }

      const mobile = {
        '--m-schedule-title-fs': css.m_scheduleTitleFS,
        '--m-schedule-desc-fs': css.m_scheduleDescFS,
      }

      return { ...desktop, ...mobile }
    },

    time_label() {
      return this.isDesktop ? 'Pukul' : 'Waktu'
    }
  }
}
</script>

<style scoped>
  section[name=detail] p.title {
    font-family: var(--schedule-title-ff);
    font-size: var(--schedule-title-fs);
    color: var(--schedule-title-c);
  }

  section[name=detail] p.desc {
    font-family: var(--schedule-desc-ff);
    font-size: var(--schedule-desc-fs);
    color: var(--schedule-desc-c);
    margin-top: 0.313rem;
  }

  p.time-text {
    color: #FFF;
    text-align: center;
    font-family: cinzelB;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.15rem;
    margin-left: 1.75rem;
  }
</style>

<style mobile scoped>
  .mobile section[name=detail] {
    margin-top: 0.938rem !important;
  }

  .mobile section[name=detail] p.title {
    font-size: var(--m-schedule-title-fs);
  }

  .mobile section[name=detail] p.desc {
    font-size: var(--m-schedule-desc-fs);
    margin-top: 0.313rem;
  }

  .mobile p.time-text {
    font-size: 1rem;
    letter-spacing: 0.15rem;
    margin-left: 0;
  }
</style>