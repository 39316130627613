<template>
  <div
    id="footer-section"
    class="d-flex align-items-center justify-content-center"
    :class="{
      'mt-13 h-rem-21-875': isDesktop,
      'pb-30 pt-18': isMobile
    }"
  >
    <div name="content" class="w-100 text-center position-relative z-index-2">
      <a :href="instagram_url" target="_blank">
        <img 
          :width="isDesktop ? css.footerIconWidth : css.m_footerIconWidth" 
          src="/momento_logo.svg"
        >
      </a>
    </div>

    <div name="decorations">
      <FooterDecoration />
    </div>
  </div>
</template>

<script>
import FooterDecoration from "@/components/sections/footer/FooterDecoration"

export default {
  components: { FooterDecoration },
  data() {
    return {
      css: {
        ...this.$sections.footer
      },
      instagram_url: "https://www.instagram.com/momentoprjct"
    }
  },
  computed: {
    logoSrc() {
      return `https://firebasestorage.googleapis.com/v0/b/momento-projects.appspot.com/o/master%2Fmomento_logo_${this.css.logoType}.png?alt=media`
    }
  }
}
</script>

<style scoped>
  #footer-section {
    background: #101822;
  }

  [name=content] {
    margin-top: -5rem;
  }
</style>

<style mobile scoped>
  .mobile #footer-section {
    padding-top: 7.25rem !important;
    padding-bottom: 8.5rem !important;
  }
</style>