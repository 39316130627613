export default [
  {
    name: 'cinzeldecorativeR',
    src: 'fonts/cinzeldecorative/CinzelDecorative-Regular.ttf'
  },
  {
    name: 'cinzeldecorativeB',
    src: 'fonts/cinzeldecorative/CinzelDecorative-Bold.ttf'
  },
  {
    name: 'cinzeldecorativeEB',
    src: 'fonts/cinzeldecorative/CinzelDecorative-Black.ttf'
  }
]