<template>
  <div 
    class="radio-container"
    :style="styles"  
  >
    <template v-if="label">
      <p
        class="radio-label"
        :class="{
          'mb-1-252': isMobile
        }"
        :style="{
          fontFamily: labelFontFamily,
          fontSize: isDesktop ? labelFontSize : m_labelFontSize,
          color: labelFontColor
        }"
      >
        {{ label }}
      </p>
    </template>
    <div
      name="radio-wrapper"
      :class="{ 'd-flex': isDesktop }"
    >
      <template v-for="item in radio_items">
        <div 
          :key="item.id" class="radio-item-wrapper"
          @click="selectedValue = item.value"
        >
          <div 
            class="radio-item" 
            :class="{ active: item.value == selectedValue }"
          >
            <input
              type="radio"
              :id="item.id"
              :name="name"
              :value="item.value"
              v-model="selectedValue"
            />
            <label
              :style="{ fontFamily: fontFamilyLabel }"
              :for="item.id"
            >
              {{ item.label }}
            </label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["radio_items", "name", "label", "selected", "fontSizeLabel", "optionLabelColor", "optionLabelColorActive", "fontFamilyLabel", "fontFamilyBoldLabel", "radioSize", "radioColor", "radioColorActive", "radioBorderColorActive", "labelFontFamily", "labelFontSize", "labelFontColor", "m_fontSizeLabel", "m_labelFontSize", "m_radioSize"],
  watch: {
    selectedValue(val) {
      this.$emit("input", val)
    },

    '$attrs.value'(val) {
      this.selectedValue = val
    }
  },
  data() {
    return {
      selectedValue: this.$attrs.value,
      size: this.radioSize || "20px",
      color: this.radioColor || "#707070",
      colorActive: this.radioColorActive || this.radioColor || "#707070",
      borderColorActive: this.radioBorderColorActive || this.radioColor || "#707070",
      labelColor: this.optionLabelColor || "#161616",
      labelColorActive: this.optionLabelColorActive || this.labelColor || "#161616",
      labelSize: this.fontSizeLabel || "14px"
    }
  },
  computed: {
    styles() {
      const desktop = {
        '--fontSizeLabel': this.labelSize,
        '--optionLabelColor': this.labelColor,
        '--optionLabelColorActive': this.labelColorActive,
        '--radioSize': this.size,
        '--radioColor': this.color,
        '--radioColorActive': this.colorActive,
        '--radioBorderColorActive': this.borderColorActive,

        '--fontFamilyBoldLabel': this.fontFamilyBoldLabel
      }

      const mobile = {
        '--m-radioSize': this.m_radioSize,
        '--m-fontSizeLabel': this.m_fontSizeLabel,
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  p {
    font-family: poppinsSB;
    font-size: 1rem;
  }

  [name=radio-wrapper] {
    justify-content: space-between;
    gap: 1rem;
  }
  
  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 7px 0 0;
    animation-name: example;
    animation-duration: 2s;
    padding-top: 0.68rem;
    padding-bottom: 0.81rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    border-radius: 0.9375rem;
    border: 1px solid #fff;
    width: 92.5%;
  }

  .radio-item.active {
    background-color: #5D7394;
    animation: bubbling 0.5s;
  }

  .radio-item-wrapper {
    width: 100%;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  
  .radio-item label {
    font-size: var(--fontSizeLabel);
    font-weight: normal;
    color: var(--optionLabelColor);
  }

  .radio-item label {
    position: relative;
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4.5px;
    margin: 0 5px 0 0;
    width: var(--radioSize);
    height: var(--radioSize);
    border-radius: 14px;
    border: 1px solid var(--radioColor);
    background-color: transparent;
  }

  .radio-item.active label:before {
    border-color: var(--radioBorderColorActive);
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 14px;
    border-color: #5D7394;
    width: calc(var(--radioSize) - 0.29rem);
    height: calc(var(--radioSize) - 0.29rem);
    position: absolute;
    top: 7.45px;
    left: 3.4px;
    content: " ";
    display: block;
    background: var(--radioColorActive);
  }

  .radio-item:hover, .radio-item input:hover, .radio-item label:hover {
    cursor: pointer;
  }

  .radio-item:hover:not(.active) {
    background-color: #5d73945d;
  }

  .radio-item input[type=radio]:checked ~ label {
    font-family: var(--fontFamilyBoldLabel) !important;
    background: var(--optionLabelColorActive);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .radio-label {
    margin-bottom: 1.5rem;
  }
</style>

<style mobile scoped>
  .mobile .radio-item label {
    font-size: var(--m-fontSizeLabel);
  }

  .mobile .radio-item label:before {
    width: var(--m-radioSize);
    height: var(--m-radioSize);
  }
  
  .mobile .radio-item input[type=radio]:checked + label:after {
    width: calc(var(--m-radioSize) - 4px);
    height: calc(var(--m-radioSize) - 4px);
    top: 2.5px;
  }
</style>