<template>
  <div 
    class="payment-card-container" 
  >
    <div name="left-wrapper">
      <div class="payment-top">
        <img 
          :src="icon"
          :style="{
            width: icon_width
          }"
          :class="{ is_e_wallet }"
        >

        <p 
          class="title"
          :class="{
            'text-left': isMobile
          }"
        >
          {{ title }}
          <template v-if="isMobile && is_e_wallet">
            <br>
            <span style="font-size: 0.75rem; text-align-left">
              (GoPay, OVO, Dana dsb)
            </span>
          </template>
        </p>
        <p v-if="is_e_wallet && isDesktop">(GoPay, OVO, Dana dsb)</p>
      </div>

      <p class="code text-left"> {{ code }} an. <span class="name">{{ name }}</span></p>
    </div>
    
    <div name="right-wrapper">
      <img 
        name="copy-icon" 
        :src="copyIcon"
        @click="copyText"
      >
    </div>

    <CopiedToast ref="copied-toast"/>
  </div>
</template>

<script>
import CopiedToast from "@/components/sections/gift/CopiedToast"

export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    icon_width: {
      type: String,
      default: '139px'
    },
    title: {
      type: String,
      default: ''
    }
  },

  components: { CopiedToast },

  data() {
    return {
      iconDir: this.$baseComponents.icon.dir || "default",
      isCopied: false,
    }
  },

  computed: {
    copyIcon() {
      const { isCopied, iconDir } = this
      const icon = isCopied ? "copied.png" : "copy.svg"

      return `/icons/${iconDir}/${icon}`
    },

    is_e_wallet() {
      return this.title == 'E-wallet'
    }
  },

  methods: {
    copyText() {
      if(!this.isCopied) {
        this.$refs['copied-toast'].$el.classList.remove('d-none')
  
        this.$swal({
          html: this.$refs['copied-toast'].$el,
          customClass: "custom-modal",
          heightAuto: false,
          showConfirmButton: false,
          backdrop: false,
          timer: 1500,
          position: 'top',
        })

        this.isCopied = true
        
        navigator.clipboard.writeText(this.code)
        
        setTimeout(() => this.isCopied = false, 1500)
      }
    }
  }
}
</script>

<style>
  .custom-modal {
    background: transparent !important;
    box-shadow: unset !important;
  }
</style>

<style scoped>
  [name=copy-icon] {
    width: 1.5rem; 
    transition: 0.25s;
  }

  [name=copy-icon]:hover {
    cursor: pointer;
    transform: scale(1.25);
    transition: 0.25s;
  }

  .payment-card-container {
    border-radius: 0.625rem;
    background: linear-gradient(106deg, #FBFBFD 7.73%, #C8D4DA 24.76%, #E3E9EE 45.33%, #D6DFE6 64.61%, #FAFBFC 80.12%, #B8C9D3 98.11%);
    width: 21.875rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  [name=left-wrapper] {
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
  }

  [name=right-wrapper] {
    display: flex;
    align-items: center;
  }

  p.code {
    font-family: opensansR;
    color: #102135;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  p.code span.name {
    font-family: opensansB;
  }

  .payment-top {
    display: flex;
    gap: 0.62rem;
  }

  p.title {
    color: #102135;
    font-family: opensansB;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
</style>

<style mobile scoped>
  .mobile .payment-card-container {
    width: unset;
  }

  .mobile img.is_e_wallet {
    height: 1.6875rem;
  }
</style>