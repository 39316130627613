<template>
  <div 
    class="mobile-duo-image-card image-wrapper"
  >
    <div 
      class="image-duo-wrapper one"
      :class="{
        'flipped': first_model
      }"
    >
      <div class="front">
        <image-element
          :img="first_front"
          @click.native="imageClicked(first_front)"
        />
      </div>

      <div class="back">
        <image-element
          :img="first_back"
          @click.native="imageClicked(first_back)"
        />
      </div>
    </div>

    <div 
      class="image-duo-wrapper two"
      :class="{
        'flipped': second_model
      }"  
    >
      <div class="front">
        <image-element
          :img="second_front"
          @click.native="imageClicked(second_front)"
        />
      </div>

      <div class="back">
        <image-element
          :img="second_back"
          @click.native="imageClicked(second_back)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageElement from '@/components/sections/gallery/ImageElement'

export default {
  components: {
    ImageElement  
  },

  props: {
    first_front: {
      type: String,
      default: ''
    },

    first_back: {
      type: String,
      default: ''
    },

    second_front: {
      type: String,
      default: ''
    },

    second_back: {
      type: String,
      default: ''
    },

    first_model: {
      type: Boolean,
      default: false
    },

    second_model: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    imageClicked(src) {
      this.$emit('clicked', src)
    }
  }
}
</script>

<style scoped>
  .image-wrapper {
    width: 100%;
    height: 11.25rem;
    transform-style: preserve-3d;
    position: relative;
    display: flex;
    gap: 0.38rem;
  }

  .image-duo-wrapper:not(.flipped) {
    animation-name: reverseFlipImage;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .flipped {
    animation-name: flipImage;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .image-duo-wrapper.one {
    width: calc(33% - 0.19rem);
    height: 11.25rem;
    background: gray;
    position: relative;
    transform-style: preserve-3d;
  }

  .image-duo-wrapper.two {
    width: calc(67% - 0.19rem);
    height: 11.25rem;
    background: gray;
    position: relative;
    transform-style: preserve-3d;
  }

  .image-duo-wrapper:first-child {
    margin-bottom: 0.5rem;
  }

  .front, .back {
    width: 100%;
    height: inherit;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
  }
</style>