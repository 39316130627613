<template>
  <div 
    class="wrapper-container" 
    :style="{ 
        backgroundColor,
        border: !noBorder ? border : null
      }"
    >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["backgroundColor", "border", "noBorder"]
}
</script>

<style scoped>
  .wrapper-container {
    background-color: #EEEEEE;
    border-radius: 1.25rem;
  }
</style>