import { website_url } from '@/config/website-config'

const generateLocalSrc = img_src => {
  return `${website_url}${img_src}`
}

export default [
  generateLocalSrc('/decorations/customVirandaAkbar/time/desktop/top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/cover/desktop/top-left.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/cover/desktop/bottom-right.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/rsvp/desktop/top-center.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/rsvp/desktop/top-2-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/story/desktop/top-center.png'),
  generateLocalSrc('/icons/customVirandaAkbar/location-image.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/filter-instagram/desktop/top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/filter-instagram/desktop/background-center.png'),
  generateLocalSrc('/momento_logo.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/footer/desktop/bottom-center.png'),
  generateLocalSrc('/icons/customVirandaAkbar/email-closed.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/date.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/notes.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/story/desktop/bottom-section.png'),
  generateLocalSrc('/icons/customVirandaAkbar/filter-ig-icon.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding gift.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/payment-1.png'),
  generateLocalSrc('/icons/customVirandaAkbar/payment-2.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/copy.svg')
]