import { website_url } from '@/config/website-config'

const generateLocalSrc = img_src => {
  return `${website_url}${img_src}`
}

export default [
  generateLocalSrc('/decorations/customVirandaAkbar/cover/mobile/top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/cover/mobile/bottom-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/rsvp/mobile/bg-top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/opening/mobile/top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/time/mobile/top-center-2.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/time/mobile/top-center-1.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/rsvp/mobile/top-center.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/rsvp/mobile/top-2-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/story/mobile/top-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/story/mobile/top-front-center.svg'),
  generateLocalSrc('/decorations/customVirandaAkbar/story/desktop/bottom-section.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/filter-instagram/mobile/background-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/footer/mobile/bottom-center.png'),
  generateLocalSrc('/decorations/customVirandaAkbar/wish/mobile/background-decoration.png')
]