<template>
  <ColorWrapper
    class="d-flex justify-content-center w-100 maxw-200 py-5"
    :class="{
      'py-5': isDesktop,
      'pt-1 pb-6': isMobile
    }"
    :style="styles"
  >
    <div class="text-center w-100">
      <section name="icon" v-if="cardType != 5">
        <img :width="isDesktop ? css.dateTimeIconSize : css.m_dateTimeIconSize" :src="`/icons/${iconDir}/date.svg`">
      </section>

      <template v-if="cardType == 1">
        <section name="detail" class="mt-7-5">
          <p class="title">Akad & Resepsi</p>
          <p class="desc">{{ $weddingData.akadDate }}</p>
        </section>
      </template>

      <template v-else-if="cardType == 2">
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad</p>
            <p class="desc">{{ $weddingData.akadDate }}</p>
          </div>
          <div class="detail-2">
            <p class="title">Resepsi</p>
            <p class="desc">{{ $weddingData.receptionDate }}</p>
          </div>
        </section>
      </template>

      <template v-else-if="cardType == 3">
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad Nikah</p>
            <p class="desc">{{ $weddingData.akadDate }}</p>
          </div>
        </section>
      </template>

      <template v-else-if="cardType == 4">
        <section name="detail" class="mt-7-5">
          <p class="title">Resepsi</p>
          <p class="desc">{{ $weddingData.receptionDate }}</p>
        </section>
      </template>

      <template v-else-if="cardType == 5">
        <section 
          name="detail" 
          class="date-va d-flex justify-content-center align-items-center"
        >
          <p class="day">Sabtu</p>

          <div class="card-item-wrapper">
            <p class="date">11</p>

            <p class="month">November</p>
          </div>

          <p class="year">2023</p>
        </section>
      </template>
    </div>
  </ColorWrapper>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        scheduleTitleFF: "opensansB",
        scheduleTitleFS: "1.25rem",
        scheduleTitleC: "#161616",
        scheduleDescFF: "opensansR",
        scheduleDescFS: "1rem",
        scheduleDescC: "#161616",

        ...this.$sections.time
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--schedule-title-ff': css.scheduleTitleFF,
        '--schedule-title-fs': css.scheduleTitleFS,
        '--schedule-title-c': css.scheduleTitleC,
        '--schedule-desc-ff': css.scheduleDescFF,
        '--schedule-desc-fs': css.scheduleDescFS,
        '--schedule-desc-c': css.scheduleDescC,
      }

      const mobile = {
        '--m-schedule-title-fs': css.m_scheduleTitleFS,
        '--m-schedule-desc-fs': css.m_scheduleDescFS,
      }

      return { ...desktop, ...mobile }
    },
    cardType() {
      return parseInt(this.type) || 1
    }
  }
}
</script>

<style scoped>
  section[name=detail] p.title {
    font-family: var(--schedule-title-ff);
    font-size: var(--schedule-title-fs);
    color: var(--schedule-title-c);
  }

  section[name=detail] p.desc {
    font-family: var(--schedule-desc-ff);
    font-size: var(--schedule-desc-fs);
    color: var(--schedule-desc-c);
    margin-top: 0.313rem;
  }

  section[name=detail].date-va p.day, 
  section[name=detail].date-va p.year,
  section[name=detail].date-va p.date,
  section[name=detail].date-va p.month {
    color: #FFF;
    text-align: center;
    font-family: cinzelB;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  section[name=detail].date-va p.month {
    font-family: cinzelR;
    font-size: 1.75rem;
    font-weight: 400;
  }

  section[name=detail].date-va p.month, section[name=detail].date-va p.date {
    line-height: 80%;
    padding: 0 2.5rem;
  }

  section[name=detail].date-va p:not(.date):not(.month) {
    padding: 1.53rem 3.5rem;
  }

  section[name=detail].date-va .card-item-wrapper {
    border-left: 0.0625rem solid #fff;
    border-right: 0.0625rem solid #fff;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }
</style>

<style mobile scoped>
  .mobile section[name=detail] {
    margin-top: 0.938rem !important;
  }

  .mobile section[name=detail] p.title {
    font-size: var(--m-schedule-title-fs);
  }

  .mobile section[name=detail] p.desc {
    font-size: var(--m-schedule-desc-fs);
  }

  .mobile section[name=detail] p.day, .mobile section[name=detail] p.year {
    font-size: 1.125rem;
    padding-top: 1.031rem !important;
    padding-bottom: 1.031rem !important;
  }

  .mobile section[name=detail] p.date {
    font-size: 1.75rem;
  }

  .mobile section[name=detail] p.month {
    font-size: 0.875rem;
  }

  .mobile section[name=detail] p.day {
    padding-right: 1rem !important;
    padding-left: 0 !important;
  }

  .mobile section[name=detail] p.year {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }

  .mobile section[name=detail].date-va p.month, section[name=detail].date-va p.date {
    padding-left: 0;
    padding-right: 0;
  }

  .mobile section[name=detail].date-va .card-item-wrapper {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>