var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex align-items-center justify-content-center mt-16-5': _vm.isDesktop,
    'mt-8-5 px-5': _vm.isMobile
  },attrs:{"id":"warning-section"}},[_c('div',{attrs:{"name":"content"}},[_c('section',{class:{
        'w-rem-68-75 m-auto': _vm.isDesktop
      },attrs:{"name":"notes"}},[_c('BorderedText',{staticClass:"mb-3-752",attrs:{"icon":"wedding-invitation.svg"}},[_c('p',[_vm._v("Acara ini diselenggarakan dengan mematuhi protokol kesehatan yang berlaku demi mencegah penyebaran virus Covid-19. Tanpa mengurangi rasa hormat, kepada para tamu undangan dimohon kesediaannya untuk :")])])],1),_c('section',{class:{
        'w-rem-86-875 mt-10': _vm.isDesktop
      },attrs:{"name":"protocols"}},[(_vm.isDesktop)?[_c('div',{staticClass:"d-flex justify-content-around"},[_vm._l((_vm.protocols),function(protocol,index){return [_c('WarningIcon',_vm._b({key:index},'WarningIcon',protocol,false))]})],2)]:[_c('div',[_c('div',{staticClass:"d-flex justify-content-evenly"},[_vm._l(([].concat( _vm.protocols ).splice(0, 2)),function(protocol,index){return [_c('WarningIcon',_vm._b({key:index},'WarningIcon',protocol,false))]})],2),_c('div',{staticClass:"d-flex justify-content-around mt-5"},[_vm._l(([].concat( _vm.protocols ).splice(2, 3)),function(protocol,index){return [_c('WarningIcon',_vm._b({key:index},'WarningIcon',protocol,false))]})],2)])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }