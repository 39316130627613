<template>
  <div
    id="gallery-section"
    class="position-relative"
  >
    <div id="gallery-section-scrollto" class="position-absolute" style="top: -7rem; z-index: -99"></div>
    <div 
      name="content" 
      class="position-relative z-index-2"
    >
      <section name="initial" class="text-center">
        <img 
          class="initial-image"
          :width="isDesktop ? css.galleryInitialWidth : css.m_galleryInitialWidth" 
          :src="$weddingData.galleryInitialSrc"
        >
      </section>

      <section 
        name="images"
      >
        <template v-if="isDesktop">
          <one-image-card
            :front="gallery_images[0]"
            :back="gallery_images[5]"
            v-model="flipped.first"
            @clicked="openGalleriesViewer($event)"
          />

          <duo-image-card
            :first_front="gallery_images[1]"
            :first_back="gallery_images[6]"
            :second_front="gallery_images[2]"
            :second_back="gallery_images[7]"
            :first_model="flipped.second"
            :second_model="flipped.third"
            @clicked="openGalleriesViewer($event)"
          />

          <one-image-card
            :front="gallery_images[3]"
            :back="gallery_images[8]"
            v-model="flipped.fourth"
            @clicked="openGalleriesViewer($event)"
          />

          <one-image-card
            :front="gallery_images[4]"
            :back="gallery_images[9]"
            v-model="flipped.fifth"
            @clicked="openGalleriesViewer($event)"
          />
        </template>

        <template v-else-if="isMobile">
          <mobile-one-image-card
            :front="gallery_images[1]"
            :back="gallery_images[6]"
            v-model="flipped.first"
            @clicked="openGalleriesViewer($event)"
          />

          <mobile-duo-image-card
            :first_front="gallery_images[0]"
            :first_back="gallery_images[5]"
            :second_front="gallery_images[2]"
            :second_back="gallery_images[7]"
            :first_model="flipped.second"
            :second_model="flipped.third"
            @clicked="openGalleriesViewer($event)"
          />

          <mobile-duo-fair-image-card
            :first_front="gallery_images[3]"
            :first_back="gallery_images[8]"
            :second_front="gallery_images[4]"
            :second_back="gallery_images[9]"
            :first_model="flipped.fourth"
            :second_model="flipped.fifth"
            @clicked="openGalleriesViewer($event)"
          />
        </template>
      </section>

      <section name="bottom-decor">
        <img 
          src="/decorations/customVirandaAkbar/story/desktop/bottom-section.png" 
          alt="bottom-decor" 
          class="bottom-decor"
        >
      </section>
    </div>
  </div>
</template>

<script>
import OneImageCard from '@/components/sections/gallery/OneImageCard'
import DuoImageCard from '@/components/sections/gallery/DuoImageCard'

import MobileOneImageCard from '@/components/sections/gallery/MobileOneImageCard'
import MobileDuoImageCard from '@/components/sections/gallery/MobileDuoImageCard'
import MobileDuoFairImageCard from '@/components/sections/gallery/MobileDuoFairImageCard'

export default {
  components: {
    OneImageCard,
    DuoImageCard,
    MobileOneImageCard,
    MobileDuoImageCard,
    MobileDuoFairImageCard
  },
  
  data() {
    return {
      css: {
        ...this.$sections.gallery
      },

      flipped: {
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false
      },

      flip_all: false
    }
  },

  computed: {
    gallery_images() {
      return this.$weddingData.galleries
    }
  },

  watch: {
    flip_all(val) {
      const { flipped } = this
      const flipped_keys = Object.keys(flipped)

      flipped_keys.forEach((key, index) => {
        const index_mod = val ? index : (flipped_keys.length - index)

        setTimeout(() => flipped[key] = val, (index_mod / 3 * 1000))
      })
    }
  },

  methods: {
    flipIntervally() {
      setInterval(() => this.flip_all = !this.flip_all, 5000)
    },

    openGalleriesViewer(src) {
      const { gallery_images } = this

      const index = gallery_images.findIndex(img => img == src)

      this.$viewerApi({
        images: this.gallery_images,
        options: {
          initialViewIndex: index ? index : 0,
        }
      })
    }
  },

  mounted() {
    this.flipIntervally()
  }
}
</script>

<style scoped>
  .frame-container {
    height: 28.125rem;
  }

  .frame-container img:hover {
    cursor: pointer;
  }

  section[name=images] {
    display: flex;
    justify-content: center;
    gap: 0.44rem;
  }

  #galleries-viewer {
    display: none;
  }

  section[name=bottom-decor] {
    text-align: center;
    margin-top: 3.75rem;
  }

  section[name=bottom-decor] img {
    width: 37.6875rem;
  }
</style>

<style mobile scoped>
  .mobile .frame-container {
    height: unset !important;
  }

  .mobile .initial-image {
    width: 6.25rem;
  }

  .mobile section[name=initial]  {
    margin-bottom: 0.812rem;
  }

  .mobile section[name=images] {
    padding-left: 0.94rem;
    padding-right: 0.94rem;
    flex-direction: column;
    gap: 0.38rem;
  }

  .mobile section[name=bottom-decor] {
    margin-top: 1.88rem;
    padding-left: 0.94rem;
    padding-right: 0.94rem;
  }

  .mobile section[name=bottom-decor] img {
    width: 100%;
  }
</style>