<template>
  <div
    id="warning-section"
    class=""
    :class="{
      'd-flex align-items-center justify-content-center mt-16-5': isDesktop,
      'mt-8-5 px-5': isMobile
    }"
  >
    <div name="content">
      <section
        name="notes"
        :class="{
          'w-rem-68-75 m-auto': isDesktop
        }"
      >
        <BorderedText icon="wedding-invitation.svg" class="mb-3-752">
          <p>Acara ini diselenggarakan dengan mematuhi protokol kesehatan yang berlaku demi mencegah penyebaran virus Covid-19. Tanpa mengurangi rasa hormat, kepada para tamu undangan dimohon kesediaannya untuk :</p>
        </BorderedText>
      </section>

      <section 
        name="protocols"
        :class="{
          'w-rem-86-875 mt-10': isDesktop
        }"
        >
        <template v-if="isDesktop">
          <div class="d-flex justify-content-around">
            <template v-for="(protocol, index) in protocols">
              <WarningIcon v-bind="protocol" :key="index" />
            </template>
          </div>
        </template>

        <template v-else>
          <div>
            <div class="d-flex justify-content-evenly">
              <template v-for="(protocol, index) in [...protocols].splice(0, 2)">
                <WarningIcon v-bind="protocol" :key="index" />
              </template>
            </div>

            <div class="d-flex justify-content-around mt-5">
              <template v-for="(protocol, index) in [...protocols].splice(2, 3)">
                <WarningIcon v-bind="protocol" :key="index" />
              </template>
            </div>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>

<script>
import WarningIcon from "@/components/sections/warning/WarningIcon"

export default {
  components: { WarningIcon },
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      protocols: [
        {
          icon: "wear-mask.svg",
          text: "Memakai<br>Masker"
        },
        {
          icon: "wash-hand.svg",
          text: "Mencuci<br>Tangan"
        },
        {
          icon: "hand-shake.svg",
          text: "Hindari<br>Berjabat Tangan"
        },
        {
          icon: "hand-sanitizer.svg",
          text: "Gunakan<br>Hand Sanitizer"
        },
        {
          icon: "crowded.svg",
          text: "Hindari<br>Kerumunan"
        },
      ]
    }
  }
}
</script>