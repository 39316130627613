<template>
  <div
    class="one-image-card image-wrapper"
    :class="{
      'flipped': $attrs.value
    }"
  >
    <div class="front">
      <image-element
        :img="front"
        @click.native="imageClicked(front)"
      />
    </div>

    <div class="back">
      <image-element
        :img="back"
        @click.native="imageClicked(back)"
      />
    </div>
  </div>
</template>

<script>
import ImageElement from '@/components/sections/gallery/ImageElement'

export default {
  components: {
    ImageElement  
  },

  props: {
    front: {
      type: String,
      default: ''
    },

    back: {
      type: String,
      default: ''
    },
  },

  methods: {
    imageClicked(src) {
      this.$emit('clicked', src)
    }
  }
}
</script>

<style scoped>
  .image-wrapper {
    width: 19.5625rem;
    height: 28.125rem;
    transform-style: preserve-3d;
    position: relative;
  }

  .image-wrapper:not(.flipped) {
    animation-name: reverseFlipImage;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .flipped {
    animation-name: flipImage;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .front, .back {
    width: 100%;
    height: inherit;
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    transform: rotateY(180deg);
  }
</style>