<template>
  <p
    class="text-center" 
    :style="{
      fontSize: isDesktop ? fontSize : m_fontSize,
      fontFamily: fontFamily,
      color: color
    }"
  >
    {{ text }}
  </p>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      fontSize: "2.5rem",
      fontFamily: "poppinsR",
      color: "#161616",

      ...this.$baseComponents.title
    }
  }
}
</script>