import RepositoryBase from "../repository"

export default class settingRepository extends RepositoryBase {
  constructor() {
    super("settings")
  }

  getWeddingData() {
    return new Promise((resolve, reject) => {
      this.ref
        .doc("weddingData")
        .get()
        .then(data => {
          resolve({
            success: true,
            data: data.data()
          })
        })
        .catch(error => {
          reject({
            success: false,
            message: error.message
          })
        })
    })
  }

  getOptionalSections() {
    return new Promise((resolve, reject) => {
      this.ref
        .doc("optionalSections")
        .get()
        .then(data => {
          resolve({
            success: true,
            data: data.data()
          })
        })
        .catch(error => {
          reject({
            success: false,
            message: error.message
          })
        })
    })
  }
}