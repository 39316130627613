import RepositoryBase from "../repository"

export default class wishRepository extends RepositoryBase {
  constructor() {
    super("wishes")
  }

  getWishes() {
    return new Promise((resolve, reject) => {
      this.ref
        .orderBy("createdAt", "desc")
        .get()
        .then((wishes) => {
            resolve({
              success: true,
              data: wishes
            })
          })
        .catch((error) => {
          reject({
            success: false,
            message: error
          })
        });
    })
  }
}