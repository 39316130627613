<template>
  <div
    id="video-section"
    class="d-flex justify-content-center align-items-center mt-20"
  >
    <template v-if="$weddingData.videoType == 'youtube'">
      <iframe
        :src="$weddingData.videoLink"
        v-bind="youtubePlayerConfig"
      ></iframe>
    </template>

    <template v-else-if="$weddingData.videoType == 'video'">
      <video
        id="video_player"
        v-bind="videoPlayerConfig"
        :poster="$weddingData.videoPoster"
      >
        <source
          :src="$weddingData.videoLink"
          type="video/mp4"
        />
        Your browser does not support HTML video.
      </video>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      youtubePlayerConfig: {
        width: "560",
        height: "315",
        allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        title: "Youtube Video Player",
        frameborder: "0",
        allowfullscreen: true
      },

      videoPlayerConfig: {
        width: "560",
        height: "315",
        controls: true
      },
    };
  },
};
</script>

<style scoped>
  #video_player {
    object-fit: cover;
  }
</style>