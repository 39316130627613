import RepositoryBase from "../repository"

export default class guestRepository extends RepositoryBase {
  constructor() {
    super("guests")
  }

  getGuestByUsername(username) {
    return new Promise((resolve, reject) => {
      this.ref
      .doc(username)
      .get()
      .then((guest) => {
          if(guest.exists) {
            resolve({
              success: true,
              data: guest
            })
          } else {
            resolve({
              success: false,
              message: "Guest doesn't exist."
            })
          }
      })
      .catch((error) => {
        reject({
          success: false,
          message: error.message
        })
      })
    })
  }
}