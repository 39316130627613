<template>
  <div id="cover-decoration" :style="styles">
    <div name="top-left" v-if="decor.topLeft">
      <img :src="`${iconDir}/top-left.png`" class="decoration" alt="top-left-decoration" data-aos="fade-right">
    </div>
    <div name="top-right" v-if="decor.topRight">
      <img :src="`${iconDir}/top-right.png`" class="decoration" alt="top-right-decoration" data-aos="fade-left">
    </div>

    <div name="bottom-left" v-if="decor.bottomLeft">
      <img :src="`${iconDir}/bottom-left.png`" class="decoration" alt="bottom-left-decoration" data-aos-offset="-500" data-aos="fade-right">
    </div>
    <div name="bottom-right" v-if="decor.bottomRight">
      <img :src="`${iconDir}/bottom-right.png`" class="decoration" alt="bottom-right-decoration" data-aos-offset="-500" data-aos="fade-left">
    </div>

    <template v-if="isMobile">
      <div name="mobile-top-center">
        <img 
          :src="`${iconDir}/top-center.png`" 
          class="mobile-decoration pointer-events-none" 
          alt="top-center-decoration" 
          data-aos="fade-down"
        >
      </div>

      <img 
        :src="`${iconDir}/bottom-center.png`" 
        class="mobile-bottom-decoration pointer-events-none" 
        alt="bottom-center-decoration" 
        data-aos="fade-up"
        data-aos-offset="-10"
      >
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      let decors = this.$decorations.cover

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/cover/${this.device}`
    },
    styles() {
      const css = this.$decorations.cover

      const desktop = {
        '--top-left-top': css.topLeft_top,
        '--top-left-left': css.topLeft_left,
        '--top-left-width': css.topLeft_width,

        '--top-right-top': css.topRight_top,
        '--top-right-right': css.topRight_right,
        '--top-right-width': css.topRight_width,
        
        '--bottom-left-bottom': css.bottomLeft_bottom,
        '--bottom-left-left': css.bottomLeft_left,
        '--bottom-left-width': css.bottomLeft_width,

        '--bottom-right-bottom': css.bottomRight_bottom,
        '--bottom-right-right': css.bottomRight_right,
        '--bottom-right-width': css.bottomRight_width,
      }

      const mobile = {
        '--m-top-left-top': css.m_topLeft_top,
        '--m-top-left-left': css.m_topLeft_left,
        '--m-top-left-width': css.m_topLeft_width,

        '--m-top-right-top': css.m_topRight_top,
        '--m-top-right-right': css.m_topRight_right,
        '--m-top-right-width': css.m_topRight_width,
        
        '--m-bottom-left-bottom': css.m_bottomLeft_bottom,
        '--m-bottom-left-left': css.m_bottomLeft_left,
        '--m-bottom-left-width': css.m_bottomLeft_width,

        '--m-bottom-right-bottom': css.m_bottomRight_bottom,
        '--m-bottom-right-right': css.m_bottomRight_right,
        '--m-bottom-right-width': css.m_bottomRight_width,
      }

      return { ...mobile, ...desktop }
    }
  }
}
</script>

<style scoped>
  [name=top-left] img {
    top: var(--top-left-top);
    left: var(--top-left-left);
    width: var(--top-left-width);
  }

  [name=top-right] img {
    top: var(--top-right-top);
    right: var(--top-right-right);
    width: var(--top-right-width);
  }

  [name=bottom-left] img {
    bottom: var(--bottom-left-bottom);
    left: var(--bottom-left-left);
    width: var(--bottom-left-width);
  }

  [name=bottom-right] img {
    bottom: var(--bottom-right-bottom);
    right: var(--bottom-right-right);
    width: var(--bottom-right-width);
  }
</style>

<style mobile scoped>
  .mobile [name=top-left] img {
    top: var(--m-top-left-top);
    left: var(--m-top-left-left);
    width: var(--m-top-left-width);
  }

  .mobile [name=top-right] img {
    top: var(--m-top-right-top);
    right: var(--m-top-right-right);
    width: var(--m-top-right-width);
  }

  .mobile [name=bottom-left] img {
    bottom: var(--m-bottom-left-bottom);
    left: var(--m-bottom-left-left);
    width: var(--m-bottom-left-width);
  }

  .mobile [name=bottom-right] img {
    bottom: var(--m-bottom-right-bottom);
    right: var(--m-bottom-right-right);
    width: var(--m-bottom-right-width);
  }

  .mobile [name=mobile-top-center] img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .mobile .mobile-bottom-decoration {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>