import { website_url } from '@/config/website-config'

const generateLocalSrc = img_src => {
  return `${website_url}${img_src}`
}

export default [
  generateLocalSrc('/story/mobile-story/mobile-story-1.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-2.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-3.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-4.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-5.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-6.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-7.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-8.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-9.png'),
  generateLocalSrc('/story/mobile-story/mobile-story-10.png'),
  generateLocalSrc('/images/bride-mobile.png'),
  generateLocalSrc('/images/groom-mobile.png'),
  generateLocalSrc('/icons/customVirandaAkbar/disk-plate.png'),
  generateLocalSrc('/icons/customVirandaAkbar/disk-arm-arm.png'),
  generateLocalSrc('/icons/customVirandaAkbar/date.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/love-balloons.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/arrow-left.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/calendar.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/location-image.png'),
  generateLocalSrc('/icons/customVirandaAkbar/location-now.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/notes.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/filter-ig-icon.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding gift.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/payment-1.png'),
  generateLocalSrc('/icons/customVirandaAkbar/payment-2.svg'),
  generateLocalSrc('/momento_logo.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-couple-active.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-couple.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-day.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-day-active.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-arch.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/wedding-arch-active.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/picture.svg'),
  generateLocalSrc('/icons/customVirandaAkbar/picture-active.svg'),
]