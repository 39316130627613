<template>
  <div 
    id="storyline-container"
  >
    <Carousel class="story-carousel" v-bind="settings">
      <div 
        class="story-partial"
        v-for="index in 10"
        :key="index"
      >
        <img 
          v-if="isMobile"
          class="story-image" 
          :src="`/story/mobile-story/mobile-story-${index}.png`" 
          alt="story-image"
        >

        <img 
          v-else
          class="story-image" 
          :src="`/story/story_${index}.png`" 
          alt="story-image"
        >
      </div>
    </Carousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel"
import "vue-slick-carousel/dist/vue-slick-carousel.css"
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css"

export default {
  components: {
    Carousel: VueSlickCarousel
  },

  computed: {
    settings() {
      return {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        touchThreshold: 1000
      }
    }
  }
}
</script>

<style>
  .story-carousel .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #fff;
    font-size: 1rem;
    transition: 0.25s;
  }

  .story-carousel .slick-dots li button:before {
    color: rgb(182, 181, 181);
    font-size: 0.75rem;
    transition: 0.25s;
    height: 50px;
    display: flex;
    align-items: flex-end;
  }

  .story-carousel .slick-dots li {
    margin: 0;
  }
</style>

<style mobile>
  .mobile .slick-dots {
    position: static;
    margin-top: -1rem;
  }
</style>

<style scoped>
  #storyline-container {
    min-height: 28rem;
  }

  .story-carousel {
    width: 20rem;
    height: 20rem;
    margin: auto;
  }

  .story-carousel .story-image {
    width: 20rem;
    height: 20rem;
  }
</style>

<style mobile scoped>
  .mobile .story-carousel .story-image {
    width: 100%;
    height: unset;
  }

  .mobile .story-carousel {
    width: 100vw;
  }
</style>