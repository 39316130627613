<template>
  <div
    class="notes-container"
    :class="{
      'px-5': isDesktop
    }"
    :style="{ 
      background: backgroundColor,
      paddingTop: isDesktop ? '1.375rem' : '',
      paddingBottom: isDesktop ? '1.375rem' : ''
    }"
  >
    <BorderedText :borderColor="borderColor" wide>
      <p v-if="text" class="py-2" :style="{ color: fColor, fontFamily: fFamily, fontSize: fSize}" v-html="text"></p>

      <template v-else>
        <img 
          :src="`/icons/${baseIconDir}/notes.svg`"
          alt="notes-icon" 
          class="notes-icon"
        >

        <p
          v-if="isDesktop" 
          class="notes-text"
        >
          Para tamu diharapkan untuk <span class="notes-text-bold">tidak memakai</span> busana bernuansa  <span class="notes-text-bold">gelap.</span> <br>seperti hitam, biru dongker atau sejenisnya.
        </p>

        <p
          v-else-if="isMobile" 
          class="notes-text"
        >
          Tanpa mengurangi rasa hormat, diharapkan para tamu menggunakan <span class="notes-text-bold">busana bernuansa terang.</span><br>Terimakasih
        </p>
      </template>
    </BorderedText>
  </div>
</template>

<script>
export default {
  props: ["text", "bgColor", "fontColor", "fontFamily", "fontSize", "borderColor"],

  data() {
    return {
      backgroundColor: this.bgColor || "#707070",
      fColor: this.fontColor || "#fff",
      fFamily: this.fontFamily || "poppinsMI",
      fSize: this.fontSize || "1rem"
    }
  },

  computed: {
    baseIconDir() {
      return this.$baseComponents.icon.dir
    }
  }
}
</script>

<style scoped>
  .notes-icon {
    width: 3.125rem;
  }

  p.notes-text {
    color: #102135;
    text-align: center;
    font-family: poppinsI;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 1.5rem;
  }

  .notes-text-bold {
    font-family: poppinsBI;
  }
</style>

<style mobile scoped>
  .mobile .notes-container {
    padding: 1rem 1.19rem;
  }

  .mobile .notes-icon {
    width: 2.5rem;
  }

  .mobile p.notes-text {
    margin-top: 0.75rem;
    font-size: 0.75rem;
  }
</style>