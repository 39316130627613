const baseComponents = {
  background: {
    withImage: false,
    bgColor: "#1C192C"
  },
  icon: {
    dir: "outline-midnight"
  },
  title: {
    fontSize: "1.875rem",
    fontFamily: "andinademo",
    color: "#FFFFFF",

    m_fontSize: "1.25rem"
  },
  button: {
    // start::desktop
    bgColor: "linear-gradient(90deg, #D4AF37 1.25%, #CF953C 26.09%, #E6CB62 53.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    fontFamily: "poppinsM",
    fontSize: "1rem",
    fontColor: "#1C192C",
    minWidth: "15.625rem",
    // start::desktop

    // start::mobile
    m_fontSize: "0.75rem"
    // end::mobile
  },
  borderedText: {
    fontFamily: "opensansR",
    fontColor: "#FFFFFF",
    fontColorChatName: "-webkit-linear-gradient(90deg, #D4AF37 1.25%, #CF953C 26.09%, #E6CB62 53.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    fontSizeChat: "0.875rem",
    fontSizeCard: "1rem",
    fontFamilyB: "opensansB",
    fontSizeB: "1rem",
    borderColor: "#D4AF37",
    borderWidth: "0.125rem",
    iconWidth: "50",

    m_fontSizeCard: "0.75rem",
    m_fontSizeB: "0.75rem",
    m_iconWidth: "30"
  },
  colorWrapper: {
    bgColor: "transparent",
    border: "1px solid #D4AF37"
  },
  input: {
    // start::desktop
    placeholderColor: "#B1B1B1",
    borderColor: "#CCCCCC",
    focusBorderColor: "#D4AF37",
    fontFamilyLabel: "poppinsSB",
    labelColor: "#FFFFFF",
    fontSizeLabel: "1rem",
    fontFamilyInput: "opensansR",
    fontSizeInput: "0.875rem",
    fontColorInput: "#1C192C",
    disabledFontColorInput: "#d2d2d2",
    // end::desktop

    // start::mobile
    m_fontSizeLabel: "0.75rem",
    m_fontSizeInput: "0.75rem"
    // end::mobile
  },
  textArea: {
    // start::desktop
    countFontFamily: "opensansR",
    countFontSize: "0.875rem",
    countFontColor: "#FFFFFF",
    labelColor: "#FFFFFF",
    focusBorderColor: "#D4AF37",
    fontColorInput: "#1C192C",
    // end::desktop

    // start::mobile
    m_countFontSize: "0.75rem",
    // end::mobile
  },
  radio: {
    // start::desktop
    fontSizeLabel: "0.875rem",
    fontFamilyLabel: "opensansR",
    fontFamilyBoldLabel: "opensansB",
    radioSize: "1.25rem",
    radioColor: "#707070",
    radioColorActive: "-webkit-linear-gradient(90deg, #D4AF37 1.25%, #CF953C 26.09%, #E6CB62 53.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    radioBorderColorActive: "#D4AF37",
    labelFontFamily: "poppinsSB",
    labelFontSize: "1rem",
    labelFontColor: "#FFFFFF",
    optionLabelColor: "#FFFFFF",
    optionLabelColorActive: "-webkit-linear-gradient(90deg, #D4AF37 1.25%, #CF953C 26.09%, #E6CB62 53.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    // end::desktop

    // start::mobile
    m_radioSize: "1rem",
    m_fontSizeLabel: "0.75rem",
    m_labelFontSize: "0.75rem",
    // end::mobile
  },
  notes: {
    bgColor: "#707070",
    fontColor: "#fff",
    fontFamily: "poppinsMI",
    fontSize: "1rem"
  },
  countDown: {
    // start::desktop
    bgColor: "#FFEDAB",
    labelFontColor: "#1C192C",
    labelFontFamily: "openSansR",
    labelFontSize: "1.125rem",
    numberFontColor: "#1C192C",
    numberFontFamily: "antsvalley",
    numberFontSize: "5rem",
    borderColor: "#1C192C",
    borderWidth: "0.125rem",
    // end::desktop

    // start::mobile
    m_labelFontSize: "0.75rem",
    m_numberFontSize: "2.5rem",
    m_boteCardMinHeight: "6.625rem"
    // end::mobile
  },
  musicButton: {
    // start::desktop
    backgroundColor: "#FFEDAB",
    buttonWidth: "4rem",
    iconWidth: "38",
    // end::desktop
    
    // start::mobile
    m_buttonWidth: "2.5rem",
    m_iconWidth: "24",
    // end::mobile
  },
  floatingMenu: {
    backgroundColor: "#1C192C",
    borderColor: "#1C192C"
  },
  loading: {
    background: "#1C192C"
  }
}

const sections = {
  cover: {
    //start::desktop
    brGrFontFamily: "andinademo",
    brGrFontSize: "2.375rem",
    brGrFontColor: "#FFFFFF",
    
    andFontFamily: "andinademo",
    andFontSize: "1.875rem",
    andFontColor: "-webkit-linear-gradient(90deg, #D4AF37 1.25%, #CF953C 26.09%, #E6CB62 53.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    andMarginTop: "-0.3rem",
    andMarginBottom: "-2.3rem",
    andMarginLeft: "0",
    andMarginRight: "0",

    dearFontFamily: "poppinsR",
    dearFontSize: "1.25rem",
    dearColor: "#FFFFFF",

    groomMarginRight: "1.1rem",
    // end::desktop
    
    // start::mobile
    m_brGrFontSize: "1.5rem",

    m_andFontSize: "1rem",
    m_andMarginTop: "0rem",
    m_andMarginBottom: "-1rem",
    m_andMarginLeft: "0",
    m_andMarginRight: "0",

    m_dearFontSize: "0.875rem",
    m_dearFontColor: "#FFFFFF",

    m_bridePaddingLeft: "1rem",
    m_bridePaddingRight: "0",
    
    m_groomPaddingRight: "2rem",
    m_groomPaddingLeft: "1.5rem"
    // end::mobile
  },
  opening: {
    // start::desktop
    qContentFontFamily: "opensansR",
    qContentFontSize: "1rem",
    qContentColor: "#FFFFFF",
    qDescFontFamily: "opensansB",
    qDescFontSize: "1rem",
    qDescColor: "-webkit-linear-gradient(90deg, #D4AF37 1.25%, #CF953C 43.09%, #E6CB62 87.72%, #D4AF37 75.81%, #CF953C 98.57%)",
    
    avatarFrameWidth: "275",
    avatarNameFontSize: "1.25rem",
    avatarNameFontFamily: "poppinsSB",
    avatarDescFontSize: "0.875rem",
    avatarDescFontFamily: "poppinsR",
    avatarParentsFontFamily: "opensansR",
    avatarParentsFontSize: "1rem",
    avatarNameColor: "#FFEDAB",
    avatarDescColor: "#FFFFFF",
    avatarMarginBottom: "0.625rem",
    parentWithAnd: true,
    // end::desktop

    // start::mobile
    m_qContentFontSize: "0.75rem",
    m_qDescFontSize: "0.75rem",
    
    m_avatarFrameWidth: "150",
    m_avatarNameFontSize: "0.875rem",
    m_avatarDescFontSize: "0.625rem",
    m_avatarParentsFontSize: "0.75rem",
    m_avatarParentsFontFamily: "opensansR",
    m_avatarMarginBottom: "0",
    m_avatarDescFontFamily: "opensansI",
    m_parentWithAnd: true,
    m_addBrOnSpace: 2,
    // end::mobile
  },
  time: {
    // start::desktop
    scheduleTitleFF: "opensansB",
    scheduleTitleFS: "1.25rem",
    scheduleTitleC: "#FFFFFF",
    scheduleDescFF: "opensansR",
    scheduleDescFS: "1rem",
    scheduleDescC: "#FFFFFF",

    dateTimeIconSize: "50",
    dateCardType: 1,
    timeCardType: 1,
    // end::desktop

    // start::mobile
    m_scheduleTitleFS: "0.75rem",
    m_scheduleDescFS: "0.75rem",
    
    m_dateTimeIconSize: "30"
    // end::mobile
  },
  location: {
    // start::desktop
    titleFontFamily: "opensansB",
    titleFontSize: "1.25rem",
    titleColor: "#FFFFFF",
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#FFFFFF",
    iconWidth: "50",
    noBorder: true,
    headerBorderWidth: "1px 1px 0 1px",
    headerBorderStyle: "solid",
    headerBorderColor: "#D4AF37",
    headerBorderRadius: "1.25rem 1.25rem 0 0",
    // end::desktop

    // start::mobile
    m_titleFontSize: "0.75rem",
    m_descFontSize: "0.75rem",
    m_iconWidth: "30",
    // end::mobile
  },
  rsvp: {
    // start::desktop
    descFontFamily: "opensansR",
    descFontSize: "1rem",
    descColor: "#FFFFFF",
    // end:desktop

    // start::mobile
    m_descFontSize: "0.75rem",
    // end::mobile
  },
  story: {
    lineColor: "#161616",
    iconBgColor: "#161616",
    iconBorderColor: "#161616",
    cardBorderColor: "#161616",
  },
  gallery: {
    galleryInitialWidth: "133px",
    m_galleryInitialWidth: "104px"
  },
  wish: {
    noDataFontColor: "#FFF",

    // start::mobile
    m_chatBorderBottom: "1px solid #D4AF37"
    // end::mobile
  },
  streaming: {
    streamingTextFontFamily: "opensansR",
    streamingTextFontSize: "1rem",
    streamingTextFontColor: "#161616",
  },
  gift: {
    codeFontFamily: "poppinsSB",
    codeFontSize: "1.25rem",
    codeFontColor: "#161616",
    detailFontFamily: "poppinsR",
    detailFontSize: "0.875rem",
    detailFontColor: "#161616",

    giftDetailFontFamily: "poppinsR",
    giftDetailFontSize: "1rem",
    giftDetailFontColor: "#161616",
  },
  warning: {
    // start::desktop
    warnIconFontFamily: "opensansSB",
    warnIconFontSize: "1rem",
    warnIconFontColor: "#FFFFFF",
    warnIconWidth: "150",
    // end::desktop

    // start::mobile
    m_warnIconWidth: "90",
    m_warnIconFontSize: "0.75rem"
    // end::mobile
  },
  footer: {
    whiteLogo: true,
    // start::desktop
    footerIconWidth: "200",
    // end::desktop
    
    // start::mobile
    m_footerIconWidth: "125"
    // end::mobile
  }
}

const decorations = {
  cover: {
    // start::desktop
    topLeft_top: "1.875rem",
    topLeft_left: "1.875rem",
    topLeft_width: "66.5%",
    
    topRight_top: "0",
    topRight_right: "0",
    topRight_width: "30.5%",
    
    bottomLeft_bottom: "0",
    bottomLeft_left: "0",
    bottomLeft_width: "36.2%",
    
    bottomRight_bottom: "1.875rem",
    bottomRight_right: "1.875rem",
    bottomRight_width: "59.5%",
    // start::desktop

    // start::mobile
    m_topLeft_top: "1.031rem",
    m_topLeft_left: "1.344rem",
    m_topLeft_width: "49%",
    
    m_topRight_top: "0",
    m_topRight_right: "0",
    m_topRight_width: "56.5%",
    
    m_bottomLeft_bottom: "0",
    m_bottomLeft_left: "0",
    m_bottomLeft_width: "62.8%",
    
    m_bottomRight_bottom: "0.938rem",
    m_bottomRight_right: "1.063rem",
    m_bottomRight_width: "30.3%",
    // start::mobile
  },
  opening: {
    // start::desktop
    bottomLeft_bottom: "-2.8rem",
    bottomLeft_left: "0",
    bottomLeft_width: "22%",
    
    bottomRight_bottom: "-2.8rem",
    bottomRight_right: "0",
    bottomRight_width: "22%",
    // start::desktop
  },
  time: {
    // start::desktop
    bottomLeft_bottom: "16rem",
    bottomLeft_left: "0",
    bottomLeft_width: "42.8%",
    
    bottomRight_bottom: "16rem",
    bottomRight_right: "0",
    bottomRight_width: "42.8%",
    // start::desktop

    // start::mobile
    m_bottomLeft_bottom: "11.5rem",
    m_bottomLeft_left: "0",
    m_bottomLeft_width: "47%",
    
    m_bottomRight_bottom: "11.5rem",
    m_bottomRight_right: "0",
    m_bottomRight_width: "47%",
    // start::mobile
  },
  location: {
    // start::desktop
    topLeft_top: "-3.125rem",
    topLeft_left: "0",
    topLeft_width: "6.5%",
    
    topRight_top: "-3.125rem",
    topRight_right: "0",
    topRight_width: "6.5%",
    // start::desktop
  },
  rsvp: {
    // start::desktop
    topLeft_top: "-1.3rem",
    topLeft_left: "0",
    topLeft_width: "22.23%",
    
    topRight_top: "-1.3rem",
    topRight_right: "0",
    topRight_width: "22.23%",
    // start::desktop

    // start::mobile
    m_topLeft_top: "-0.5rem",
    m_topLeft_left: "0",
    m_topLeft_width: "29.45%",
    
    m_topRight_top: "-0.5rem",
    m_topRight_right: "0",
    m_topRight_width: "29.45%",
    // start::mobile
  },
  wish: {
    // start::desktop
    topRight_top: "-1.3rem",
    topRight_right: "0",
    topRight_width: "12.7%",
    // start::desktop

    // start::mobile
    m_topLeft_top: "-1rem",
    m_topLeft_left: "0",
    m_topLeft_width: "24%",
    
    m_topRight_top: "-1rem",
    m_topRight_right: "0",
    m_topRight_width: "24%",
    // start::mobile
  },
  footer : {
    // start::desktop
    bottomLeft_bottom: "0",
    bottomLeft_left: "0",
    bottomLeft_width: "27.5%",
    
    bottomRight_bottom: "0",
    bottomRight_right: "0",
    bottomRight_width: "27.5%",
    // start::desktop

    // start::mobile
    m_bottomLeft_bottom: "0",
    m_bottomLeft_left: "0",
    m_bottomLeft_width: "45%",
    
    m_bottomRight_bottom: "0",
    m_bottomRight_right: "0",
    m_bottomRight_width: "45%",
    // start::mobile
  }
}

export { baseComponents, sections, decorations }