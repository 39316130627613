<template>
  <div
    id="wish-section"
    :style="styles"
    :class="{
      'd-flex align-items-center justify-content-center': isDesktop,
      'mt-30 px-5': isMobile
    }"
    class="position-relative"
  >
    <div name="content" class="w-100">
      <section name="title">
        <Title :text="wishTitleText" />
      </section>

      <section
        name="wish"
        :class="{
          'd-flex justify-content-center': isDesktop
        }"
      >
        <div
          id="wishes"
          name="wishes"
          :class="{
            'w-100': isDesktop,
            'maxh-rem-31': isMobile,
            'overflowed': is_chat_container_overflowed
          }"
        >
          <template v-if="wishes.length">
            <template v-for="(wish, index) in wishes">
              <BorderedText boteStyle="chat" v-bind="wish" :key="index"/>
            </template>
          </template>
          <template v-else>
            <p class="no-data text-center">
              Belum ada ucapan.
            </p>
          </template>
        </div>

        <div
          name="form-input"
          :class="{
            'w-100': isDesktop
          }"
        >
          <div class="wish-form-wrapper">
            <Input
              :class="{
                'mb-6': isDesktop,
              }"
              label="Nama"
              placeholder="Tulis Namamu..."
              v-model="form.name"
            />

            <TextArea
              :class="{
                'mb-6': isDesktop,
                'mb-3-752': isMobile
              }"
              label="Pesan"
              placeholder="Tulis Pesanmu..."
              limit="500"
              rows="12"
              v-model="form.message"
            />

            <div
              class="d-flex justify-content-center"
            >
              <Button
                minWidth="100%"
                paddingX="0"
                :text="sendWishText" 
                @click.native="$buttonClick(submit)"
                :loading="loading_submit_wish"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

    <div 
      v-if="isMobile"
      name="decorations"
    >
      <img 
        src="/decorations/customVirandaAkbar/wish/mobile/background-decoration.png" 
        alt="bg-wish-decoration" 
        class="bg-wish-decoration"
      >
    </div>

    <!-- <div name="decorations">
      <WishDecoration />
    </div> -->
  </div>
</template>

<script>
import WishDecoration from "@/components/sections/wish/WishDecoration"

export default {
  components: { WishDecoration },

  props: ["wishes"],

  data() {
    return {
      form: {
        name: "",
        message: "",
        username: null
      },
      css: {
        noDataFontColor: "rgb(87, 102, 58)",
        ...this.$sections.wish
      },

      is_chat_container_overflowed: false,
      loading_submit_wish: false
    }
  },

  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--no-data-font-color': css.noDataFontColor
      }
      
      const mobile = {
        '--m-chat-border-bottom': css.m_chatBorderBottom
      }

      return { ...desktop, ...mobile }
    },
    sendWishText() {
      return this.isDesktop ? "Kirim Ucapan" : "Kirim"
    },
    wishTitleText() {
      return "Ucapan dan Doa"
    }
  },
  
  watch: {
    wishes() {
      setTimeout(() => this.setChatContainerOverflowed(), 200)
    }
  },

  methods: {
    async submit() {
      this.loading_submit_wish = true

      const validated = this.validate()

      if(validated) {
        let res = await this.$wishService.submitWish(this.form)
  
        if(res.success) {
          this.$alertSuccess("Terimakasih atas doa dan ucapannya :)")
  
          this.resetForm()

          this.$emit("update")
        } else {
          this.$alertFail(res.message)
        }
      } else {
        this.$alertFail("Harap isi nama dan ucapan kamu")
      }

      this.loading_submit_wish = false
    },
    validate() {
      const excludes = ["username"]

      for(const key in this.form) {
        if(!excludes.includes(key))
        if(!this.form[key]) return false
      }

      return true
    },
    resetForm() {
      this.form = {
        name: "",
        message: "",
        username: null
      }
    },

    setChatContainerOverflowed() {
      const element = document.getElementById('wishes')

      this.is_chat_container_overflowed = element.scrollHeight > element.clientHeight
    }
  },

  mounted() {
    this.setChatContainerOverflowed()
  }
}
</script>

<style mobile>
  .mobile #wishes .bote-container .bote-chat {
    margin: 0;
    padding: 0.75rem 0.75rem;
    margin-left: 1rem;
    margin-right: 1rem;
    border-bottom: 0.0625rem solid #102135;
    border-style: dashed;
  }
</style>

<style scoped>
  #wish-section {
    margin-top: 4.31rem;
  }

  section[name=wish] {
    margin-top: 1.5rem;
  }

  [name=content] {
   max-width: 75rem; 
  }

  p.desc {
    font-family: var(--desc-font-family);
    font-size: var(--desc-font-size);
    color: var(--desc-color);
  }

  [name=wishes] {
    overflow-y: auto;
    padding: 1rem;
  }

  [name=wishes].overflowed {
    padding-right: 0;
  }

  [name=wishes]::-webkit-scrollbar {
    width:16px;
  }

  [name=wishes]::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1rem;
    background-color: #102135;
  }

  [name=wishes]:hover::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 1rem;
    background-color: #102135;
  }

  [name=wishes]::-webkit-scrollbar-track {
    border-radius: 50px;
  }

  #wishes:has(p.no-data) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  p.no-data {
    color: #102135;
  }

  div[name=form-input] {
    margin-left: 2.69rem;
    max-width: 32.5rem;
  }

  div[name=wishes] {
    border-radius: 1rem;
    background: linear-gradient(106deg, #FBFBFD 7.73%, #C8D4DA 24.76%, #E3E9EE 45.33%, #D6DFE6 64.61%, #FAFBFC 80.12%, #B8C9D3 98.11%);
    max-height: 34.375rem;
    min-height: 34.375rem;
  }
</style>

<style mobile scoped>
  .mobile div[name=form-input] {
    border-top: var(--m-chat-border-bottom);
  }

  .mobile #wish-section {
    margin-top: 3.83rem !important;
    position: relative;
  }

  .mobile section[name=title] p {
    font-size: 2rem !important;
  }

  .mobile section[name=wish] {
    margin-top: 1.31rem;
  }

  .mobile #wishes {
    border-radius: 0.625rem;
    padding: 0.62rem;
  }

  .mobile div[name=form-input] {
    margin-left: 0.62rem;
    margin-right: 0.62rem;
    max-width: unset;
    margin-top: 1.5rem;
  }

  .mobile .wish-form-wrapper .input-container {
    margin-bottom: 1rem;
  }

  .mobile .bg-wish-decoration {
    position: absolute;
    top: 7rem;
    z-index: -5;
    left: 0;
    right: 0;
  }

  .mobile #wishes:has(p.no-data) {
    min-height: 10rem;
  }

  .mobile div[name=wishes] {
    min-height: unset !important;
  }
</style>