<template>
  <BaseBorderedText v-bind="[$baseComponents.borderedText, $attrs]">
    <slot></slot>
  </BaseBorderedText>
</template>

<script>
import BaseBorderedText from "@/components/bases/BaseBorderedText"

export default {
  components: { BaseBorderedText }
}
</script>