<template>
  <div
    class="button-container d-flex justify-content-center align-items-center w-100" 
    :style="{
        background: bgColor, 
        minWidth: isDesktop ? minWidth : null, 
        maxWidth: minWidth,
        '--button-container-bg-color': bgColor,
        paddingLeft: paddingX,
        paddingRight: paddingX,
      }"
    >
    <template v-if="icon">
      <img class="button-icon" :src="`/icons/${iconDir}/${icon}`">
    </template>

    <p class="button-text" :style="{fontFamily, fontSize, color: fontColor}">{{ text }}</p>

    <div v-if="loading" class="loading-spinner"></div>
  </div>
</template>

<script>
export default {
  props: ["icon", "text", "bgColor", "fontColor", "fontFamily", "fontSize", "minWidth", "paddingX", "loading"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir
    }
  }
}
</script>

<style scoped>
  .button-container {
    max-width: 15.625rem;
    max-height: 1.875rem;
    padding: 0.938rem 2.5rem;
    border-radius: 1.875rem;
    background: linear-gradient(106.46deg, #FBFBFD 7.73%, #C8D4DA 24.76%, #E3E9EE 45.33%, #D6DFE6 64.61%, #FAFBFC 80.12%, #B8C9D3 98.11%);
    transition: 0.5s;
    position: relative;
  }

  .button-container:hover {
    cursor: pointer;
    box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    -webkit-box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    -moz-box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    transition: 0.25s;
  }

  .button-icon {
    width: 1.875rem;
  }

  .button-text {
    font-family: var(--poppinsM);
    font-size: 1rem;
    padding-left: 0.938rem;
    color: #161616;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .loading-spinner {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
  }

  .loading-spinner:after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: rotation 1.2s linear infinite;
  }

  .button-container:has(.loading-spinner) .button-icon, .button-container:has(.loading-spinner) .button-text {
    opacity: 0.5;
  }
</style>

<style mobile scoped>
  .mobile .button-container {
    max-width: unset !important;
    max-height: 2.5rem;
    padding: 0.688rem 0;
    border-radius: 1.2rem;
  }

  .mobile .button-icon {
    width: 1.25rem;
  }

  .mobile .button-text {
    font-size: 0.75rem !important;
    padding-left: 0.625rem;
  }
</style>