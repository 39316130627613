<template>
  <div
    id="location-section"
    :class="{
      'd-flex align-items-center justify-content-center': isDesktop
    }"
    class="position-relative"
    :style="{
      ...styles,

      paddingTop: isDesktop ? '2.19rem' : ''
    }"
  >
    <div name="content" data-aos="fade-up" class="position-relative z-index-2">
      <template v-if="isDesktop">
        <ColorWrapper
          class="d-flex justify-content-center w-100 maxh-35-46 maxh-33-28 mb-16-752 overflow-hidden"
          :style="{
            paddingTop: css.wrapperPaddingTop
          }"
          :noBorder="css.noBorder"
        >
          <div class="text-center w-100">
            
            <section
              name="header" 
              :style="{
                  borderWidth: css.headerBorderWidth,
                  borderStyle: css.headerBorderStyle,
                  borderColor: css.headerBorderColor,
                  borderRadius: css.headerBorderRadius
                }"
              :class="{
                'pt-5': css.noBorder 
              }"
            >
              <section name="icon">
                <img class="location-image" :src="`/icons/${iconDir}/location-image.png`">
              </section>

              <section
                name="title"
              >
                <p class="title">{{ $weddingData.locationPlace }}</p>

                <p class="title">HALL 1</p>
                <p 
                  class="desc mt-1 m-auto"
                  :style="{
                    maxWidth: css.addressMaxWidth
                  }"
                >
                  {{ $weddingData.locationAddress }}
                </p>
              </section>
            </section>

            <section name="maps" class="d-flex">
              <iframe
                :src="$weddingData.locationMapsUrl"
                class="g-maps w-rem-75"
                style="border: 0;"
                allowfullscreen="true"
                loading="lazy"
              ></iframe>
            </section>
          </div>
        </ColorWrapper>
      </template>

      <template v-else>
        <section name="icon">
          <img class="location-image" :src="`/icons/${iconDir}/location-image.png`">
        </section>

        <section
          name="title"
        >
          <p class="title">{{ $weddingData.locationPlace }}</p>

          <p class="title">HALL 1</p>
          <p class="desc">
            Jln. Raya Merdeka, RT.002/RW.001, Cimone Jaya,<br> Karawaci, Tangerang City, Banten 15114
          </p>
        </section>

        <section name="maps">
          <iframe
            :src="$weddingData.locationMapsUrl"
            class="g-maps w-100"
            style="border: 0;"
            allowfullscreen="true"
            loading="lazy"
          ></iframe>
        </section>

        <Link :to="$weddingData.locationShare" external newtab>
          <section name="open-maps" class="d-flex justify-content-center">
            <Button icon="location-now.svg" text="Lihat Arah Lokasi" />
          </section>
        </Link>
      </template>
    </div>

    <div
      v-if="isDesktop" 
      name="decorations"
    >
      <LocationDecoration />
    </div>
  </div>
</template>

<script>
import LocationDecoration from "@/components/sections/location/LocationDecoration"

export default {
  components: { LocationDecoration },
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        titleFontFamily: "opensansB",
        titleFontSize: "1.25rem",
        titleColor: "#161616",
        descFontFamily: "opensansR",
        descFontSize: "1rem",
        descColor: "#161616",
        wrapperPaddingTop: "1.25rem",
        addressMaxWidth: "40%",

        ...this.$sections.location
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--title-font-family': css.titleFontFamily,
        '--title-font-size': css.titleFontSize,
        '--title-color': css.titleColor,
        '--desc-font-family': css.descFontFamily,
        '--desc-font-size': css.descFontSize,
        '--desc-color': css.descColor
      }

      const mobile = {
        '--m-title-font-size': css.m_titleFontSize,
        '--m-desc-font-size': css.m_descFontSize
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style>
  #location-section .wrapper-container {
    max-height: unset !important;
  }
</style>

<style scoped>
  section[name=title] p.title {
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    color: var(--title-color);
  }

  section[name=title] p.desc {
    font-family: var(--desc-font-family);
    font-size: var(--desc-font-size);
    color: var(--desc-color);
  }

  section[name=maps] iframe {
    border-radius: 1.25rem;
  }

  section[name=header] {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  section[name=header] section[name=title] {
    padding-bottom: 2.5rem;
  }

  section[name=header] section[name=title] p.title {
    margin-bottom: 0.69rem;
    font-weight: 700;
  }

  section[name=maps], section[name=maps] iframe {
    height: 31.25rem;
  }

  .location-image {
    width: 100%;
    max-width: 66.75rem;
  }
</style>

<style mobile scoped>
  .mobile section[name=title] p.title {
    font-size: var(--m-title-font-size);
  }

  .mobile section[name=title] p.desc {
    font-size: var(--m-desc-font-size);
  }

  .mobile section[name=maps] iframe {
    border-radius: 0.625rem;
  }

  .mobile section[name=open-maps] {
    margin-left: 0.62rem !important;
    margin-right: 0.62rem !important;
    margin-top: 1.5rem;
    margin-bottom: 0.8rem;
  }

  .mobile #location-section {
    padding: 0.75rem 1.25rem;
    margin-top: 1.75rem;
  }

  .mobile section[name=icon] {
    display: flex;
    margin-bottom: 1rem;
  }

  .mobile section[name=title] {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.31rem;
    margin-bottom: 1.69rem;
  }

  .mobile section[name=maps] {
    padding-left: 0.62rem;
    padding-right: 0.62rem;
  }

  .mobile section[name=maps], .mobile section[name=maps] iframe {
    height: 16.5rem;
  }
</style>