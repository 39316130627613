export default [
  {
    name: 'cinzelR',
    src: 'fonts/cinzel/Cinzel-Regular.ttf'
  },
  {
    name: 'cinzelB',
    src: 'fonts/cinzel/Cinzel-Bold.ttf'
  },
  {
    name: 'cinzelEB',
    src: 'fonts/cinzel/Cinzel-ExtraBold.ttf'
  },
  {
    name: 'cinzelBk',
    src: 'fonts/cinzel/Cinzel-Black.ttf'
  }
]