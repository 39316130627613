import { render, staticRenderFns } from "./GiftSection.vue?vue&type=template&id=06f20e2c&scoped=true&"
import script from "./GiftSection.vue?vue&type=script&lang=js&"
export * from "./GiftSection.vue?vue&type=script&lang=js&"
import style0 from "./GiftSection.vue?vue&type=style&index=0&id=06f20e2c&scoped=true&lang=css&"
import style1 from "./GiftSection.vue?vue&type=style&index=1&id=06f20e2c&mobile=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f20e2c",
  null
  
)

export default component.exports