// src/firebaseConfig.js
import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    // start::main firebase
    
    apiKey: "AIzaSyDaojTfsX7idd7NfXUyX-owohRCNIIPRmQ",
    authDomain: "momento-projects.firebaseapp.com",
    projectId: "momento-projects",
    storageBucket: "momento-projects.appspot.com",
    messagingSenderId: "347765803245",
    appId: "1:347765803245:web:36d9afa8c4157b88f9e4e4"

    // end::main firebase

    // start::second firebase

    // apiKey: "AIzaSyDTGvyCSg6E4mHjDYzQ8S2IvSxdrwGrrlQ",
    // authDomain: "momento-projects-backup.firebaseapp.com",
    // projectId: "momento-projects-backup",
    // storageBucket: "momento-projects-backup.appspot.com",
    // messagingSenderId: "132902181437",
    // appId: "1:132902181437:web:93f1bc00943570a0c8a854"

    // end::second firebase
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);