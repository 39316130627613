<template>
  <BaseCountDown v-bind="[$baseComponents.countDown, $attrs]" />
</template>

<script>
import BaseCountDown from "@/components/bases/BaseCountDown"

export default {
  components: { BaseCountDown }
}
</script>