<template>
  <div
    id="notes-section"
    class="d-flex align-items-center justify-content-center"
    :style="{
      marginTop: isDesktop ? '2.942rem' : '',
      position: 'relative',
      zIndex: '2'
    }"
  >
    <div 
      name="content" 
      class="w-100"
    >
      <Notes 
        v-if="isDesktop"
        :borderColor="css.borderColor"
      />

      <Notes 
        v-else-if="isMobile"
        id="notes-warn"
        :borderColor="css.borderColor"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      css: {
        ...this.$baseComponents.notes
      }
    }
  }
}
</script>

<style mobile>
  .mobile #notes-warn .bote-card {
    padding: 0;
  }

  .mobile #notes-section {
    z-index: 6 !important;
  }
</style>