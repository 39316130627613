var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:{
    'd-flex align-items-center justify-content-center': _vm.isDesktop,
    'px-3-752': _vm.isMobile
  },style:({
    paddingTop: _vm.isDesktop ? '15.75rem' : ''
  }),attrs:{"id":"rsvp-section"}},[_c('div',{attrs:{"name":"content","data-aos":"fade-up"}},[_c('Form',{staticClass:"form-container",class:{
        'pb-9 px-25': _vm.isDesktop,
        'mt-2 pt-5 px-7-5': _vm.isMobile,
        'pb-8': _vm.isMobile && _vm.form.attendance == null
      },style:({
        paddingBottom: _vm.isMobile && _vm.form.attendance != null ? '4rem' : ''
      }),attrs:{"noBorder":true}},[_c('section',{attrs:{"name":"title"}},[_c('Title',{attrs:{"text":"R S V P"}}),_c('p',{staticClass:"desc text-center",class:{
            'mt-2-752': _vm.isDesktop
          },style:(_vm.styles)},[(!_vm.hide_form)?[_vm._v(" Hallo! "),_c('span',{staticClass:"desc-bold"},[_vm._v(_vm._s(_vm.$guestData.name))]),_vm._v(", merupakan suatu kehormatan "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v("apabila kamu dapat hadir dalam acara"),(_vm.isDesktop)?_c('br'):_vm._e(),_vm._v(" pernikahan "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v("kami. Mohon bantuannya agar kami dapat "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v("mempersiapkan segala sesuatunya secara "),(_vm.isDesktop)?_c('br'):_vm._e(),_vm._v("maksimal "),(_vm.isMobile)?_c('br'):_vm._e(),_vm._v("dengan mengisi form berikut : ")]:[_vm._v(" Terimakasih "),_c('span',{staticClass:"desc-bold"},[_vm._v(_vm._s(_vm.$guestData.name))]),_vm._v(", telah mengisi kehadiran pada form ini. Apabila kamu ingin mengubah "),(_vm.isDesktop)?_c('br'):_vm._e(),_vm._v("info kehadiranmu, silahkan klik tombol ‘ubah’ dibawah ini ya. Terimakasih ")]],2),_c('div',{staticClass:"d-flex justify-content-center",attrs:{"name":"confirmation-button-wrapper"}},[(_vm.hide_form)?_c('Button',{attrs:{"text":"Ubah","minWidth":'25rem'},nativeOn:{"click":function($event){return _vm.$buttonClick(_vm.buttonFunc)}}}):_vm._e()],1)],1),_c('section',{attrs:{"name":"form-input"}},[(!_vm.hide_form)?_c('div',{staticClass:"form-input-content-wrapper"},[_c('div',[_c('Radio',{class:{
                'mb-7-5': _vm.isDesktop,
              },attrs:{"name":"kehadiran","label":"Kamu akan hadir ga nih?","radio_items":_vm.attendance_options},model:{value:(_vm.form.attendance),callback:function ($$v) {_vm.$set(_vm.form, "attendance", $$v)},expression:"form.attendance"}}),_c('transition',{attrs:{"name":"anim-form"}},[(_vm.form.attendance)?[_c('Radio',{class:{
                    'mb-7-5': _vm.isDesktop,
                  },attrs:{"name":"jumlah","label":"Bagaimana kamu akan hadir?","radio_items":_vm.how_options},model:{value:(_vm.form.how),callback:function ($$v) {_vm.$set(_vm.form, "how", $$v)},expression:"form.how"}})]:_vm._e()],2),_c('transition',{attrs:{"name":"anim-textarea"}},[(_vm.form.attendance == false)?_c('TextArea',{staticClass:"rsvp-text-area",class:{
                  'mb-7-5': _vm.isDesktop,
                  'opened': !_vm.form.attendance
                },attrs:{"label":"Kenapa tidak bisa hadir? :(","placeholder":"Tulis Alasanmu... (Boleh ga diisi kok hehe)","limit":"400","disabled":_vm.isDisabled},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}):_vm._e()],1)],1)]):_vm._e(),(((_vm.isMobile && _vm.showConfirmationButton) || _vm.isDesktop) && !_vm.hide_form)?_c('div',{staticClass:"d-flex justify-content-center",attrs:{"name":"confirmation-button-wrapper"}},[_c('transition',{attrs:{"name":"anim-confirm-btn"}},[(_vm.showConfirmationButton)?_c('Button',{attrs:{"text":_vm.buttonText,"minWidth":'25rem'},nativeOn:{"click":function($event){return _vm.$buttonClick(_vm.buttonFunc)}}}):_vm._e()],1)],1):_vm._e()])])],1),_c('div',{attrs:{"name":"decorations"}},[_c('RSVPDecoration',{attrs:{"hide_form":_vm.hide_form}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }