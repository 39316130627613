export default {
  theme: "default",
  
  guestName: "nama tamu",

  brideFullName: "Mystrile Algorithm",
  brideNickName: "Mystrile",
  brideFather: "Bapak Algorithm Algorithm",
  brideMother: "Ibu Mystrile Mystrile",
  brideChildOrder: 2,
  brideAvatar: "https://github.com/momentoprojectsid/storage/raw/master/default/bride.png",

  groomFullName: "Algorithm Mystrile",
  groomNickName: "Algorithm",
  groomFather: "Bapak Algorithm Algorithm",
  groomMother: "Ibu Mystrile Mystrile",
  groomChildOrder: 3,
  groomAvatar: "https://github.com/momentoprojectsid/storage/raw/master/default/groom.png",

  quotes: "“Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu isteri-isteri dari jenismu sendiri, supaya kamu cenderung dan merasa tenteram kepadanya, dan dijadikan-Nya diantaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berfikir.”",
  quotesDesc: "(Ar-Rum: 21)",

  akadDate: "Senin, 17 Januari 2022",
  akadTime: "07:00 - 08:00",

  receptionDate: "Senin, 17 Januari 2022",
  receptionTime: "11:00 - 12:00",
  reception2Time: null,
  receptionCountDown: "Jul 27, 2022 18:00:00",

  locationPlace: "Hotel ABCD",
  locationAddress: "Tangcity Superblock, Jl. Jenderal Sudirman No.1, RT.001/RW.005, Babakan, Tangerang, Tangerang City, Banten 15117",
  locationMapsUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.497399802268!2d106.88814581539638!3d-6.197915962445708!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f529ce0b4ba1%3A0x8e09aa8d6f340843!2sFieris%20Hotel!5e0!3m2!1sid!2sid!4v1641737904496!5m2!1sid!2sid",

  storyLines: [
    {
      text: `1. Being deeply loved by someone gives you strength, while loving someone deeply gives you courage. "We are most alive when we are in love." "The only thing we never get enough of is love; and the only thing we never give enough of is love." "There is only one happiness in this life, to love and be loved.".`
    },
    {
      text: `2. Being deeply loved by someone gives you strength, while loving someone deeply gives you courage. "We are most alive when we are in love." "The only thing we never get enough of is love; and the only thing we never give enough of is love." "There is only one happiness in this life, to love and be loved.".`
    },
    {
      text: `3. Being deeply loved by someone gives you strength, while loving someone deeply gives you courage. "We are most alive when we are in love." "The only thing we never get enough of is love; and the only thing we never give enough of is love." "There is only one happiness in this life, to love and be loved.".`
    },
    {
      text: `4. Being deeply loved by someone gives you strength, while loving someone deeply gives you courage. "We are most alive when we are in love." "The only thing we never get enough of is love; and the only thing we never give enough of is love." "There is only one happiness in this life, to love and be loved.".`
    },
  ],

  galleryType: "p3l2",
  galleryInitialSrc: "https://github.com/momentoprojectsid/storage/raw/master/initial.png",
  galleryPortraits: [
    "https://www.tarkettsee.com/media/img/M/THH_25121917_25131917_25126917_25136917_001.jpg",
    "https://www.tarkettsee.com/media/img/M/THH_25121917_25131917_25126917_25136917_001.jpg",
    "https://www.tarkettsee.com/media/img/M/THH_25121917_25131917_25126917_25136917_001.jpg"
  ],
  galleryLandscapes: [
    "https://www.tarkettsee.com/media/img/M/THH_25121917_25131917_25126917_25136917_001.jpg" ,
    "https://www.tarkettsee.com/media/img/M/THH_25121917_25131917_25126917_25136917_001.jpg"
  ],

  videoType: "youtube",
  videoLink: "https://www.youtube.com/embed/7jyosNCQZY0",

  streamingLink: [
    {
      text: "Link",
      link: "https://google.com"
    },
    {
      text: "Link",
      link: "https://google.com"
    },
  ],

  notes: "Para hadirin yang kami hormati dan sayangi. Pihak hotel tidak membolehkan kiriman bunga",

  wishes: [
    {
      name: "Algorithm",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Mystrile",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Algorithm",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Mystrile",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Algorithm",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Mystrile",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Algorithm",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Mystrile",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Algorithm",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    },
    {
      name: "Mystrile",
      comment: "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis le"
    }
  ],

  payments: [
    {
      icon: "https://github.com/momentoprojectsid/storage/raw/master/bca_logo.png",
      code: "12345678",
      detail: "a.n. mystrile algorithm"
    },
    {
      icon: "https://github.com/momentoprojectsid/storage/raw/master/ovo_logo.png",
      code: "085123456789",
      detail: "a.n. mystrile algorithm"
    }
  ]
}