<template>
  <div
    class="avatar-container text-center"
    :class="type"
    :style="styles"
  >
    <section
      name="image"
      :style="{
        marginBottom: isDesktop ? avatarMarginBottom : m_avatarMarginBottom
      }">
      <img
        :width="isDesktop ? avatarFrameWidth : m_avatarFrameWidth"
        :src="$weddingData[`${type}Avatar`]"
      >
    </section>

    <section name="desc" :data-aos="desc_animation">
      <p class="name mt-2" v-html="personName" :class="{ 'mb-1': isMobile }"></p>
      <p class="desc mt-2">{{ childTerm }} {{ childOrder }} dari :</p>
      <p class="desc parent mt-2">{{ father }}</p>
      <!-- <p
        v-if="isDesktop ? parentWithAnd : m_parentWithAnd"
        class="desc"
      >
        &
      </p> -->
      <p class="desc parent">{{ mother }}</p>
    </section>
  </div>
</template>

<script>
export default {
  props: ["type", "name", "order", "father", "mother"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      avatarFrameWidth: "275",
      avatarNameFontSize: "1.25rem",
      avatarNameFontFamily: "poppinsSB",
      avatarDescFontSize: "0.875rem",
      avatarDescFontFamily: "poppinsR",
      avatarNameColor: "#161616",
      avatarDescColor: "#707070",
      avatarParentsFontSize: "0.875rem",
      parentWithAnd: true,

      ...this.$sections.opening
    }
  },
  computed: {
    personName() {
      if(this.isDesktop) return this.name
      
      let name = this.name.split(" ")
      let nameWithBr = []

      name.forEach((item, index) => {
        if((index) == this.m_addBrOnSpace) nameWithBr.push("<br>")

        nameWithBr.push(item)
      })

      return nameWithBr.join(" ")
    },
    styles() {
      const desktop = {
        '--avatar-name-font-size': this.avatarNameFontSize,
        '--avatar-name-font-family': this.avatarNameFontFamily,
        '--avatar-name-color': this.avatarNameColor,
        '--avatar-desc-font-size': this.avatarDescFontSize,
        '--avatar-desc-font-family': this.avatarDescFontFamily,
        '--avatar-desc-color': this.avatarDescColor,
        '--avatar-parent-font-size': this.avatarParentsFontSize,
        '--avatar-parent-font-family': this.avatarParentsFontFamily
      }

      const mobile = {
        '--m-avatar-name-font-size': this.m_avatarNameFontSize,
        '--m-avatar-desc-font-size': this.m_avatarDescFontSize,
        '--m-avatar-parent-font-size': this.m_avatarParentsFontSize,
        '--m-avatar-parent-font-family': this.m_avatarParentsFontFamily,
        '--m-avatar-desc-font-family': this.m_avatarDescFontFamily,
      }

      return { ...desktop, ...mobile  }
    },
    childTerm() {
      switch(this.type) {
        case "bride": return "Putri"
        case "groom": return "Putra"
      }
    },
    childOrder() {
      switch(parseInt(this.order)) {
        case 1: return "Pertama"
        case 2: return "Kedua"
        case 3: return "Ketiga"
        case 4: return "Keempat"
        case 5: return "Kelima"
        case 6: return "Keenam"
        case 7: return "Ketujuh"
        case 8: return "Kedelapan"
        case 9: return "Kesembilan"
        case 10: return "Kesepuluh"
        case 11: return "Kesebelas"
        case 12: return "Keduabelas"
        case 13: return "Ketigabelas"
        case 14: return "Keempatbelas"
        case 15: return "Kelimabelas"

        default: return this.order
      }
    },

    desc_animation() {
      return this.type == 'bride' ? 'fade-right' : 'fade-left'
    }
  }
}
</script>

<style scoped>
  p.name {
    font-family: var(--avatar-name-font-family);
    font-size: var(--avatar-name-font-size);
    color: var(--avatar-name-color);
  }

  p.desc {
    font-family: var(--avatar-desc-font-family);
    font-size: var(--avatar-desc-font-size);
    color: var(--avatar-desc-color);
  }

  p.desc.parent {
    font-size: var(--avatar-parent-font-size);
    font-family: var(--avatar-parent-font-family);
    line-height: 1.75rem;
  }

  .avatar-container {
    position: relative;
  }

  .bride section[name=desc] {
    position: absolute;
    bottom: 14%;
    right: 60%;
    background: #102135;
    padding: 1rem 1.5rem;
    text-align: right;
    min-width: 200%;
    min-height: 8rem;
  }

  .groom section[name=desc] {
    position: absolute;
    bottom: 14%;
    left: 60%;
    background: #102135;
    padding: 1rem 1.5rem;
    text-align: left;
    min-width: 200%;
    min-height: 8rem;
  }
</style>

<style mobile scoped>
  .mobile p.name {
    font-size: var(--m-avatar-name-font-size);
  }

  .mobile p.desc {
    font-size: var(--m-avatar-desc-font-size);
    font-family: var(--m-avatar-desc-font-family);
  }

  .mobile p.desc.parent {
    font-size: var(--m-avatar-parent-font-size);
    font-family: var(--m-avatar-parent-font-family);
  }

  .mobile .avatar-container {
    max-width: 40%;
    min-width: 40%;
  }

</style>