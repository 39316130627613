<template>
  <BaseInput
    v-bind="[$baseComponents.input, $attrs]"
    @input="val => $emit('input', val)"
  />
</template>

<script>
import BaseInput from "@/components/bases/BaseInput"

export default {
  components: { BaseInput }
}
</script>