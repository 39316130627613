<template>
  <BaseRadio
    v-bind="[$baseComponents.radio, $attrs]"
    @input="val => $emit('input', val)"
  />
</template>

<script>
import BaseRadio from "@/components/bases/BaseRadio"

export default {
  components: { BaseRadio }
}
</script>