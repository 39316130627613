<template>
  <BaseLink v-bind="$attrs">
    <slot></slot>
  </BaseLink>
</template>

<script>
import BaseLink from "@/components/bases/BaseLink"

export default {
  components: { BaseLink }
}
</script>