var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center position-relative",style:(Object.assign({}, {minHeight: _vm.isDesktop ? _vm.css.windowHeight + _vm.css.unit : '100vh'},
    _vm.styles)),attrs:{"id":"cover-section"}},[(_vm.css.withVideoBackground)?[_c('video',{attrs:{"autoplay":"","muted":"","loop":"","webkit-playsinline":"","playsinline":"","id":"cover-video"},domProps:{"muted":true}},[_c('source',{attrs:{"src":"/bg-videos/customVirandaAkbar/bg-video.mp4","type":"video/mp4"}})])]:_vm._e(),_c('div',{attrs:{"name":"content","data-aos":"fade-in"}},[(_vm.$weddingData.coverTitle)?[_c('section',{attrs:{"name":"title"}},[_c('div',{attrs:{"name":"cover-title"}},[_c('p',[_vm._v(_vm._s(_vm.$weddingData.coverTitle))])])])]:_vm._e(),_c('section',{staticClass:"d-flex justify-content-center",attrs:{"name":"bride-groom"}},[_c('div',{style:({
          width: _vm.css.nameWidth,
          marginBottom: _vm.isDesktop ? (_vm.$guestData ? _vm.css.marginBottom : (_vm.publicNameMarginBottom || _vm.css.marginBottom)) : (_vm.$guestData ? _vm.css.marginBottom : '0')
        }),attrs:{"name":"name"}},[_c('p',{staticClass:"w-max",style:({
            paddingLeft: _vm.isDesktop ? _vm.css.bridePaddingLeft : _vm.css.m_bridePaddingLeft,
            paddingRight: _vm.isDesktop ? _vm.css.bridePaddingRight : _vm.css.m_bridePaddingRight,
            marginLeft: _vm.isDesktop ? _vm.css.brideMarginLeft : _vm.css.m_brideMarginLeft,
            marginRight: _vm.isDesktop ? _vm.css.brideMarginRight : _vm.css.m_brideMarginRight
          }),attrs:{"name":"bride"}},[_vm._v(" "+_vm._s(_vm.$weddingData.brideNickName)+" ")]),(_vm.css.andWithLine)?[_c('div',{staticClass:"d-flex align-items-center",attrs:{"name":"and-withline"}},[_vm._m(0),_c('p',{staticClass:"text-center w-100",style:({
                marginTop: _vm.isDesktop ? _vm.css.andMarginTop : _vm.css.m_andMarginTop,
                marginBottom: _vm.isDesktop ? _vm.css.andMarginBottom : _vm.css.m_andMarginBottom, 
                marginLeft: _vm.isDesktop ? _vm.css.andMarginLeft : _vm.css.m_andMarginLeft, 
                marginRight: _vm.isDesktop ? _vm.css.andMarginRight : _vm.css.m_andMarginRight
              }),attrs:{"name":"and"}},[_vm._v(" and ")]),_vm._m(1)])]:[_c('p',{staticClass:"text-center",style:({
              marginTop: _vm.isDesktop ? _vm.css.andMarginTop : _vm.css.m_andMarginTop,
              marginBottom: _vm.isDesktop ? _vm.css.andMarginBottom : _vm.css.m_andMarginBottom, 
              marginLeft: _vm.isDesktop ? _vm.css.andMarginLeft : _vm.css.m_andMarginLeft, 
              marginRight: _vm.isDesktop ? _vm.css.andMarginRight : _vm.css.m_andMarginRight
            }),attrs:{"name":"and"}},[_vm._v(" and ")])],_c('p',{staticClass:"text-center",style:({
            paddingRight: _vm.isDesktop ? _vm.css.groomPaddingRight : _vm.css.m_groomPaddingRight, 
            paddingLeft: _vm.isDesktop ? _vm.css.groomPaddingLeft : _vm.css.m_groomPaddingLeft,
            marginLeft: _vm.isDesktop ? _vm.css.groomMarginLeft : _vm.css.m_groomMarginLeft,
            marginRight: _vm.isDesktop ? _vm.css.groomMarginRight : _vm.css.m_groomMarginRight
          }),attrs:{"name":"groom"}},[_vm._v(" "+_vm._s(_vm.$weddingData.groomNickName)+" ")])],2)]),(_vm.$weddingData.coverDate)?_c('section',{attrs:{"name":"date"}},[_c('p',{attrs:{"name":"cover-date"}},[_vm._v(_vm._s(_vm.$weddingData.coverDate))])]):_vm._e(),(_vm.$guestData)?_c('section',{attrs:{"name":"dear"}},[(_vm.isDesktop)?_c('p',{staticClass:"text-center",attrs:{"name":"guest-name"}},[_vm._v(" Kepada Yth: "+_vm._s(_vm.$guestData.name)+" ")]):(_vm.isMobile)?_c('p',{staticClass:"text-center",attrs:{"name":"guest-name"}},[_vm._v(" Kepada Yth."),_c('br'),_vm._v(_vm._s(_vm.$guestData.name)+" ")]):_vm._e()]):_vm._e(),_c('section',{staticClass:"d-flex justify-content-center",style:({ marginTop: _vm.css.openBtnMarginTop }),attrs:{"name":"open"}},[(_vm.isDesktop)?[_c('Button',{staticClass:"open-button",attrs:{"icon":"email-closed.svg","text":"Buka Undangan"},nativeOn:{"click":function($event){_vm.$buttonClick(function () { return _vm.$emit('open'); })}}})]:_vm._e()],2)],2),(_vm.isMobile)?_c('div',{style:({
      marginTop: _vm.$guestData ? '107%' : '80%'
    }),attrs:{"name":"side-button"},on:{"click":function($event){_vm.$buttonClick(function () { return _vm.$emit('open'); })}}},[_c('img',{staticClass:"side-button-icon",attrs:{"src":"/icons/customVirandaAkbar/arrow-left.svg","alt":"side-button-icon"}}),_vm._v(" Buka Undangan ")]):_vm._e(),_c('div',{attrs:{"name":"decorations"}},[_c('CoverDecoration')],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100",attrs:{"name":"line-left"}},[_c('hr')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100 h-100",attrs:{"name":"line-right"}},[_c('hr')])}]

export { render, staticRenderFns }