import { website_url } from '@/config/website-config'

const generateLocalSrc = img_src => {
  return `${website_url}${img_src}`
}

export default [
  generateLocalSrc('/story/story_1.png'),
  generateLocalSrc('/story/story_2.png'),
  generateLocalSrc('/story/story_3.png'),
  generateLocalSrc('/story/story_4.png'),
  generateLocalSrc('/story/story_5.png'),
  generateLocalSrc('/story/story_6.png'),
  generateLocalSrc('/story/story_7.png'),
  generateLocalSrc('/story/story_8.png'),
  generateLocalSrc('/story/story_9.png'),
  generateLocalSrc('/story/story_10.png'),
  generateLocalSrc('/icons/customVirandaAkbar/disk-plate.png'),
  generateLocalSrc('/icons/customVirandaAkbar/disk-arm-arm.png'),
]