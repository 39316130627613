<template>
  <div
    id="time-section"
    class="d-flex align-items-center justify-content-center position-relative"
  >
    <div name="content" class="w-100">
      <div class="top-decor">
        <template v-if="isDesktop">
          <img :src="`${iconDir}/top-center.png`" class="time-top-decoration" alt="top-center-decoration" data-aos="zoom-in">

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#102135" fill-opacity="1" d="M0,160L120,186.7C240,213,480,267,720,288C960,309,1200,299,1320,293.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
          </svg>
        </template>

        <template v-else-if="isMobile">
          <img :src="`${iconDir}/top-center-2.png`" class="time-top-center-2-decoration pointer-events-none" alt="time-top-center-2-decoration" id="time-section-scrollto">

          <img :src="`${iconDir}/top-center-1.svg`" class="time-top-center-1-decoration pointer-events-none" alt="time-top-center-1-decoration">
        </template>
      </div>

      <div class="time-content">
        <section
          name="title" 
          class="text-center"
          :class="{
            'mt-19-5 mb-7-5': isDesktop,
          }"
        >
          <img 
            :width="isDesktop ? css.dateTimeIconSize : css.m_dateTimeIconSize" 
            :src="`/icons/${baseIconDir}/date.svg`"
          >

          <Title 
            :class="{
              'mt-5': isDesktop,
              'mt-4': isMobile
            }" 
            text="Waktu Pelaksanaan" 
          />
        </section>
  
        <section name="date" class="d-flex justify-content-center">
          <DateCard :type="css.dateCardType" />
        </section>
  
        <section name="time" class="d-flex justify-content-center">
          <TimeCard :type="css.timeCardType" />
        </section>
  
        <!-- <section name="open-calendar" v-if="isMobile" class="mt-7-5 d-flex justify-content-center">
          <Button icon="calendar.svg" text="Catat di Calendar" />
        </section> -->
  
        <section 
          name="count-down"
          :style="{
            marginTop: isDesktop ? '3.75rem' : ''
          }"
        >
          <CountDown 
            :date="$weddingData.receptionCountDown"
          />
        </section>

        <!-- <template v-if="isMobile">
          <div name="add-calendar-button-wrapper">
            <Button 
              icon="calendar.svg" 
              text="Catat di Calendar" 
            />
          </div>
        </template> -->
      </div>

    </div>

    <div name="decorations">
      <TimeDecoration />
    </div>
  </div>
</template>

<script>
import ColorWrapper from "@/components/resources/ColorWrapper"
import DateCard from "@/components/sections/time/DateCard"
import TimeCard from "@/components/sections/time/TimeCard"
import TimeDecoration from "@/components/sections/time/TimeDecoration"

export default {
  components: { ColorWrapper, DateCard, TimeCard, TimeDecoration },
  data() {
    return {
      css: {
        ...this.$sections.time
      }
    }
  },

  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/time/${this.device}`
    },

    baseIconDir() {
      return this.$baseComponents.icon.dir
    }
  }
}
</script>

<style>
  .time-content .bote-card {
    padding-top: 1.25rem !important;
    padding-bottom: 3rem !important;
  }
</style>

<style mobile>
  .mobile .time-content .bote-card {
    padding-top: 1rem !important;
    padding-bottom: 1.38rem !important;
  }
</style>

<style scoped>
  section[name=background] {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: -35%;
  }

  .container {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    vertical-align: middle;
    overflow: hidden;
  }

  #time-section [name=content] {
    background: #102135;
    position: relative;
  }

  #time-section section[name=date] .wrapper-container,
  #time-section section[name=time] .wrapper-container {
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .top-decor img, .top-decor svg {
    width: 100%;
    position: absolute;
  }

  .top-decor img.time-top-decoration {
    transform: translateY(-100%);
  }

  .top-decor svg {
    transform: translateY(-93%);
  }

  .top-decor {
    margin-top: 3rem;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
</style>

<style mobile scoped>
  .mobile .top-decor {
    margin-top: 0;
  }

  .mobile .top-decor img, .mobile .top-decor svg {
    top: -12rem;
    z-index: -2;
    left: -2px;
    width: calc(100% + 4px);
  }

  .mobile .top-decor .time-top-center-2-decoration {
    top: -10.6rem;
    left: -2px;
    width: calc(100% + 4px);
  }

  .mobile [name=count-down] {
    margin-top: 2.06rem;
  }

  .mobile [name=add-calendar-button-wrapper] {
    padding: 1rem 1.87rem;
    display: flex;
    justify-content: center;
  }
</style>