<template>
  <div 
    id="rsvp-decoration"
    :class="{
      rsvp_added
    }"
  >
    <div 
      v-if="isDesktop"
      name="top-center"
    >
      <img :src="`${iconDir}/top-center.svg`" alt="top-center-decor" class="top-center-decor">

      <img :src="`${iconDir}/top-2-center.png`" alt="top-2-center-decor" class="top-2-center-decor">

      <div 
        class="bg-top-center-decor"
        alt="bg-top-center-decor"
        :style="{
          backgroundImage: `url('${iconDir}/bg-top-center.png')`
        }"
      >
      </div>
    </div>

    <div 
      v-else-if="isMobile"
      name="top-center"
    >
      <img :src="`${iconDir}/top-center.svg`" alt="top-center-decor" class="top-center-decor">

      <img :src="`${iconDir}/top-2-center.png`" alt="top-2-center-decor" class="top-2-center-decor">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hide_form: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    decor() {
      let decors = this.$decorations.rsvp

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/rsvp/${this.device}`
    },
    styles() {
      const css = this.$decorations.rsvp

      const desktop = {
        '--top-left-top': css.topLeft_top,
        '--top-left-left': css.topLeft_left,
        '--top-left-width': css.topLeft_width,

        '--top-right-top': css.topRight_top,
        '--top-right-right': css.topRight_right,
        '--top-right-width': css.topRight_width,
      }

      const mobile = {
        '--m-top-left-top': css.m_topLeft_top,
        '--m-top-left-left': css.m_topLeft_left,
        '--m-top-left-width': css.m_topLeft_width,

        '--m-top-right-top': css.m_topRight_top,
        '--m-top-right-right': css.m_topRight_right,
        '--m-top-right-width': css.m_topRight_width,
      }

      return { ...mobile, ...desktop }
    },
    rsvp_added() {
      return this.$guestData.rsvp ? true : false
    }
  }
}
</script>

<style scoped>
  [name=top-center] img {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
  }

  [name=top-left] img {
    top: var(--top-left-top);
    left: var(--top-left-left);
    width: var(--top-left-width);
  }

  [name=top-right] img {
    top: var(--top-right-top);
    right: var(--top-right-right);
    width: var(--top-right-width);
  }

  .top-center-decor {
    width: 100vw;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    transform: rotateX(180deg);
    z-index: 2;
  }

  .top-2-center-decor {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    top: -2.813rem;
    width: 100%;
  }

  .bg-top-center-decor {
    position: absolute;
    z-index: 0;
    right: 0;
    left: 0;
    top: -2.813rem;
    bottom: 0;
    max-height: 37.625rem;
    width: 100%;
    background-size: cover;
    background-position: top center;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
  }

  #rsvp-decoration.rsvp_added .bg-top-center-decor {
    padding-bottom: 5rem;
  }
</style>

<style mobile scoped>
  .mobile [name=top-left] img {
    top: var(--m-top-left-top);
    left: var(--m-top-left-left);
    width: var(--m-top-left-width);
  }

  .mobile [name=top-right] img {
    top: var(--m-top-right-top);
    right: var(--m-top-right-right);
    width: var(--m-top-right-width);
  }

  .mobile .top-center-decor {
    transform: unset;
    z-index: 5;
  }

  .mobile .top-2-center-decor {
    top: -1.7rem;
    z-index: 4;
  }
</style>