<template>
  <div class="wrapper">
    <div class="spinner">
      <img
        src="/icons/default/momentospin.png"
        class="spin-logo"
        alt="momento loading"
        width="100"
      />

      <div 
        class="loading-bar-wrapper"
        :class="{
          'mobile': isMobile
        }"
      >
        <div 
          class="loading-bar"
          :style="{
            width: `${load_value}%`
          }"
        >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from "@/components/helpers/event-bus"

  export default {
    data() {
      return {
        preload_loaded: 0,
        max_preload: {
          mobile: 100,
          desktop: 86
        }
      }
    },

    computed: {
      load_value() {
        return this.preload_loaded / this.max_preload.mobile * 100
      }
    },
    
    methods: {
      listenPreloadLoadedValueChanged() {
        EventBus.$on('preloadLoadedValueChanged', val => this.preload_loaded = val > 20 ? val : 20)
      }
    },

    mounted() {
      this.listenPreloadLoadedValueChanged()
    }
  }
</script>

<style scoped>
  .wrapper {
    background: #102135;
    height: 100vh;
    width: 100vw;
  }

  .spinner {
    position: absolute;
    left: 0;
    margin: auto;
    bottom: 45vh;
    right: 0;
    z-index: 100;
    text-align: center;
  }

  .spin-logo {
    animation: rotation 2s infinite linear;
  }

  p.loading-text {
    color: #fff;
  }

  .loading-bar-wrapper {
    width: 20vw;
    height: 0.75rem;
    background: #5D7394;
    margin: 4rem auto 0 auto;
    border-radius: 0.75rem;
    padding: 0.25rem;
  }

  .loading-bar {
    width: 0%;
    height: 100%;
    border-radius: 0.75rem;
    background-color: #102135;
    transition: 0.15s;
  }
</style>

<style mobile scoped>
  .mobile.loading-bar-wrapper {
    width: 60vw;
  }
</style>