<template>
  <div
    id="opening-section"
    :class="{
      'd-flex align-items-center justify-content-center pt-17-5': isDesktop
    }"
    class="position-relative"
    :style="{
      ...styles,

      paddingBottom: isDesktop ? '3rem' : ''
    }"
  >
    <video autoplay muted webkit-playsinline playsinline loop id="opening-video">
      <source :src="`/bg-videos/customVirandaAkbar/bg-video.mp4`" type="video/mp4">
    </video>

    <!-- start::desktop view -->
    <template v-if="isDesktop">
      <div name="content">
        <section name="quotes">
          <BorderedText>
            <p class="q-content">{{ $weddingData.quotes }}</p>
            <template v-if="$weddingData.quotesDesc">
              <p class="q-desc mt-2-5">{{ $weddingData.quotesDesc }}</p>
            </template>
          </BorderedText>
        </section>

        <section name="bride-groom">
          <div class="d-flex justify-content-center">
            <Avatar type="bride" :name="$weddingData.brideFullName" :order="$weddingData.brideChildOrder" :father="$weddingData.brideFather" :mother="$weddingData.brideMother"/>
            
            <div name="avatar-and" class="d-flex align-items-center">
              <p>&</p>
            </div>

            <Avatar type="groom" :name="$weddingData.groomFullName" :order="$weddingData.groomChildOrder" :father="$weddingData.groomFather" :mother="$weddingData.groomMother"/>
          </div>
        </section>
      </div>
      <div name="decorations">
        <OpeningDecoration />
      </div>
    </template>
    <!-- end::desktop view -->

    <!-- start::mobile view -->
    <OpeningMobileSection
      v-else-if="isMobile"
    />
    <!-- end::mobile view -->
  </div>
</template>

<script>
import Avatar from "@/components/sections/opening/Avatar"
import OpeningDecoration from "@/components/sections/opening/OpeningDecoration"
import OpeningMobileSection from "@/components/sections/opening/OpeningMobileSection"

export default {
  components: { 
      Avatar, 
      OpeningDecoration, 
      OpeningMobileSection
  },

  data() {
    return {
      css: {
        qContentFontFamily: "opensansR",
        qContentFontSize: "1rem",
        qContentColor: "#161616",
        qDescFontFamily: "opensansB",
        qDescFontSize: "1rem",
        qDescColor: "#161616",

        ...this.$sections.opening
      }
    }
  },

  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--q-content-font-family': css.qContentFontFamily,
        '--q-content-font-size': css.qContentFontSize,
        '--q-content-color': css.qContentColor,
        '--q-desc-font-family': css.qDescFontFamily,
        '--q-desc-font-size': css.qDescFontSize,
        '--q-desc-color': css.qDescColor
      }

      const mobile = {
        '--m-q-content-font-size': css.m_qContentFontSize,
        '--m-q-desc-font-size': css.m_qDescFontSize
      }
      
      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  #opening-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
  }
  p.q-content {
    font-family: var(--q-content-font-family);
    font-size: var(--q-content-font-size);
    color: var(--q-content-color);
    line-height: 1.75rem;
  }

  p.q-desc {
    font-family: var(--q-desc-font-family);
    font-size: var(--q-desc-font-size);
    color: var(--q-desc-color);
    background: var(--q-desc-color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
  }

  section[name=bride-groom] {
    margin-top: 2.313rem;
    padding-bottom: 11.688rem;
  }

  [name=avatar-and] {
    padding-right: 6.5rem;
    padding-left: 6.25rem;
    font-family: cinzelR;
    font-size: 16.75rem;
    color: var(--q-desc-color);
    background: conic-gradient(from 180deg at 40.15% 53.73%, #B8C9D3 -28.12deg, #FBFBFD 26.25deg, #C8D4DA 88.12deg, #FFFFFF 156.58deg, #AEC0CE 191.74deg, #E3E9EE 237.13deg, #FAFBFC 255.19deg, #D6DFE6 310.11deg, #B8C9D3 331.88deg, #FBFBFD 386.25deg);
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.5;
  }
</style>

<style scoped>
  .mobile #opening-section {
    padding-top: 1.25rem !important;
    padding-bottom: 12.5rem;
  }

  .mobile #opening-section div[name=content] {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }

  .mobile p.q-content {
    font-size: var(--m-q-content-font-size);
  }

  .mobile p.q-desc {
    font-size: var(--m-q-desc-font-size);
  }
</style>