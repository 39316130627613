<template>
  <BaseTextArea
    v-bind="[$attrs, $baseComponents.input, $baseComponents.textArea]"
    @input="val => $emit('input', val)"
  />
</template>

<script>
import BaseTextArea from "@/components/bases/BaseTextArea"

export default {
  components: { BaseTextArea }
}
</script>