<template>
  <a v-if="isExternal" :href="$attrs.to" :target="isNewTab ? '_blank' : ''">
    <slot></slot>
  </a>
  <RouterLink v-else v-bind="$attrs">
    <slot></slot>
  </RouterLink>
</template>

<script>
  export default {
    props: ["external", "newtab"],
    computed: {
      isExternal() {
        return typeof(this.external) != 'undefined' ? true : false
      },
      isNewTab() {
        return typeof(this.newtab) != 'undefined' ? true : false
      }
    }
  }
</script>

<style scoped>
  * {
    text-decoration: none; 
  }
</style>