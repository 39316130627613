<template>
  <div id="wish-decoration" :style="styles">
    <template v-if="isMobile">
      <div name="top-left" v-if="decor.topLeft">
        <img :src="`${iconDir}/top-left.png`" class="decoration" alt="top-left-decoration" data-aos="zoom-in-right">
      </div>
    </template>
    <div name="top-right" v-if="decor.topRight">
      <img :src="`${iconDir}/top-right.png`" class="decoration" alt="top-right-decoration" data-aos="zoom-in-left">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      let decors = this.$decorations.wish

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/wish/${this.device}`
    },
    styles() {
      const css = this.$decorations.wish

      const desktop = {
        '--top-right-top': css.topRight_top,
        '--top-right-right': css.topRight_right,
        '--top-right-width': css.topRight_width,
      }

      const mobile = {
        '--m-top-left-top': css.m_topLeft_top,
        '--m-top-left-left': css.m_topLeft_left,
        '--m-top-left-width': css.m_topLeft_width,

        '--m-top-right-top': css.m_topRight_top,
        '--m-top-right-right': css.m_topRight_right,
        '--m-top-right-width': css.m_topRight_width,
      }

      return { ...mobile, ...desktop }
    }
  }
}
</script>

<style scoped>
  [name=top-right] img {
    top: var(--top-right-top);
    right: var(--top-right-right);
    width: var(--top-right-width);
  }
</style>

<style mobile scoped>
  .mobile [name=top-left] img {
    top: var(--m-top-left-top);
    left: var(--m-top-left-left);
    width: var(--m-top-left-width);
  }

  .mobile [name=top-right] img {
    top: var(--m-top-right-top);
    right: var(--m-top-right-right);
    width: var(--m-top-right-width);
  }
</style>