var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:{
    'd-flex align-items-center justify-content-center': _vm.isDesktop,
    'mt-30 px-5': _vm.isMobile
  },style:(_vm.styles),attrs:{"id":"wish-section"}},[_c('div',{staticClass:"w-100",attrs:{"name":"content"}},[_c('section',{attrs:{"name":"title"}},[_c('Title',{attrs:{"text":_vm.wishTitleText}})],1),_c('section',{class:{
        'd-flex justify-content-center': _vm.isDesktop
      },attrs:{"name":"wish"}},[_c('div',{class:{
          'w-100': _vm.isDesktop,
          'maxh-rem-31': _vm.isMobile,
          'overflowed': _vm.is_chat_container_overflowed
        },attrs:{"id":"wishes","name":"wishes"}},[(_vm.wishes.length)?[_vm._l((_vm.wishes),function(wish,index){return [_c('BorderedText',_vm._b({key:index,attrs:{"boteStyle":"chat"}},'BorderedText',wish,false))]})]:[_c('p',{staticClass:"no-data text-center"},[_vm._v(" Belum ada ucapan. ")])]],2),_c('div',{class:{
          'w-100': _vm.isDesktop
        },attrs:{"name":"form-input"}},[_c('div',{staticClass:"wish-form-wrapper"},[_c('Input',{class:{
              'mb-6': _vm.isDesktop,
            },attrs:{"label":"Nama","placeholder":"Tulis Namamu..."},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('TextArea',{class:{
              'mb-6': _vm.isDesktop,
              'mb-3-752': _vm.isMobile
            },attrs:{"label":"Pesan","placeholder":"Tulis Pesanmu...","limit":"500","rows":"12"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{staticClass:"d-flex justify-content-center"},[_c('Button',{attrs:{"minWidth":"100%","paddingX":"0","text":_vm.sendWishText,"loading":_vm.loading_submit_wish},nativeOn:{"click":function($event){return _vm.$buttonClick(_vm.submit)}}})],1)],1)])])]),(_vm.isMobile)?_c('div',{attrs:{"name":"decorations"}},[_c('img',{staticClass:"bg-wish-decoration",attrs:{"src":"/decorations/customVirandaAkbar/wish/mobile/background-decoration.png","alt":"bg-wish-decoration"}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }