<template>
  <div
    id="rsvp-section"
    :class="{
      'd-flex align-items-center justify-content-center': isDesktop,
      'px-3-752': isMobile
    }"
    :style="{
      paddingTop: isDesktop ? '15.75rem' : ''
    }"
    class="position-relative"
  >
    <div name="content" data-aos="fade-up">
      <Form
        class="form-container"
        :class="{
          'pb-9 px-25': isDesktop,
          'mt-2 pt-5 px-7-5': isMobile,
          'pb-8': isMobile && form.attendance == null
        }"
        :style="{
          paddingBottom: isMobile && form.attendance != null ? '4rem' : ''
        }"
        :noBorder="true"
      >
        <section name="title">
          <Title text="R S V P" />

          <p
            class="desc text-center"
            :class="{
              'mt-2-752': isDesktop
            }"
            :style="styles"
          >
            <template v-if="!hide_form">
              Hallo! <span class="desc-bold">{{ $guestData.name }}</span>, merupakan suatu kehormatan <br v-if="isMobile">apabila kamu dapat hadir dalam acara<br v-if="isDesktop"> pernikahan <br v-if="isMobile">kami. Mohon bantuannya agar kami dapat <br v-if="isMobile">mempersiapkan segala sesuatunya secara <br v-if="isDesktop">maksimal <br v-if="isMobile">dengan mengisi form berikut :
            </template>
            <template v-else>
              Terimakasih <span class="desc-bold">{{ $guestData.name }}</span>, telah mengisi kehadiran pada form ini. Apabila kamu ingin mengubah <br v-if="isDesktop">info kehadiranmu, silahkan klik tombol ‘ubah’ dibawah ini ya. Terimakasih
            </template>
          </p>

          <div
            name="confirmation-button-wrapper"
            class="d-flex justify-content-center"
          >
            <Button 
              v-if="hide_form"
              text="Ubah"
              @click.native="$buttonClick(buttonFunc)"
              :minWidth="'25rem'"
            />
          </div>
        </section>

        <section name="form-input">
          <div
            class="form-input-content-wrapper"
            v-if="!hide_form"
          >
            <div>
              <Radio
                :class="{
                  'mb-7-5': isDesktop,
                }"
                name="kehadiran"
                label="Kamu akan hadir ga nih?"
                :radio_items="attendance_options"
                v-model="form.attendance"
              />
              <transition name="anim-form">
                <template v-if="form.attendance">
                  <Radio
                    :class="{
                      'mb-7-5': isDesktop,
                    }"
                    name="jumlah"
                    label="Bagaimana kamu akan hadir?"
                    :radio_items="how_options"
                    v-model="form.how"
                  />
                </template>
              </transition>
              
              <transition name="anim-textarea">
                <TextArea
                  v-if="form.attendance == false"
                  :class="{
                    'mb-7-5': isDesktop,
                    'opened': !form.attendance
                  }"
                  class="rsvp-text-area"
                  label="Kenapa tidak bisa hadir? :("
                  placeholder="Tulis Alasanmu... (Boleh ga diisi kok hehe)"
                  limit="400"
                  v-model="form.message"
                  :disabled="isDisabled"
                />
              </transition>
            </div>
          </div>

          <div
            name="confirmation-button-wrapper"
            class="d-flex justify-content-center"
            v-if="((isMobile && showConfirmationButton) || isDesktop) && !hide_form"
          >
            <transition name="anim-confirm-btn">
              <Button 
                v-if="showConfirmationButton"
                :text="buttonText" 
                @click.native="$buttonClick(buttonFunc)"
                :minWidth="'25rem'"
              />
            </transition>
          </div>
        </section>
      </Form>
    </div>

    <div
      name="decorations"
    >
      <RSVPDecoration :hide_form="hide_form" />
    </div>
  </div>
</template>

<script>
import RSVPDecoration from "@/components/sections/rsvp/RSVPDecoration"

export default {
  components: { RSVPDecoration },
  data() {
    return {
      isDisabled: false,
      isInvited: false,
      attendance_options: [
        { id: 'hadir', value: true, label: 'Hadir' },
        { id: 'tidak_hadir', value: false, label: 'Maaf, belum bisa hadir' }
      ],
      how_options: [
        { id: 'sendiri', value: 1, label: 'Sendiri' },
        { id: 'berdua', value: 2, label: 'Berdua' }
      ],
      form: {
        id: null,
        name: "",
        message: "",
        attendance: null,
        how: null,
        username: null,
      },
      css: {
        descFontFamily: "opensansR",
        descFontSize: "1rem",
        descColor: "#161616",

        ...this.$sections.rsvp
      },
      hide_form: false
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--desc-font-family': css.descFontFamily,
        '--desc-font-size': css.descFontSize,
        '--desc-color': css.descColor
      }

      const mobile = {
        '--m-desc-font-size': css.m_descFontSize,
      }

      return { ...desktop, ...mobile }
    },
    buttonText() {
      return this.isDisabled ? "Ubah" : "Konfirmasi"
    },
    buttonFunc() {
      return this.isDisabled ? (() => {
        this.isDisabled = false
        this.hide_form = false
      }) : this.submit
    },

    showConfirmationButton() {
      if(this.form.attendance) {
        if(this.form.how != null) return true

        return false
      }

      return this.form.attendance != null
    }
  },
  methods: {
    __init() {
      this.getRsvpName()
    },
    async submit() {
      const validated = this.validate()
      let res = await this.$rsvpService.submitRsvp(this.form)
  
        if(res.success) {
          this.$alertSuccess("Terimakasih atas doa dan ucapannya :)")

          if(this.$guestData) {
            this.isDisabled = true
            this.hide_form = true

            this.form.id = res.data.id
          }
        } else {
          this.$alertFail(res.message)
        }

      // if(validated) {
      //   let res = await this.$rsvpService.submitRsvp(this.form)
  
      //   if(res.success) {
      //     this.$alertSuccess("Terimakasih atas doa dan ucapannya :)")

      //     if(this.$guestData) {
      //       this.isDisabled = true
      //       this.hide_form = true

      //       this.form.id = res.data.id
      //     }
      //   } else {
      //     this.$alertFail(res.message)
      //   }
      // } else {
      //   this.$alertFail("Harap isi form rsvp dengan lengkap")
      // }
    },
    validate() {
      const excludes = ["attendance", "how", "username", "id"]
      
      for(const key in this.form) {
        if(!excludes.includes(key)) {
          if(!this.form[key]) {
            return false
          }
        }
      }

      return true
    },
    getRsvpName() {
      if(this.$guestData) {
        this.form.name = this.$guestData.name
        this.form.username = this.$guestData.username

        this.isInvited = true

        this.form = { ...this.form, ...this.$guestData.rsvp }

        if(this.$guestData.rsvp) {
          this.isDisabled = true
          this.hide_form = true
        }
      }
    }
  },
  mounted() {
    this.__init()
  }
}
</script>

<style scoped>
  .maxw-300 {
    max-width: calc(75rem - (6.25rem * 2)) !important;
  }

  #rsvp-section {
    background: #101822;
    padding-bottom: 15rem;
  }

  p.desc {
    color: #fff;
    text-align: center;
    font-family: montserratR;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem;
  }

  p.desc .desc-bold {
    font-family: montserratB;
  }

  .isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }

  [name=content] {
    position: relative;
    z-index: 4;
  }

  [name=form-input] {
    margin-top: 9.31rem;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }

  [name=confirmation-button-wrapper] {
    margin-top: 5rem;
  }

  .form-input-content-wrapper {
    min-height: 16rem;
  }

  .anim-textarea-enter-active {
    height: 0;
    transform: scale(0);
    animation: blowed 0.5s;
    animation-delay: 0.5s;
  }

  .anim-textarea-leave-active {
    height: 0;
    transform: scale(0);
    animation: blowed 0.5s;
    animation-direction: reverse;
  }

  .anim-form-enter-active {
    animation: blowed 0.5s;
    animation-delay: 0.5s;
    height: 0;
    transform: scale(0);
  }

  .anim-form-leave-active {
    height: 0;
    transform: scale(0);
    animation: blowed 0.5s;
    animation-direction: reverse;
  }

  .anim-confirm-btn-enter-active {
    animation: blowed 0.5s;
    animation-delay: 0.5s;
    transform: scale(0);
  }

  .anim-confirm-btn-leave-active {
    transform: scale(0);
    animation: blowed 0.5s;
    animation-direction: reverse;
  }

  .form-container {
    width: 75rem;
  }
</style>

<style mobile scoped>
 .mobile p.desc {
  font-size: var(--m-desc-font-size);
 }

 .mobile .form-container {
  width: unset;
 }

 .mobile #rsvp-section {
  padding-left: 0 !important;
  padding-right: 0 !important;
 }

 .mobile .wrapper-container.form-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
 }

 .mobile .wrapper-container.form-container section[name=title] {
  padding-top: 6rem !important;
  padding-bottom: 1.25rem !important;
  background-image: url('/decorations/customVirandaAkbar/rsvp/mobile/bg-top-center.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
 }

 .mobile p.desc {
  margin: 1rem 1.87rem 1.25rem 1.87rem;
  line-height: normal;
 }

 .mobile #rsvp-section {
  margin-top: -0.6rem;
 }

 .mobile [name=form-input] {
    margin-top: 1.44rem;
    margin-bottom: 1.14rem;
    margin-left: 1.87rem;
    margin-right: 1.87rem;
  }

  .mobile .form-input-content-wrapper {
    min-height: 300px;
  }

  .mobile [name=confirmation-button-wrapper] {
    margin-top: 1.88rem;
  }

  .mobile .rsvp-text-area {
    margin-top: 1.5rem;
  }
</style>

<style mobile>
  .mobile [name=kehadiran] p.radio-label, .mobile [name=jumlah] p.radio-label {
    margin-bottom: 1rem !important;
  }

  .mobile [name=kehadiran] [name=radio-wrapper], .mobile [name=jumlah] [name=radio-wrapper] {
    display: flex;
    flex-direction: column;
  }

  .mobile [name=jumlah] {
    margin-top: 1.5rem;
  }

  .mobile #rsvp-section {
    padding-bottom: 8.75rem;
  }
</style>