import RepositoryBase from "../repository"

export default class rsvpRepository extends RepositoryBase {
  constructor() {
    super("rsvps")
  }

  getRsvpByUsername(username) {
    return new Promise((resolve, reject) => {
      this.ref
        .where("username", "==", username)
        .get()
        .then((rsvps) => {
          if(rsvps.size) {
            rsvps.forEach((rsvp) => {
              if (rsvp.exists) {
                resolve({
                  success: true,
                  data: rsvp
                })
              }
            })
          }

          resolve({
            success: false,
            message: "Rsvp doesn't exist"
          })
        })
        .catch((error) => {
          reject({
            success: false,
            message: error.message
          })
        });
    })
  }
}