export default [
  {
    name: 'opensansR',
    src: 'fonts/opensans/OpenSans-Regular.ttf'
  },
  {
    name: 'opensansB',
    src: 'fonts/opensans/OpenSans-Bold.ttf'
  },
  {
    name: 'opensansBI',
    src: 'fonts/opensans/OpenSans-BoldItalic.ttf'
  },
  {
    name: 'opensansEB',
    src: 'fonts/opensans/OpenSans-ExtraBold.ttf'
  },
  {
    name: 'opensansEBI',
    src: 'fonts/opensans/OpenSans-ExtraBoldItalic.ttf'
  },
  {
    name: 'opensansL',
    src: 'fonts/opensans/OpenSans-Light.ttf'
  },
  {
    name: 'opensansLI',
    src: 'fonts/opensans/OpenSans-LightItalic.ttf'
  },
  {
    name: 'opensansI',
    src: 'fonts/opensans/OpenSans-Italic.ttf'
  },
  {
    name: 'opensansSB',
    src: 'fonts/opensans/OpenSans-SemiBold.ttf'
  },
  {
    name: 'opensansSBI',
    src: 'fonts/opensans/OpenSans-SemiBoldItalic.ttf'
  }
]