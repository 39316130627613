<template>
  <BaseColorWrapper v-bind="$attrs" :backgroundColor="bgColor" :border="border">
    <slot></slot>
  </BaseColorWrapper>
</template>

<script>
import BaseColorWrapper from "@/components/bases/BaseColorWrapper"

export default {
  components: { BaseColorWrapper },
  data() {
    return {
      ...this.$baseComponents.colorWrapper,
      bgColor: this.$attrs.backgroundColor || this.$baseComponents.colorWrapper.bgColor
    }
  }
}
</script>