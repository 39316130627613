<template>
  <div
    v-if="loaded"
    :class="{ mobile: isMobile }"
    :style="{
      '--max-width': windowWidth + 'px',
      overflowX: 'hidden',
      overflowY: 'hidden'
    }"
  >

    <section name="cover" :style="$background(bgCover)" v-if="!closeCover">
      <CoverSection @open="openInvitation" :sections="$sections.cover"/>
    </section>

    <template v-if="opened">
      <section name="content" :style="$background(bgContent)">
        <!-- start::sections -->
        <OpeningSection data-aos="fade-up"/>
        <TimeSection 
          data-aos="fade-up"
        />
        <LocationSection/>
        <NotesSection v-if="optional_sections.notes" data-aos="fade-up"/>
        <RSVPSection v-if="optional_sections.rsvp && showRsvp"/>
        <StorySection v-if="optional_sections.story" data-aos="fade-up"/>
        <GallerySection v-if="optional_sections.gallery" data-aos="fade-up"/>
        <!-- <VideoSection v-if="optional_sections.video" data-aos="fade-up"/> -->
        <!-- <StreamingSection v-if="optional_sections.streaming" data-aos="fade-up"/> -->
        <WishSection data-aos="fade-up" :wishes="wishesData" @update="getWishes"/>
        <FilterInstagramSection data-aos="fade-up"/>
        <GiftSection v-if="optional_sections.gift" data-aos="fade-up"/>
        <!-- <WarningSection v-if="optional_sections.warning" data-aos="fade-up"/> -->
        <FooterSection data-aos="fade-in"/>
        <!-- end::sections -->

        <!-- start::resources -->
        <FloatingMenu v-if="isMobile" data-aos="zoom-in" :showRsvp="showRsvp" />
        <MusicButton data-aos="fade-left"/>
        <!-- end::resources -->
      </section>
    </template>

    <section name="modals">
      <GiftModal 
        v-if="show_gift_modal" 
      />
    </section>
  </div>
  <MomentoLoading v-else/>
</template>

<script>
import Vue from "vue"

// start::Sections
import CoverSection from "@/components/sections/cover/CoverSection"
import OpeningSection from "@/components/sections/opening/OpeningSection"
import TimeSection from "@/components/sections/time/TimeSection"
import LocationSection from "@/components/sections/location/LocationSection"
import RSVPSection from "@/components/sections/rsvp/RSVPSection"
import StorySection from "@/components/sections/story/StorySection"
import GallerySection from "@/components/sections/gallery/GallerySection"
import VideoSection from "@/components/sections/video/VideoSection"
import StreamingSection from "@/components/sections/streaming/StreamingSection"
import WishSection from "@/components/sections/wish/WishSection"
import GiftSection from "@/components/sections/gift/GiftSection"
import WarningSection from "@/components/sections/warning/WarningSection"
import NotesSection from "@/components/sections/notes/NotesSection"
import FooterSection from "@/components/sections/footer/FooterSection"
import FilterInstagramSection from "@/components/sections/filter-instagram/FilterInstagramSection"
// end::Sections

import GiftModal from "@/components/sections/gift/GiftModal"
import { EventBus } from "@/components/helpers/event-bus"

// start::data
import { website_url } from "@/config/website-config"
import preloaded_decoration_images from "@/assets/data/preloaded_images/decorations"
import preloaded_decoration_images_mobile from "@/assets/data/preloaded_images/mobile_decorations"
import preloaded_images from "@/assets/data/preloaded_images/images"
import preloaded_images_mobile from "@/assets/data/preloaded_images/mobile_images"
// end::data

// start::fonts
import font_cinzeldecorative from "@/assets/fonts/cinzeldecorative"
import font_andasia from "@/assets/fonts/andasia"
import font_poppins from "@/assets/fonts/poppins"
import font_antsvalley from "@/assets/fonts/antsvalley"
import font_opensans from "@/assets/fonts/opensans"
import font_cinzel from "@/assets/fonts/cinzel"
import font_montserrat from "@/assets/fonts/montserrat"
// end::fonts

export default {
  components: { CoverSection, OpeningSection, TimeSection, LocationSection, RSVPSection, StorySection, GallerySection, VideoSection, StreamingSection, WishSection, GiftSection, WarningSection, NotesSection, FooterSection, FilterInstagramSection, GiftModal },

  data() {
    return {
      opened: false,
      loaded: false,
      iconDir: "default",
      optional_sections: {},
      closeCover: false,
      wishesData: [],
      showRsvp: false,
      show_gift_modal: false,
      preload_loaded: 0
    }
  },

  computed: {
    bgCover() {
      const { iconDir, device } = this
      const { background } = this.$baseComponents

      if(background.withImage) return `url('/bg-icons/${iconDir}/background-cover-${device}.png')`

      return background.bgColor
    },
    bgContent() {
      const { iconDir, device } = this
      const { background } = this.$baseComponents

      if(background.withImage) return `url('/bg-icons/${iconDir}/background-${device}.png')`

      return background.bgColor
    },
    username() {
      return this.$route.params.username
    }
  },

  watch: {
    preload_loaded(val) {
      EventBus.$emit('preloadLoadedValueChanged', val)
    }
  },

  methods: {
    __init() {
      Promise.all([
        this.getWeddingData(),
        this.getOptionalSections(),
        this.getGuest(),
        this.getWishes(),
      ]).then(async () => {
        await this.preloadAllImages()
        await this.preloadAllFonts()

        this.loaded = true
      })
    },

    async getWeddingData() {
      const res = await this.$settingRepository.getWeddingData()

      if(res.success) {
        Vue.prototype.$weddingData = { ...this.$weddingData, ...res.data}

        this.setTheme()
      }
    },

    async getOptionalSections() {
      const res = await this.$settingRepository.getOptionalSections()

      if(res.success) {
        this.optional_sections = res.data

        Vue.prototype.$optionalSections = res.data
      }
    },

    async getGuest() {
      const res = await this.$guestRepository.getGuestByUsername(this.username)

      if(res.success) {
        this.showRsvp = true

        const rsvp = await this.getRsvp()

        Vue.prototype.$guestData = { ...res.data.data(), rsvp }
      }

    },

    async getRsvp() {
      const res = await this.$rsvpRepository.getRsvpByUsername(this.username)
      if(res.success) return {
        id: res.data.id,
        ...res.data.data()
      }
    },

    async getWishes() {
      const res = await this.$wishRepository.getWishes()

      this.wishesData = []

      if(res.success) {
        res.data.docs.forEach(wish => {
          this.wishesData.push({
            id: wish.id,
            name: wish.data().name,
            comment: wish.data().message
          })
        })

        this.wishesData.sort((a, b) => b.id - a.id)
      }
    },
    
    setTheme() {
      const { baseComponents, sections, decorations } = require(`@/assets/themes/${this.$weddingData.theme}`)

      Vue.prototype.$baseComponents = baseComponents
      Vue.prototype.$decorations = decorations
      Vue.prototype.$sections = { ...this.$sections, ...sections }

      this.iconDir = this.$baseComponents.icon.dir
    },

    openInvitation() {
      this.opened = true

      setTimeout(() => {
        this.closeCover = true
        window.scrollTo({ top: top })
      }, 1000)

      setTimeout(() => {
        const element = document.getElementById("opening-section")
        const top = element.offsetTop
  
        window.scrollTo({ top: top, behavior: 'smooth' })
      }, 100)
    },

    preloadImage(src) {
      return new Promise((resolve, reject) => {
        const image = new Image()
        image.onload = resolve
        image.onerror = reject
        image.src = src
      })
    },

    async preloadAllImages() {
      const { galleries, brideAvatar, groomAvatar, galleryInitialSrc } = this.$weddingData

      let images_to_preload = []

      if(this.isDesktop) {
        images_to_preload = [
          brideAvatar,
          groomAvatar,
          galleryInitialSrc,
          ...galleries,
          ...preloaded_decoration_images,
          ...preloaded_images
        ] //desktop assets
      } else {
        images_to_preload = [
          galleryInitialSrc,
          ...galleries,
          ...preloaded_decoration_images_mobile,
          ...preloaded_images_mobile
        ] //mobile assets
      }

      images_to_preload = [
        ...images_to_preload
      ].map(src => {
        return {
          src
        }
      })

      return await Promise.all(images_to_preload.map(async img => {
        await this.preloadImage(img.src)

        this.preload_loaded = this.preload_loaded + 1
      }))
    },

    preloadFont(name, src) {
      return new Promise(resolve => {
        const font = new FontFace(name, `url('${website_url}/${src}')`)
  
        document.fonts.add(font)
        font.load()

        resolve(true)
      })
    },

    async preloadAllFonts() {
      return new Promise(resolve => {
        if(document.fonts) {
          const fonts = [
            ...font_cinzeldecorative,
            ...font_andasia,
            ...font_poppins,
            ...font_antsvalley,
            ...font_opensans,
            ...font_cinzel,
            ...font_montserrat
          ]

          fonts.forEach(async font => {
            await this.preloadFont(font.name, font.src)

            this.preload_loaded = this.preload_loaded + 1
          })

          document.fonts.ready.then(() => resolve(true))
        }
      })
    }
  },

  async mounted() {
    this.__init()

    EventBus.$on('giftModalState', state => this.show_gift_modal = state);
  }
}
</script>

<style scoped>
  section[name=cover], section[name=content] {
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
</style>

<style mobile scoped>
  .mobile {
   width: var(--max-width);
   max-width: var(--max-width);
  }
</style>