<template>
  <div
    id="cover-section"
    class="d-flex align-items-center justify-content-center position-relative"
    :style="{ 
      minHeight: isDesktop ? css.windowHeight + css.unit : '100vh',
      ...styles
    }"
  >
    <template v-if="css.withVideoBackground">
      <video autoplay muted loop webkit-playsinline playsinline id="cover-video">
        <source :src="`/bg-videos/customVirandaAkbar/bg-video.mp4`" type="video/mp4">
      </video>
    </template>

    <div name="content" data-aos="fade-in">
      <template v-if="$weddingData.coverTitle">
        <section name="title">
          <div name="cover-title">
            <p>{{ $weddingData.coverTitle }}</p>
          </div>
        </section>
      </template>

      <section
        name="bride-groom"
        class="d-flex justify-content-center"
      >
        <div
          name="name" 
          :style="{
            width: css.nameWidth,
            marginBottom: isDesktop ? ($guestData ? css.marginBottom : (publicNameMarginBottom || css.marginBottom)) : ($guestData ? css.marginBottom : '0')
          }"
        >
          <p
            name="bride"
            class="w-max"
            :style="{
              paddingLeft: isDesktop ? css.bridePaddingLeft : css.m_bridePaddingLeft,
              paddingRight: isDesktop ? css.bridePaddingRight : css.m_bridePaddingRight,
              marginLeft: isDesktop ? css.brideMarginLeft : css.m_brideMarginLeft,
              marginRight: isDesktop ? css.brideMarginRight : css.m_brideMarginRight
            }"
          >
            {{ $weddingData.brideNickName }}
          </p>

          <template v-if="css.andWithLine">
            <div name="and-withline" class="d-flex align-items-center">
              <div name="line-left" class="w-100 h-100">
                <hr>
              </div>
              <p
                name="and"
                class="text-center w-100"
                :style="{
                  marginTop: isDesktop ? css.andMarginTop : css.m_andMarginTop,
                  marginBottom: isDesktop ? css.andMarginBottom : css.m_andMarginBottom, 
                  marginLeft: isDesktop ? css.andMarginLeft : css.m_andMarginLeft, 
                  marginRight: isDesktop ? css.andMarginRight : css.m_andMarginRight
                }"
              >
                and
              </p>
              <div name="line-right" class="w-100 h-100">
                <hr>
              </div>
            </div>
          </template>
          <template v-else>
            <p
              name="and"
              class="text-center"
              :style="{
                marginTop: isDesktop ? css.andMarginTop : css.m_andMarginTop,
                marginBottom: isDesktop ? css.andMarginBottom : css.m_andMarginBottom, 
                marginLeft: isDesktop ? css.andMarginLeft : css.m_andMarginLeft, 
                marginRight: isDesktop ? css.andMarginRight : css.m_andMarginRight
              }"
            >
              and
            </p>
          </template>
          <p
            name="groom"
            class="text-center"
            :style="{
              paddingRight: isDesktop ? css.groomPaddingRight : css.m_groomPaddingRight, 
              paddingLeft: isDesktop ? css.groomPaddingLeft : css.m_groomPaddingLeft,
              marginLeft: isDesktop ? css.groomMarginLeft : css.m_groomMarginLeft,
              marginRight: isDesktop ? css.groomMarginRight : css.m_groomMarginRight
            }"
          >
            {{ $weddingData.groomNickName }}
          </p>
        </div>
      </section>

      <section
        v-if="$weddingData.coverDate"
        name="date"
      >
        <p name="cover-date">{{ $weddingData.coverDate }}</p>
      </section>

      <section
        v-if="$guestData"
        name="dear"
      >
        <p 
          name="guest-name" 
          class="text-center"
          v-if="isDesktop"
        >
          Kepada Yth: {{ $guestData.name }}
        </p>

        <p 
          name="guest-name" 
          class="text-center"
          v-else-if="isMobile"
        >
          Kepada Yth.<br>{{ $guestData.name }}
        </p>
      </section>

      <section
        name="open" 
        class="d-flex justify-content-center"
        :style="{ marginTop: css.openBtnMarginTop }"
      >
        <template v-if="isDesktop">
          <Button 
            class="open-button" 
            icon="email-closed.svg" 
            text="Buka Undangan" 
            @click.native="$buttonClick(() => $emit('open'))"
          />
        </template>
      </section>
    </div>

    <div 
      name="side-button"
      v-if="isMobile"
      :style="{
        marginTop: $guestData ? '107%' : '80%'
      }"
      @click="$buttonClick(() => $emit('open'))"
    >
      <img 
        src="/icons/customVirandaAkbar/arrow-left.svg" 
        alt="side-button-icon" 
        class="side-button-icon"
      >
      Buka Undangan
    </div>

    <div name="decorations">
      <CoverDecoration />
    </div>
  </div>
</template>

<script>
import CoverDecoration from "@/components/sections/cover/CoverDecoration"

export default {
  components: { CoverDecoration },
  data() {
    return {
      css: {
        unit: "px",
        nameWidth: null,
        nickPadding: null,
        windowHeight: window.innerHeight,
        
        //from database
        brGrFontFamily: "antsvalley",
        brGrFontSize: "4.375rem",
        brGrFontColor: "#161616",
        andFontFamily: "antsvalley",
        andFontSize: "5.625rem",
        andFontColor: "#161616",
        andMarginTop: "-3rem",
        andMarginBottom: "-2rem",
        andMarginLeft: "0",
        andMarginRight: "0",
        dearFontFamily: "poppinsR",
        dearFontSize: "1.25rem",
        dearColor: "#707070",
        openBtnMarginTop: "1.25rem",
        nameMarginBottom: "0",

        ...this.$sections.cover
      }
    }
  },
  computed: {
    styles() {
      const { css } = this
      const { cover } = this.$sections

      let desktop = {
        '--br-gr-font-family': css.brGrFontFamily,
        '--br-gr-font-size': css.brGrFontSize,
        '--br-gr-font-color': css.brGrFontColor,

        '--and-font-family': css.andFontFamily,
        '--and-font-size': css.andFontSize,
        '--and-font-color': css.andFontColor,

        '--and-line-color': css.andLineColor,
        '--and-line-thickness': css.andLineThickness,

        '--dear-font-family': css.dearFontFamily,
        '--dear-font-size': css.dearFontSize,
        '--dear-color': css.dearColor,

        '--cover-title-font-family': css.coverTitleFontFamily,
        '--cover-title-font-size': css.coverTitleFontSize,
        '--cover-title-color': css.coverTitleColor  ,
        
        '--cover-date-color': css.coverDateColor,
        '--cover-date-font-size': css.coverDateFontSize,
        '--cover-date-font-family': css.coverDateFontFamily,
        '--cover-date-margin-top': css.coverDateMarginTop,
        '--cover-date-margin-bottom': css.coverDateMarginBottom
      }

      const mobile = {
        '--m-br-gr-font-size': css.m_brGrFontSize,
        '--m-and-font-size': css.m_andFontSize,
        '--m-dear-font-size': css.m_dearFontSize,
        '--m-dear-font-color': css.m_dearFontColor,
      }

      return { ...desktop, ...mobile }
    },
    publicNameMarginBottom() {
      return this.isDesktop ? this.css.nameMarginBottomOnPublic : this.css.m_nameMarginBottomOnPublic
    }
  },
  methods: {
    __init() {
      this.getNameCss()
    },
    getNameCss() {
      const brideWidth = document.querySelector("p[name=bride]").clientWidth
      const groomWidth = document.querySelector("p[name=groom]").clientWidth
      const nameWidth = brideWidth + groomWidth
      const middleWidth = nameWidth / 2
      const unit = this.css.unit

      this.css.nameWidth = `${nameWidth}${unit}`
      // this.css.bridePaddingLeft = this.css.groomPaddingRight = `${brideWidth > middleWidth ? brideWidth - middleWidth : middleWidth - brideWidth}${unit}`
    }
  },
}
</script>

<style>
  .open-button .button-icon {
    animation: bounceUp;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
</style>

<style scoped>
  #cover-video {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }

  p[name=bride], p[name=groom] {
    font-family: var(--br-gr-font-family);
    font-size: var(--br-gr-font-size);
    color: var(--br-gr-font-color);
    background: var(--br-gr-font-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    letter-spacing: 0.1em;
  }

  p[name=and] {
    font-family: var(--and-font-family);
    font-size: var(--and-font-size);
    color: var(--and-font-color);
    background: var(--and-font-color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  section[name=dear] p {
    font-family: var(--dear-font-family);
    font-size: var(--dear-font-size);
    color: var(--dear-color);
  }

  section[name=title] {
    margin-bottom: 1.5rem;
  }

  section[name=title] [name=cover-title] p {
    font-family: var(--cover-title-font-family);
    font-size: var(--cover-title-font-size);
    color: var(--cover-title-color);
    text-align: center;
  }

  [name=and-withline] {
    width: 150%;
    margin-left: -25%;
  }

  [name=and-withline] hr {
    border: 0;
    background-color: var(--and-line-color);
    height: var(--and-line-thickness);
  }

  section[name=date] {
    margin-top: var(--cover-date-margin-top);
    margin-bottom: var(--cover-date-margin-bottom);
  }

  section[name=date] p[name=cover-date] {
    text-align: center;
    color: var(--cover-date-color);
    font-size: var(--cover-date-font-size);
    font-family: var(--cover-date-font-family);
    font-weight: 700;
    letter-spacing: 0.1em;
  }
</style>

<style mobile scoped>
  .mobile p[name=bride], .mobile p[name=groom] {
    font-size: var(--m-br-gr-font-size);
  }

  .mobile p[name=and] {
    font-size: var(--m-and-font-size);
  }

  .mobile section[name=dear] p {
    font-size: var(--m-dear-font-size);
    color: var(--m-dear-font-color);
  }

  .mobile [name=cover-title] p {
    font-size: 1.25rem !important;
  }

  .mobile section[name=title] {
    margin-bottom: 1.25rem;
  }

  .mobile [name=and-withline] {
    margin-top: -6px;
    margin-bottom: -6px;
  }

  .mobile section[name=date] p[name=cover-date] {
    font-size: 1rem;
  }

  .mobile section[name=date] {
    margin-top: 1rem;
    margin-bottom: 3.06rem;
  }

  .mobile .side-button-wrapper {
    position: relative;
  }

  .mobile [name=side-button] {
    min-width: 11.25rem;
    max-height: 2.5rem;
    border-radius: 1rem 0rem 0rem 1rem;
    background: conic-gradient(from 180deg at 48.5% 50%, #FBFBFD 26.24999910593033deg, #C8D4DA 88.12500178813934deg, #FFF 156.58468008041382deg, #AEC0CE 191.74442768096924deg, #E3E9EE 237.1290135383606deg, #FAFBFC 255.19062280654907deg, #D6DFE6 310.1085305213928deg, #B8C9D3 331.875deg);
    position: absolute;
    right: 0;
    padding: 0.62rem 0 0.62rem 1.5rem;
    
    color: #102135;
    text-align: center;
    font-family: poppinsR;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .mobile [name=side-button]:hover {
    cursor: pointer;
    box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    -webkit-box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    -moz-box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    transition: 0.25s;
  }

  .mobile [name=side-button] .side-button-icon {
    width: 1.25rem;
    
    animation: bounceLeft;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }

  .mobile #cover-section {
    align-items: center !important;
  }
</style>