<template>
  <div 
    class="image-element"
    :style="{
      backgroundImage: `url('${img}')`
    }"
  >
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
  .image-element {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    transition: 0.25s;
  }

  .image-element:hover {
    transform: scale(1.02);
    transition: 0.25s;
    cursor: zoom-in;
  }
</style>