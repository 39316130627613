<template>
  <div
    id="streaming-section"
    class="d-flex justify-content-center align-items-center mt-45-668"
  >
    <div name="content">
      <section name="title">
        <Title text="Link Streaming" />
      </section>

      <section name="link" class="mt-7-5">
        <ColorWrapper
          class="maxw-275 px-25 py-7-5"
          :style="{
            '--font-family': css.streamingTextFontFamily,
            '--font-size': css.streamingTextFontSize,
            '--font-color': css.streamingTextFontColor
          }"
        >
          <p name="streaming-text" class="text-center">{{ this.streamingText }}</p>

          <div name="buttons" class="d-flex justify-content-center align-items-center mt-7-5">
            <template v-for="(link, index) in $weddingData.streamingLink">
              <Link :key="index" :to="link.link" class="d-flex w-100 streaming-link" :class="$weddingData.streamingLink.length > 1 ? [isEven(index) ? 'ml-3-752 justify-content-start' : 'mr-3-752 justify-content-end'] : 'justify-content-center'" external newtab>
                <Button icon="live-stream.svg" :text="link.text" />
              </Link>
            </template>
          </div>
        </ColorWrapper>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    streamingText() {
      return this.$weddingData.streamingText || "Dikarenakan pandemi Covid-19, kami tidak dapat mengundang semua orang. Keselamatan dan kesehatan setiap orang merupakan prioritas bagi kami. Tanpa mengurangi rasa hormat kami, kami menyediakan tayangan acara pernikahan kami secara daring melalui link di bawah ini:"
    }
  },
  data() {
    return {
      css: {
        streamingTextFontFamily: "opensansR",
        streamingTextFontSize: "1rem",
        streamingTextFontColor: "#161616",

        ...this.$sections.streaming
      }
    }
  },
  methods: {
    isEven(number) {
      return number % 2 == 0 ? false : true
    }
  }
}
</script>

<style scoped>
p[name=streaming-text] {
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
}
</style>