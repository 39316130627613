<template>
  <div id="copied-toast" class="w-100 d-none" :class="{ mobile: isMobile }">
    <div name="copied-toast-wrapper" class="d-flex px-4 py-2">
      <img :src="`/icons/${iconDir}/rounded-check.svg`" alt="">
      <p class="ml-2">Nomor berhasil disalin!</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir || "default"
    }
  }
}
</script>

<style scoped>
  #copied-toast p {
    font-size: 1.25;
    line-height: 1.75rem;
    color: #FFFFFF;
    font-family: poppinsR;
  }

  #copied-toast [name=copied-toast-wrapper] {
    border-radius: 1rem;
    background-color: #5D7394;
    box-shadow: 0px 0px 13px -3px rgba(255,255,255,0.66);
    width: fit-content;
    margin: auto;
  }

  #copied-toast {
    position: relative;
    z-index: 999;
  }
</style>

<style mobile scoped>
  #copied-toast.mobile p {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
</style>