<template>
  <div
    id="gift-section"
    class="d-flex align-items-center justify-content-center"
  >
    <div name="content">
      <section name="title">
        <img 
          src="/icons/customVirandaAkbar/wedding gift.svg" 
          alt="gift-icon"
          class="gift-icon"
        >

        <Title text="Kirim Hadiah" />
      </section>

      <section name="gift">
        <p class="gift-text">
          Bagi keluarga dan sahabat yang ingin <br v-if="isMobile">mengirimkan hadiah. Kami akan dengan senang<br v-if="isMobile"> hati menerimanya. Ketuk tombol berikut untuk<br v-if="isMobile"> mengirimkannya kepada kami:
        </p>

        <div class="gift-button">
          <Button
            text="Kirim Hadiah"
            @click.native="openGiftModal"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import PaymentCard from "@/components/sections/gift/PaymentCard"
import { EventBus } from "@/components/helpers/event-bus"

export default {
  components: { 
    PaymentCard,
  },
  data() {
    return {
      css: {
        giftDetailFontFamily: "poppinsR",
        giftDetailFontSize: "1rem",
        giftDetailFontColor: "#161616",

        ...this.$sections.gift
      }
    }
  },

  methods: {
    openGiftModal() {
      EventBus.$emit('giftModalState', true)
    }
  }
}
</script>

<style scoped>
  #gift-section {
    padding-top: 4.06rem;
    padding-bottom: 4.06rem;
    background: #101822;
  }

  [name=content] {
    width: 56.25rem;
    padding: 2.5rem 2.06rem;
    border-radius: 0.625rem;
    background: #102135;
    box-shadow: -10px 10px 2px 0px rgba(255, 255, 255, 0.15);
  }

  .gift-icon {
    width: 3.125rem;
    margin-bottom: 0.75rem;
  }

  section[name=title] {
    text-align: center;
  } 

  section[name=gift] {
    margin-top: 1rem;
  }

  p.gift-text {
    color: #fff;
    text-align: center;
    font-family: opensansR;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2rem;
  }

  .gift-button {
    margin-top: 2.75rem;
    display: flex;
    justify-content: center;
  }
</style>

<style mobile scoped>
  .mobile #gift-section {
    padding-top: 2.19rem;
    padding-bottom: 2.19rem;
    padding-left: 1.88rem;
    padding-right: 1.88rem;
  }

  .mobile .gift-icon {
    width: 2rem;
  }

  .mobile p.gift-text {
    font-size: 0.75rem;
  }

  .mobile section[name=gift] {
    margin-top: 0.5rem;
  }

  .mobile .gift-button {
    margin-top: 1.5rem;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .mobile [name=content] {
    width: 100%;
    padding: 1.25rem 0.94rem 2.38rem 0.94rem;
  }

  .mobile p.gift-text {
    line-height: normal;
  }
</style>