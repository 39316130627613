<template>
  <BaseButton v-bind="[$baseComponents.button, $attrs]" />
</template>

<script>
import BaseButton from "@/components/bases/BaseButton"

export default {
  components: { BaseButton }
}
</script>