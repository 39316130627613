<template>
  <div 
    id="filter-instagram-section"
    class="d-flex justify-content-center"
    :style="{
      backgroundImage: `url('${iconDir}/background-center.png')`
    }"
  >
    <div name="content" class="w-100">
      <div
        v-if="isDesktop" 
        class="top-decor"
      >
        <img :src="`${iconDir}/top-center.png`" class="time-top-decoration" alt="top-center-decoration" data-aos="zoom-in">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path fill="#102135" fill-opacity="1" d="M0,160L120,186.7C240,213,480,267,720,288C960,309,1200,299,1320,293.3L1440,288L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
      </div>

      <section name="title">
        <Title text="Filter Instagram" />
      </section>

      <section 
        name="filter-content" 
        class="text-center"
      >
        <p class="info">Ramaikan acara dengan menggunakan filter <br v-if="isMobile">instagram kami</p>
        
        <div class="button-wrapper">
          <Button 
            icon="filter-ig-icon.svg" 
            text="Gunakan Filter Instagram" 
            minWidth="32.5rem"
            @click.native="openFilter"
          />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      instagram_filter_link: 'https://www.instagram.com/ar/1441230803090694/'
    }
  },

  computed: {
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/filter-instagram/${this.device}`
    }
  },

  methods: {
    openFilter() {
      window.open(this.instagram_filter_link, '_blank');
    }
  }
}
</script>

<style scoped>
  .top-decor img {
    top: 9.8rem;
  }

  .top-decor img, .top-decor svg {
    width: 100%;
    position: absolute;
  }

  .top-decor img.time-top-decoration {
    transform: translateY(-100%);
  }

  .top-decor svg {
    transform: scaleY(-1) scaleX(-1);;
  }

  .top-decor {
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
  }

  #filter-instagram-section {
    overflow: hidden;
    position: relative;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 5.25rem;
    margin-top: 3.25rem;
  }

  section[name=title] {
    margin-top: 8rem;
    position: relative;
    z-index: 2;
  }

  section[name=filter-content] .button-wrapper {
    display: flex;
    justify-content: center;
  }

  section[name=filter-content] p.info {
    color: #FFF;
    font-family: opensansR;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 1.44rem 0 3.62rem 0;
  }
</style>

<style mobile scoped>
  .mobile #filter-instagram-section {
    margin-top: 2.12rem;
    padding: 1.75rem 1.88rem 1.43rem 1.88rem;
    min-height: 15rem;
  }

  .mobile #filter-instagram-section [name=content] {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mobile section[name=title] {
    margin-top: 0;
  }

  .mobile section[name=title] p {
    font-size: 2rem !important;
  }

  .mobile section[name=filter-content] p.info {
    font-size: 0.75rem;
    margin: 0.74rem 0 2.44rem 0;
  }
</style>